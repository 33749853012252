/*
 * @Author: ttheitao
 * @Description: 
 * @Date: 2022-06-14 14:18:54
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-12-30 10:56:21
 */

import { Graph } from "@antv/x6";
import axios from "axios";
import Tool from "./Tool";
import objects from "../../../api/objects";

export default class EventDebug {
    constructor(vm) {
        this.vm = vm;                   // vue 实例
        this.loading = null;            // loading 实例
        this.message = vm.$message;     // message 实例
        this.tracks = [];               // 调用栈
        this.blueprints = {};           // 蓝图集
        this.marker = null;             // 蓝图遮罩层
        this.codeStatus = 200;          // Http响应状态

        this.onresize();
    }

    onresize() {
        window.onresize = () => {
            this.vm.$nextTick(() => {
                const graphListDom = this.vm.$refs.graphList;
                const graphBox = Array.from(graphListDom.getElementsByClassName('instance-list-item'));
                const width = graphListDom.scrollWidth;
                const height = graphListDom.scrollHeight;

                for (let i in graphBox) {
                    graphBox[i].style.width = width + 'px';
                    graphBox[i].style.height = height + 'px';
                }
            });
        }
    }

    /**
     * @description: 设置Vue实例并返回当前实例
     * @param {object} vm vm Vue实例
     * @return {EventDebug}
     */
    static vm(vm) {
        return new EventDebug(vm);
    }

    /**
     * @description: 执行curl
     * @return {void}
     */
    runDebug() {
        if (this.vm.curlData === "") {
            return this.message.error("请填写CURL");
        }
        this.loading = this.vm.$loading();
        this.vm.$refs.graphList.textContent = '';

        const arr = this.vm.curlData.split(/\s\\[(\r\n)\r\n]+/);

        let url = "";
        let data = {};
        let token = "";
        let method = "";
        arr.forEach((element) => {
            if (element.includes("curl '")) {
                url = element.replace("curl '", "");
                url = url.substring(0, url.length - 1);
            } else if (element.includes("  --data-raw")) {
                let temp = element;
                temp = temp.replace("  --data-raw '", "");
                temp = temp.substring(0, temp.length - 1);
                console.log(temp);
                data = JSON.parse(temp);
            } else if (element.includes("  -H 'bimcctoken: ")) {
                token = element.replace("  -H 'bimcctoken: ", "");
                token = token.substring(0, token.length - 1);
            } else if (element.includes("  -X '")) {
                method = element.replace("  -X '", "");
                method = method.substring(0, method.length - 1);
            }
        });
        data.__tracks__ = 1;

        const config = {
            url,
            method,
            headers: {
                bimcctoken: token,
                authorization: "Bearer " + token,
            },
            data,
        }

        axios.request(config)
            .then((res) => {
                if (res.data.tracks && res.data.tracks.length > 0) {
                    this.tracks = res.data.tracks;
                    this.codeStatus = res.data.code;
                    if (res.data.code !== 200) {
                        this.tracks[this.tracks.length - 1].value = res.data.msg;
                    }
                    if (this.tracks.length > 0) {
                        this.solveTracks();
                    }
                } else {
                    this.message.error("无调用栈信息 - " + res.data.msg);
                    this.loading.close();
                }
            })
            .catch((err) => {
                console.error(err);
                this.message.error("请求失败");
                this.loading.close();
            });
    }

    /**
     * @description: 解析调用栈
     * @return {void}
     */
    solveTracks() {
        // index 为蓝图层级
        let index = -1;
        const blueprintUuids = [];

        // 开发模式下载入本地数据
        this.loadTestData(false);

        this.tracks.forEach((track) => {
            if (this.isTarckBlueprint(track)) {
                if (track.value === "start") {
                    ++index;
                    this.vm.runList[index] = {
                        thumb: "",
                        blueprintUuid: track.code,
                        blueprint: null,
                        tracks: [],
                        active: false,
                    };

                    if (!blueprintUuids.includes(track.code)) {
                        blueprintUuids.push(track.code);
                    }
                }
            }
        });

        this.getBlueprints(blueprintUuids);
    }

    /**
     * @description: 获取蓝图信息
     * @param {array} uuids 蓝图uuid集
     * @return {void}
     */
    getBlueprints(uuids = []) {
        objects
            .getBlueprintsList({ uuids: uuids, hasCell: true }, 10000, 1)
            .then((res) => {
                if (res.data.code === 200) {
                    res.data.data.data.forEach((item) => {
                        this.blueprints[item.uuid] = item;
                        this.blueprints[item.uuid].cells =
                            Tool.dbToX6StyleCell(item.cells);

                        this.vm.runList.forEach(runItem => {
                            if (runItem.blueprintUuid === item.uuid && !runItem.blueprint) {
                                runItem.blueprint = this.blueprints[item.uuid];
                            }
                        });
                    });

                    this.createGraphs();
                }
                this.loading.close();
            })
            .catch((err) => {
                console.error(err);
                this.message.error("请求错误");
                this.loading.close();
            });
    }

    /**
     * @description: 创建调用栈使用到的蓝图
     * @return {void}
     */
    createGraphs() {
        this.marker = document.createElement('div');
        this.marker.style.width = "100%";
        this.marker.style.height = "100%";
        this.marker.style.position = "absolute";
        this.marker.style.left = 0;
        this.marker.style.top = 0;
        this.marker.style.background = "#fff";
        this.marker.style.zIndex = 10;
        this.vm.$refs.graphList.appendChild(this.marker);

        for (let uuid in this.blueprints) {
            const blueprint = this.blueprints[uuid];
            const dom = document.createElement('div');
            blueprint.dom = dom;
            dom.className = 'instance-list-item';
            dom.style.width = "100%";
            dom.style.height = "100%";
            dom.style.position = "absolute";
            dom.style.left = 0;
            dom.style.top = 0;
            dom.style.background = "#ccc";
            dom.style.zIndex = -1;

            this.vm.$refs.graphList.appendChild(dom);

            blueprint.graph = new Graph({
                container: dom,
                autoResize: true,
                panning: {
                    enabled: true,
                    eventTypes: ['rightMouseDown']
                },
                background: {
                    color: "#fff", // 设置画布背景颜色
                },
                grid: {
                    size: 10, // 网格大小 10px
                    visible: true, // 渲染网格背景
                },
                selecting: {
                    enabled: true,
                    rubberband: true,
                    showNodeSelectionBox: true,
                    showEdgeSelectionBox: true,
                },
                mousewheel: {
                    enabled: true,
                },
                connecting: {
                    router: {
                        name: 'manhattan',
                        args: {
                            padding: 20,
                        },
                    },
                    connector: {
                        name: 'rounded',
                        args: {
                            radius: 20,
                        },
                    }
                },
            });

            blueprint.graph.fromJSON(blueprint.cells);
        }

        setTimeout(() => {
            this.animate();
        }, 0);
    }

    /**
     * @description: 执行调试动画
     * @return {void}
     */
    animate() {
        // index 为蓝图层级，与sloveTracks方法中的保持一致
        let index = -1;
        let curBlueprint = null;
        let startCount = 0;
        const tracks = Tool.deepCopy(this.tracks);
        this.marker.style.zIndex = -2;

        let stime = setInterval(() => {
            if (tracks.length < 1) {
                this.message.success('执行完成');
                clearInterval(stime);
                return;
            }

            const track = tracks.shift();
            console.log(track, 111);
            if (this.isTarckBlueprint(track)) {
                if (curBlueprint) {
                    // 蓝图层级切换时清空一次画布的高亮状态
                    this.unhighlight(curBlueprint.graph);
                }

                if (track.value === 'start') {
                    ++index;
                    ++startCount;

                    if (this.vm.runList[index - 1]) {
                        this.vm.runList[index - 1].blueprint.dom.style.zIndex = -1;
                        this.vm.runList[index - 1].active = false;
                    }

                    curBlueprint = this.blueprints[track.code];
                    curBlueprint.dom.style.zIndex = 5;
                    this.vm.runList[index].active = true;
                } else if (track.value === "end") {
                    --startCount;
                    if (startCount > 1) {
                        --index;
                    }


                    if (this.vm.runList[index]) {
                        // 切换为上层蓝图实例
                        curBlueprint = this.vm.runList[index].blueprint;
                        // 恢复上层蓝图实例的调用栈
                        const curRunTracks = this.vm.runList[index].tracks;
                        for (let i in curRunTracks) {
                            this.highlight(curBlueprint.graph, curRunTracks[i]);
                        }

                        if (this.vm.runList[index + 1]) {
                            this.vm.runList[index + 1].blueprint.dom.style.zIndex = -1;
                            this.vm.runList[index + 1].active = false;
                        }

                        curBlueprint.dom.style.zIndex = 5;
                        this.vm.runList[index].active = true;
                    }

                    if (index > 0 && startCount > 1) {
                        curBlueprint.dom.style.zIndex = -1;
                    }
                }

                this.vm.$forceUpdate();
            } else {
                const curRunTracks = this.vm.runList[index].tracks;
                // Http状态码不为200时标识最后一个节点为警告色
                let statusColor = '#409EFF';
                curRunTracks.push(track.code);
                if (this.codeStatus !== 200 && index < 1 && tracks.length < 1) {
                    const cell = curBlueprint.graph.getCellById(track.code);
                    cell.label = track.value;
                    statusColor = '#F56C6C';
                }
                this.highlight(curBlueprint.graph, track.code, statusColor);
            }
        }, this.vm.ms);
    }

    /**
     * @description: 取消高亮
     * @param {object} graph x6实例
     * @return {void}
     */
    unhighlight(graph) {
        const cells = graph.getCells();
        for (let i in cells) {
            const cell = cells[i];
            const cellView = graph.findViewByCell(cells[i]);
            if (cellView) {
                if (cell.isEdge()) {
                    cell.setAttrs({
                        line: { stroke: '#333' },
                    });
                } else {
                    cell.setAttrs({
                        body: {
                            fill: '#fff',
                            stroke: '#333'
                        },
                        label: {
                            fill: "#333",
                        }
                    });
                }
            }
        }
    }

    /**
     * @description: 高亮
     * @param {object} graph x6实例
     * @param {string} id 单元id
     * @param {string} statusColor 状态颜色：通过 - #409EFF，异常/错误 - #F56C6C
     * @return {void}
     */
    highlight(graph, id, statusColor = '#409EFF') {
        const cell = graph.getCellById(id);
        const cellView = graph.findViewByCell(cell);
        if (cellView) {
            if (cell.isEdge()) {
                cell.setAttrs({
                    line: { stroke: '#409EFF' },
                });
            } else {
                cell.setAttrs({
                    body: {
                        fill: statusColor,
                        stroke: statusColor
                    },
                    label: {
                        fill: "#fff",
                    }
                });
            }
        }
    }

    /**
     * @description: 判断当前帧是否为蓝图
     * @param {object} track 栈帧
     * @return {boolean}
     */
    isTarckBlueprint(track) {
        return track.type === 'blueprint';
    }

    /**
     * @description: 判断当前帧是否为单元
     * @param {object} track 栈帧
     * @return {boolean}
     */
    isTarckCell(track) {
        return track.type === 'cell';
    }

    /**
     * @description: 开发模式下载入本地调试数据
     * @param {boolean} status 为 false 不加载本地调试数据
     * @return {void}
     */
    loadTestData(status = false) {
        if (process.env.NODE_ENV == 'development' && status) {
            this.tracks = [
                {
                    "type": "blueprint",
                    "code": "blueprint62b019e2b307a",
                    "value": "start"
                },
                {
                    "type": "cell",
                    "code": "Start62b019e2b5ce8",
                    "value": ""
                },
                {
                    "type": "blueprint",
                    "code": "blueprint62b019e2b307a",
                    "value": "end"
                },
                {
                    "type": "blueprint",
                    "code": "blueprint62b019e2b307a",
                    "value": "start"
                },
                {
                    "type": "cell",
                    "code": "Start62b019e2b5ce8",
                    "value": ""
                },
                {
                    "type": "cell",
                    "code": "Event62b019e2c68f0",
                    "value": ""
                },
                {
                    "type": "cell",
                    "code": "RunEdge62b019e2cb7ca",
                    "value": ""
                },
                {
                    "type": "cell",
                    "code": "Rule62b019e2c9845",
                    "value": true
                },
                {
                    "type": "cell",
                    "code": "RuleEdge62b019e2cef26",
                    "value": ""
                },
                {
                    "type": "cell",
                    "code": "Behavior62b019e2cd60d",
                    "value": null
                },
                {
                    "type": "cell",
                    "code": "RuleEdge62b019e2d1db7",
                    "value": ""
                },
                {
                    "type": "cell",
                    "code": "Behavior62b019e2d0721",
                    "value": null
                },
                {
                    "type": "cell",
                    "code": "RuleEdge62b019e2d5682",
                    "value": ""
                },
                {
                    "type": "cell",
                    "code": "Behavior62b019e2d40d4",
                    "value": null
                },
                {
                    "type": "cell",
                    "code": "RuleEdge62b019e2d9c3b",
                    "value": ""
                },
                {
                    "type": "cell",
                    "code": "Behavior62b019e2d81f8",
                    "value": null
                },
                {
                    "type": "cell",
                    "code": "RuleEdge62b019e2de8be",
                    "value": ""
                },
                {
                    "type": "cell",
                    "code": "Behavior62b019e2db830",
                    "value": null
                },
                {
                    "type": "cell",
                    "code": "Event62b019e33fe51",
                    "value": ""
                },
                {
                    "type": "cell",
                    "code": "RunEdge62b019e3452a8",
                    "value": ""
                },
                {
                    "type": "cell",
                    "code": "Rule62b019e3435c6",
                    "value": true
                },
                {
                    "type": "cell",
                    "code": "RuleEdge62b019e349c45",
                    "value": ""
                },
                {
                    "type": "cell",
                    "code": "Behavior62b019e346d5b",
                    "value": null
                },
                {
                    "type": "blueprint",
                    "code": "blueprint62b019e2b307a",
                    "value": "end"
                },
                {
                    "type": "blueprint",
                    "code": "blueprint62b019e2b307a",
                    "value": "start"
                },
                {
                    "type": "cell",
                    "code": "Start62b019e2b5ce8",
                    "value": ""
                },
                {
                    "type": "blueprint",
                    "code": "blueprint62b019e2b307a",
                    "value": "end"
                }
            ];
        }
    }
}