<!--
 * @Author: ttheitao
 * @Description: 条件节点面板
 * @Date: 2022-05-27 14:49:43
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-06-01 15:22:09
-->
<template>
    <div>
        <el-collapse v-model="activeNames">
            <el-collapse-item title="规则条件" name="2">
                <DataConfig
                    ref="dataConfig"
                    :x6Proxy="x6Proxy"
                    :obuuid="obuuid"
                    :behaviorList="behaviorList"
                    :fieldList="fieldList"
                    :dictionaryList="dictionaryList"
                    :relationshipList="relationshipList"
                    @refresh="refresh"
                >
                </DataConfig>
            </el-collapse-item>
            <el-collapse-item title="单元配置" name="1">
                <BaseConfig ref="baseConfig" :x6Proxy="x6Proxy"></BaseConfig>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import BaseConfig from "./BaseConfig.vue";
import DataConfig from "./DataConfig.vue";
import indexMixin from "../mixins/indexMixin.js";

export default {
    name: "CellRule",
    mixins: [indexMixin],
    components: {
        BaseConfig,
        DataConfig,
    },
    data() {
        return {
            label: "开始",
            activeNames: ["1", "2"],
        };
    },
};
</script>

<style>
</style>