/*
 * @Author: ttheitao
 * @Description: 
 * @Date: 2022-06-01 14:53:15
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-06-01 18:09:21
 */
export default {
    props: {
        x6Proxy: {},
        formData: {
            type: Object,
            default: null,
        },
        behaviorList: {
            type: Array,
            default: () => [],
        },
        fieldList: {
            type: Array,
            default: () => [],
        },
        dictionaryList: {
            type: Array,
            default: () => [],
        },
        relationshipList: {
            type: Array,
            default: () => [],
        },
        obuuid: {
            type: String,
            default: "",
        },
    },
    methods: {
        refresh() {
            // data 属性的修改可能会影响单元基础属性，故在此重新获取单元基础属性
            this.$refs.baseConfig.initCellProp();
        }
    }
}