/*
 * @Author: ttheitao
 * @Description: 
 * @Date: 2022-05-30 14:59:00
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-04 16:56:34
 */
import Tool from "../../js/Tool.js";
import { Divider, InputNumber } from "element-ui";

export default {
  props: {
    x6Proxy: {},
    formData: {
      type: Object,
      default: null,
    },
    behaviorList: {
      type: Array,
      default: () => [],
    },
    fieldList: {
      type: Array,
      default: () => [],
    },
    dictionaryList: {
      type: Array,
      default: () => [],
    },
    relationshipList: {
      type: Array,
      default: () => [],
    },
    obuuid: {
      type: String,
      default: "",
    },
  },
  components: {
    'el-divider': Divider,
    'el-input-number': InputNumber,
  },
  data() {
    return {
      cellProp: null,
    };
  },
  created() {
    this.initCellProp();
  },
  methods: {
    onPropChange() {
      if (!this.x6Proxy.curCell) {
        return;
      }
      this.x6Proxy.curCell.setProp(Tool.deepCopy(this.cellProp));
    },
    initCellProp() {
      this.cellProp = Tool.deepCopy(this.x6Proxy.curCell.getProp());
      // data属性在DataConfig单独处理
      delete this.cellProp.data;
    }
  }
}