<!--
 * @Author: zx
 * @Date: 2022-09-23 16:37:29
 * @LastEditTime: 2022-09-27 22:52:08
 * @Description: 
 * @LastEditors: zx
-->
<template>
    <div v-loading="loading" class="w-full h-full" style="display: flex;flex-direction: column;">
      <div class="top-tool">
        <leftTab />
      </div>
      <div class="h-full" style="overflow: hidden">
        <SaasView />
      </div>
    </div>
</template>
<script>
  import leftTab from "@/views/objects/components/saasTab.vue";
  import SaasView from '@/manage-views/views/storeView/index.vue'
  export default {
    components:{
      leftTab,
      SaasView
    },
    props:{
    },
    data() {
      return {
        loading: false
      }
    },
    mounted() {
    },
    methods:{
    }
  }
</script>
<style lang='less' scoped>

</style>