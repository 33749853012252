/*
 * @Author: ttheitao
 * @Description: 管理器
 * @Date: 2022-05-26 09:37:24
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-25 09:51:36
 */
import { Addon, Graph } from "@antv/x6";
import CustomCells from "./CustomCells.js";
import EventManager from "./EventManager.js";
import { Message } from 'element-ui';
import Tool from "./Tool.js";
import ProtGenerater from "./PortGenerater.js";
export default class X6Graph {
    constructor(vm, cells = []) {
        // 主要变量
        this.vm = vm;               // vue 实例
        this.$refs = vm.$refs;      // vue实例 refs
        this.graph = null;          // x6画布实例
        this.curCell = null;        // 当前选中cell
        this.isPasting = false;     // 当前是否在粘贴状态
        this.dnd = null;            // 拖拽实例

        this.initX6(cells);
    }

    // 初始化x6实例
    initX6(cells) {
        this.registerCustomCells();

        this.graph = new Graph(this.initOptions());
        this.initEventManager();

        if (cells.length < 1) {
            this.addDefaultNode();
        } else {
            this.graph.fromJSON(cells);
        }

        this.runDevLogic(cells);
        this.initDnd();

        return this.graph;
    }

    // 初始化选项
    initOptions() {
        return {
            container: this.$refs.x6Main,
            width: undefined,
            height: undefined,
            autoResize: true,
            panning: {
                enabled: true,
                eventTypes: ['rightMouseDown']
            },
            background: {
                // color: "#292b32", // 设置画布背景颜色
                color: "#fff",
            },
            grid: {
                size: 10, // 网格大小 10px
                visible: true, // 渲染网格背景
            },
            resizing: true,
            snapline: true,
            selecting: {
                enabled: true,
                rubberband: true,
                showNodeSelectionBox: true,
                showEdgeSelectionBox: true,
            },
            clipboard: {
                enabled: true,
            },
            keyboard: {
                enabled: true,
            },
            mousewheel: {
                enabled: true,
                factor: 1.1,
            },
            connecting: {
                router: {
                    name: 'manhattan',
                    args: {
                        padding: 20,
                    },
                },
                connector: {
                    name: 'rounded',
                    args: {
                        radius: 20,
                    },
                },
                sourceConnectionPoint: 'anchor',
                // targetConnectionPoint: 'anchor',
                allowPort: false,
                allowBlank: false,
                createEdge: (args) => {
                    return this.generateEdgeByNode(args.sourceCell);
                },
                validateEdge: ({ edge }) => {
                    const ancestors = this.getAncestorsByEdge(edge);
                    const targetCell = this.graph.getCellById(edge.target.cell);
                    for (let i in ancestors) {
                        if (ancestors[i].id === targetCell.id) {
                            this.vm.$message.error('禁止连接当前节点/父节点/祖先节点');
                            return false;
                        }
                    }

                    if (targetCell.shape === 'Start') {
                        this.vm.$message.error('禁止连接到开始节点');
                        return false;
                    }

                    if (targetCell.shape === 'vue-shape' && targetCell.component === 'Condition' && targetCell.parent) {
                        this.vm.$message.error('禁止连接到条件组中的条件');
                        return false;
                    }

                    return true;
                }
            },
            embedding: {
                enabled: true,
                validate({ child, parent }) {
                    let res = false;

                    if (child.shape === 'vue-shape') {
                        if (child.component === 'Condition' && parent.component === 'ConditionGroup') {
                            return true;
                        }
                    }

                    return res;
                }
            }
        }
    }

    runDevLogic(cells) {
        // 开发环境状态提升到window
        if (process.env.NODE_ENV == 'development') {
            window.X6Graph = this.graph;

            if (cells.length < 1) {
                return;
            }
        }
    }

    // 注册自定义单元
    registerCustomCells() {
        CustomCells.register();
    }

    // 新增开始节点
    addDefaultNode() {
        const node = this.addNode({
            shape: 'Start',
            x: 40,
            y: (this.$refs.x6Main.offsetHeight - 60) / 2,
        });

        return node;
    }

    // 显示右键菜单
    showContextmenu(left, top) {
        this.$refs.contextmenu.style.display = "block";
        this.$refs.contextmenu.style.left = left + 180 + "px";
        this.$refs.contextmenu.style.top = top + "px";
    }

    // 关闭右键菜单
    hideContextmenu() {
        this.$refs.contextmenu.style.display = "none";
    }

    // 新增节点
    addNode(node) {
        this.hideContextmenu();
        node.id = `${node.shape}${Tool.uuid2()}`;
        return this.graph.addNode(node);
    }

    // X6事件注册
    initEventManager() {
        new EventManager(this);
    }

    // 新增事件条件节点
    addRuleNode(x = 0, y = 0, label = "条件", width = 80, height = 80, attrs = null, type = 'add') {
        let node = null;
        const options = {
            shape: 'Rule',
            x: x,
            y: y,
            width: width,
            height: height,
            label: label,
            attrs: attrs ? attrs : {
                body: {
                    // fill: '#efdbff',
                    // stroke: '#9254de',
                    // 指定 refPoints 属性，多边形顶点随图形大小自动缩放
                    refPoints: '0,10 10,0 20,10 10,20',
                },
            }
        };

        if (type === 'add') {
            node = this.addNode(options);
        } else if (type === 'create') {
            options.id = `${options.shape}${Tool.uuid2()}`;
            node = this.graph.createNode(options);
        }

        return node;
    }

    // 创建一个规则节点
    createRuleNode(x = 0, y = 0, label = "条件", width = 80, height = 80, attrs = null) {
        return this.addRuleNode(x, y, label, width, height, attrs, 'create');
    }

    // 新增事件节点
    addEventNode(x = 0, y = 0, label = "事件", width = 120, height = 40, attrs = null, type = 'add') {
        let node = null;
        const options = {
            shape: 'Event',
            x: x,
            y: y,
            width: width,
            height: height,
            label: label,
            attrs: attrs ? attrs : {},
        };

        if (type === 'add') {
            node = this.addNode(options);
        } else if (type === 'create') {
            options.id = `${options.shape}${Tool.uuid2()}`;
            node = this.graph.createNode(options);
        }

        return node;
    }

    // 创建一个事件节点
    createEventNode(x = 0, y = 0, label = "事件", width = 120, height = 40, attrs = null) {
        return this.addEventNode(x, y, label, width, height, attrs, 'create');
    }

    // 新增行为节点
    addBehaviorNode(x = 0, y = 0, label = "行为", width = 120, height = 40, attrs = null, type = 'add') {
        let node = null;
        const options = {
            shape: 'Behavior',
            x: x,
            y: y,
            width: width,
            height: height,
            label: label,
            attrs: attrs ? attrs : {}
        };

        if (type === 'add') {
            node = this.addNode(options);
        } else if (type === 'create') {
            options.id = `${options.shape}${Tool.uuid2()}`;
            node = this.graph.createNode(options);
        }

        return node;
    }

    // 创建一个行为节点
    createBehaviorNode(x = 0, y = 0, label = "行为", width = 120, height = 40, attrs = null) {
        return this.addBehaviorNode(x, y, label, width, height, attrs, 'create');
    }

    // 创建一个条件组
    createConditionGroupNode(x = 0, y = 0, label = "条件组", width = 300, height = 180) {
        const node = {
            shape: "vue-shape",
            x: x,
            y: y,
            width: width,
            height: height,
            component: 'ConditionGroup',
            data: {
                label: label,
                enabled: true, // 是否启用
            },
            ports: new ProtGenerater().addTop().addRight().addBottom().addLeft().get(),
        };
        node.id = `${node.component}${Tool.uuid2()}`;
        return this.graph.createNode(node);
    }

    // 创建一个条件
    createConditionNode(x = 0, y = 0, label = "条件", width = 260, height = 30) {
        const node = {
            shape: "vue-shape",
            x: x,
            y: y,
            width: width,
            height: height,
            component: 'Condition',
            data: {
                leftValue: {
                    class: "",// 左值类
                    payload: null, // 左值荷载
                },
                operator: "", // 操作符
                rightValue: {
                    class: "",// 右值类
                    payload: null, // 右值荷载
                },
                enabled: true, // 是否启用
                label: label,
            },
            ports: new ProtGenerater().addTop().addRight().addBottom().addLeft().get(),
        };
        node.id = `${node.component}${Tool.uuid2()}`;
        return this.graph.createNode(node);
    }

    // 新增执行边
    addRunEdge(edge) {
        return this.addEdge(edge);
    }

    // 新增条件边
    addRuleEdge(edge) {
        return this.addEdge(edge);
    }
    addEdge(edge) {
        edge.id = `${edge.shape}${Tool.uuid2()}`;
        return this.graph.addEdge(edge);
    }

    /**
     * @description: 根据节点生成对应的边
     * @param {object} sourceNode 源节点
     * @param {object|null} targetNode 目标节点
     * @return {void}
     */
    generateEdgeByNode(sourceNode, targetNode = null) {
        const edge = {
            shape: 'RunEdge',
            source: sourceNode,
            target: targetNode,
            data: {
                priority: 1,
            }
        };

        const priority = Tool.getMaxPriorityByNode(sourceNode, this.graph.getCells()) + 1;
        if (sourceNode.shape === 'Rule'
            || (sourceNode.shape === 'vue-shape' || ['Condition', 'ConditionGroup'].includes(sourceNode.component))) {
            edge.shape = 'RuleEdge';
            edge.attrs = {
                line: {
                    stroke: Tool.ruleEdgeStoke.pass,
                },
            };
            edge.labels = [
                {
                    attrs: {
                        labelText: {
                            text: priority,
                        }
                    }
                }
            ];
            edge.data.priority = priority;
            edge.data.type = 'pass';

            return this.addRuleEdge(edge);
        } else {
            edge.shape = 'RunEdge';
            edge.labels = [
                {
                    attrs: {
                        labelText: {
                            text: priority,
                        }
                    }
                }
            ];
            edge.data.priority = priority;

            return this.addRunEdge(edge);
        }
    }

    // 设置当前单元
    setCurrentCell(cell = null) {
        this.curCell = cell;
    }

    clearCurrentCell() {
        this.curCell = null;
    }

    /**
     * 删除当前选中和选区选中
     * @returns 
     */
    removeSelect() {
        if (this.curCell) {
            if (this.curCell.shape === 'Start') {
                Message.error('无法删除开始节点');
                return;
            }
            this.graph.removeCell(this.curCell);

            this.clearCurrentCell();
            this.deleteCellsWithSelected();
            this.hideContextmenu();
        }
    }

    // 删除选中单元
    deleteCellsWithSelected() {
        let cells = this.graph.getSelectedCells();
        cells = cells.filter(item => {
            return item.shape !== 'Start';
        });
        if (cells.length) {
            this.graph.removeCells(cells);
        }
    }

    /**
     * @description: 自动修复id，目的是为了生成带单元格类型前缀的uuid
     * @param {array} cells 单元实例数组
     * @return {*}
     */
    autoFixId(cells) {
        cells.forEach(cell => {
            this.graph.updateCellId(cell, `${cell.shape}${Tool.uuid2()}`);
        });
    }

    /**
     * @description: 通过 edge 获取祖先 node
     * @param {object} edge 
     * @return {array}
     */
    getAncestorsByEdge(edge, hasCurrent = true) {
        const sourceCell = this.graph.getCellById(edge.source.cell);
        // const targetCell = this.graph.getCellById(edge.target.cell);
        const ancestors = hasCurrent ? [sourceCell] : [];
        const cells = this.graph.getCells();

        this.getParentsByCell(sourceCell, cells, ancestors, edge);

        return ancestors;
    }

    /**
     * @description: 通过 cell 获取所有父 node
     * @param {*} cell
     * @param {*} cells
     * @param {*} ancestors
     * @param {*} edge
     * @return {*}
     */
    getParentsByCell(cell, cells, ancestors, edge) {
        if (cell.isEdge()) {
            this.getParentsByCell(this.graph.getCellById(cell.source.cell));
        } else if (cell.isNode()) {
            cells.forEach(item => {
                if (item.isEdge() && item.id !== edge.id && item.target.cell === cell.id) {
                    const sourceCell = this.graph.getCellById(item.source.cell);
                    ancestors.push(sourceCell);
                    this.getParentsByCell(sourceCell, cells, ancestors, edge);
                }
            });
        }
    }

    initDnd() {
        this.dnd = new Addon.Dnd({
            target: this.graph,
            getDragNode: (node) => {
                this.isPasting = true;
                return node.clone({ keepId: true });
            },
            getDropNode: (node) => {
                this.isPasting = false;
                return node.clone({ keepId: true });
            },
        });
    }

    handleSencilDrag(e, sencil) {
        // 临时调整为粘贴状态，避免自动连线
        const node = this[`create${sencil.type}Node`]();
        this.dnd.start(node, e);
    }
}
