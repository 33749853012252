<!--
 * @Author: zx
 * @Date: 2022-09-23 16:40:46
 * @LastEditTime: 2022-12-23 16:02:33
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="view">
    <div class="view-left">
      <div class="etl-choose-main" v-loading="selectLoading">
        <div class="etl-choose-add">
          <el-input
            placeholder="搜索ETL"
            prefix-icon="el-icon-search"
            v-model="search"
            @input="handleEtlList"
          >
          </el-input>
          <i class="iconfont iconxinzeng2" @click="EtlAdd"></i>
        </div>
        <!-- data sources select area -->
        <article v-if="etlList && etlList.length">
          <div
            v-for="(item, index) in etlList"
            :key="index"
            :class="item.path == graphId ? 'active-item' : 'item'"
            @click="onChooseEtl(item)"
          >
            <i class="iconfont icona-wendangjilu"></i> {{ item.name }} -
            {{ item.path }}
          </div>
        </article>
        <article v-else>
          <el-empty
            slot="empty"
            :image-size="90"
            description="暂无数据"
          ></el-empty>
        </article>
        <!-- <el-pagination
          background
          layout="total,prev, next,jumper"
          v-show="etlList.length"
          :total="etlTotal"
          @current-change="handleEtlList"
          :current-page.sync="page"
          class="etl-choose-page"
        ></el-pagination> -->
      </div>
    </div>
    <div class="view-etl">
      <Etl v-if="EtlId" :id="EtlId" @reloadList="reloadList" />
      <el-empty
        v-else
        slot="empty"
        :image-size="180"
        description="未选择新增或ETL"
      ></el-empty>
    </div>
  </div>
</template>
<script>
  import { Empty } from "element-ui";
  import objects from "@/api/apiSaasStore";
  import Etl from '@/components/graph/ETL.vue'
  export default {
    components:{
      Etl,
      'el-empty': Empty
    },
    props:{
    },
    data() {
      return {
        EtlId: '',
        etlList: [],
        etlTotal: 0,
        selectLoading: false,
        search: "",
        page: 1,
        size: 9999,
        graphId: '',
      }
    },
    mounted() {
      this.handleEtlList()
    },
    methods:{
      /**
      * @description: 获取etl列表用于切换
      */
      handleEtlList() {
        this.etlList = [];
        this.selectLoading = true;
        const object_uuid = this.$route.query.obuuid
        objects.etlList(this.page, this.size, this.search,object_uuid).then((res) => {
            this.selectLoading = false;
            if (res.data.code == 200) {
              this.etlList = res.data.data.data;
              this.etlTotal = res.data.data.total;
            }
          })
          .catch(() => {
            this.selectLoading = false;
          });
      },
      onChooseEtl(data) {
        this.EtlId = data.path
        this.graphId = data.path
      },
      EtlAdd() {
        this.EtlId = ''
        this.$nextTick(() => {
          this.EtlId = 'add'
        })
      },
      reloadList() {
        this.handleEtlList()
      }
    }
  }
</script>
<style lang='less' scoped>
  .view {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 16px;
    box-sizing: border-box;
    .view-left {
      width: 320px;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 10px 10px 10px;
      box-sizing: border-box;
      border-right: 1px solid #eee;
      .title-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .dialog-title {
          font-size: 18px;
        }
      }     
      .etl-choose-main {
        height: 100%;
        display: flex;
        flex-direction: column;
        .etl-choose-add {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 30px;
          i {
            margin-left: 10px;
          }
        }
        .item {
          padding: 12px;
          line-height: 24px;
          &:hover {
            color: #409eff;
            background-color: #e8f3fe;
          }
        }
        .active-item {
          line-height: 24px;
          padding: 12px;
          color: #409eff;
          background-color: #e8f3fe;
        }
        article {
          margin-top: 10px;
          overflow: auto;
          height: 100%;
          padding-top: 5px;
        }
        .etl-choose-page {
          margin-top: 5px;
          text-align: center;
        }
      } 
    }
    .view-etl {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
</style>