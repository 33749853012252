<template>
  <div v-loading="loading" class="w-full h-full">
    <el-row class="h-full">
      <el-col :span="1" class="h-full bd-right">
        <leftTab />
      </el-col>
      <el-col class="h-full bd-right p-5" :span="4">
        <leftItem
          :topAddable="false"
          :defaultRow="defaultRow"
          :topTitle="obname"
          :topSubTitle="$route.query.obcode"
          :tableData="fieldList"
          field="name"
          @row-click="rowClickField"
        />
      </el-col>
      <el-col class="h-full bd-right p-5" :span="4">
        <leftItem
          delAble
          topTitle="字段集"
          :defaultRow="defaultRow1"
          :tableData="listData"
          field="desc"
          @row-del="rowDel"
          @row-click="rowClick"
          @top-add="addDialog = true"
        />
      </el-col>
      <el-col v-if="detailData != null" :span="10" class="h-full p-5">
        <validate-form
          class="inline-block overflow-y-auto scroller"
          style="height: calc(100% - 44px)"
          :formData="detailData"
          ref="eventDetail"
          :fieldList="fieldList"
          :dictionaryList="dictionaryList"
          :relationshipList="relationshipList"
          :obuuid="obuuid"
          @addFn="onSubmit"
          @closeLoading="closeLoading"
        />
        <el-button @click="commit()" type="primary" :loading="btnLoading">{{
          btnLoading ? "提交中 ..." : "保存"
        }}</el-button>
      </el-col>
    </el-row>
    <b-modal
      ref="elmodal"
      mode="drawer"
      title="新增验证"
      v-if="addDialog"
      :show="addDialog"
      @sure="$refs.validateForm.commit()"
      @cancel="addDialog = false"
    >
      <validate-form
        slot="content"
        ref="validateForm"
        :fieldList="fieldList"
        :obuuid="obuuid"
        :dictionaryList="dictionaryList"
        :relationshipList="relationshipList"
        @addFn="addFn"
        @closeLoading="closeLoading"
      />
    </b-modal>
  </div>
</template>

<script>
import object from "@/api/objects";
import leftTab from "@/views/objects/components/leftTab.vue";
import leftItem from "@/components/leftItem.vue";
import validateForm from "@/views/objects/components/validateForm.vue";
export default {
  name: "validatesList",
  components: {
    leftItem,
    validateForm,
    leftTab,
  },
  props: {
    obuuid: {
      type: String,
      default: "global",
    },
    obname: {
      type: String,
      default: "全局字段",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      listData: [],
      defaultRow: "",
      defaultRow1: "",
      addDialog: false,
      dictionaryList: [],
      relationshipList: [],
      fieldList: [], //字段数据
      detailData: null, //详情
      loading: false,
      btnLoading: false,
      fieldItem: null,
    };
  },
  computed: {},
  methods: {
    getData(uuid) {
      this.loading = true;
      object.getValidateList(uuid).then((res) => {
        if (
          res.data.data &&
          res.data.data.data &&
          res.data.data.data.length != 0
        ) {
          this.defaultRow1 = res.data.data.data[0];
          this.listData = res.data.data.data;
          this.loading = false;
        } else {
          this.listData = [];
          this.detailData = null;
          this.loading = false;
        }
      });
    },
    rowClick(data) {
      //点击左侧
      this.detailData = null;
      this.$nextTick(() => {
        this.detailData = JSON.parse(JSON.stringify(data));
      });
    },
    rowClickField(data) {
      this.fieldItem = data;
      this.getData(data.uuid);
    },
    rowDel(data) {
      //删除左侧列表内容
      object.deleteFieldValidate(data.uuid).then((res) => {
        if (res.data.code == 200) {
          this.getData(this.fieldItem.uuid);
        }
      });
    },
    closeLoading() {
      //关闭弹框里按钮loading
      this.$nextTick(() => {
        this.btnLoading = false;
      });
      if (this.$refs.elmodal && this.$refs.elmodal.loading) {
        this.$refs.elmodal.loading = false;
      }
    },
    addFn(data) {
      if (this.fieldItem != null) {
        data.entity_uuid = this.fieldItem.uuid;
      }
      console.log(data, 66666);
      object.addFieldValidate(data).then((res) => {
        if (res.data.code == 200) {
          this.addDialog = false;
          this.getData(this.fieldItem.uuid);
        } else {
          this.closeLoading();
        }
      });
    },
    commit() {
      this.btnLoading = true;
      this.$refs.eventDetail.commit();
    },
    onSubmit(data) {
      //保存
      object.editFieldVAlidate(this.detailData.uuid, data).then((res) => {
        if (res.data.code == 200) {
          this.btnLoading = false;
          this.getData(this.fieldItem.uuid);
        }
      });
    },
    getSelectList() {
      if (this.dictionaryList.length == 0) {
        object.getDicts(this.obuuid).then((res) => {
          if (res.data.code == 200) {
            let obj = { label: "普通字典", options: res.data.data };
            this.dictionaryList.push(obj);
            object.getDicts("global").then((res1) => {
              if (
                res1.data.code == 200 &&
                res1.data.data &&
                res1.data.data.length != 0
              ) {
                let obj1 = { label: "全局字典", options: res1.data.data };
                this.dictionaryList.unshift(obj1);
              }
              this.$store.commit(
                "setValueFormDatas",
                JSON.stringify({
                  obuuid: this.obuuid,
                  dictionaryList: this.dictionaryList,
                  fieldList: this.fieldList,
                  relationshipList: this.relationshipList,
                })
              );
            });
          }
        });
      }
      if (this.relationshipList.length == 0) {
        object.getMyRelations(this.obuuid).then((res) => {
          if (
            res.data.code == 200 &&
            res.data.data &&
            res.data.data.data &&
            res.data.data.data.length != 0
          ) {
            this.relationshipList = res.data.data.data;
            this.$store.commit(
              "setValueFormDatas",
              JSON.stringify({
                obuuid: this.obuuid,
                dictionaryList: this.dictionaryList,
                fieldList: this.fieldList,
                relationshipList: this.relationshipList,
              })
            );
          }
        });
      }
    },
  },
  mounted() {
    object.getFields(this.obuuid).then((res) => {
      if (
        res.data.code == 200 &&
        res.data.data &&
        res.data.data.data &&
        res.data.data.data.length != 0
      ) {
        this.fieldList = res.data.data.data;
        this.defaultRow = res.data.data.data[0];
        this.fieldItem = res.data.data.data[0];
        this.getData(this.fieldItem.uuid);
        this.$store.commit(
          "setValueFormDatas",
          JSON.stringify({
            obuuid: this.obuuid,
            dictionaryList: this.dictionaryList,
            fieldList: this.fieldList,
            relationshipList: this.relationshipList,
          })
        );
      }
    });
    if (this.$store.state.dataObjects.valueFormDatas == "") {
      this.getSelectList();
    } else {
      let valueFormDatas = JSON.parse(
        this.$store.state.dataObjects.valueFormDatas
      );
      if (valueFormDatas.obuuid && valueFormDatas.obuuid != this.obuuid) {
        this.getSelectList();
      } else {
        this.dictionaryList = valueFormDatas.dictionaryList;
        this.fieldList = valueFormDatas.fieldList;
        this.relationshipList = valueFormDatas.relationshipList;
      }
    }
  },
};
</script>

<style lang="less" scoped>
</style>