<!--
 * @LastEditTime: 2022-08-04 16:58:27
 * @Description: 字典选择器
 * @FilePath: /dwh_front/src/components/metaSelect/DictionarySelect.vue
 * @Date: 2022-05-10 16:30:07
 * @Author: lisushuang
 * @LastEditors: ttheitao
-->

<template>
  <el-select
    clearable
    :multiple="multiple"
    style="width: 100%"
    v-model="selectValue"
    placeholder="请选择"
    v-loading="selectLoading"
    default-first-option
    element-loading-spinner="el-icon-loading"
  >
    <el-option-group
      v-for="group in selectData"
      :key="group.label"
      :label="group.label"
    >
      <el-option
        v-for="item in group.options"
        :key="item.id"
        :label="item.name"
        :value="item.uuid"
      >
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script>
import objects from "@/api/objects";
import { OptionGroup } from "element-ui";

export default {
  name: "EventSelect",
  components:{
    'el-option-group': OptionGroup,
  },
  props: {
    object_uuid: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    object_uuid() {
      this.getSelectData();
    },
    selectValue() {
      this.$emit("input", this.selectValue);
    },
    value(val, oldVal) {
      if (val && !oldVal && !this.selectValue) {
        this.selectValue = val;
      }
    },
  },
  data() {
    return {
      selectData: [],
      selectValue: "",
      selectLoading: true,
    };
  },
  methods: {
    getSelectData() {
      this.selectLoading = true;
      this.selectData = [];
      objects.getDicts(this.object_uuid).then((res) => {
          if (res.data.code == 200) {
            if (res.data.data && res.data.data.length != 0) {
              let obj = {label: "普通字典", options: res.data.data};
              this.selectData.push(obj);
            }
            objects.getDicts("global").then((res1) => {
              if (
                  res1.data.code == 200 &&
                  res1.data.data &&
                  res1.data.data.length != 0
              ) {
                this.selectLoading = false;
                let obj1 = {label: "全局字典", options: res1.data.data};
                this.selectData.push(obj1);
                console.log(this.selectData)
              }
            });
          }
        });
      }
  },
  mounted() {
    this.getSelectData();
    this.selectValue = this.value;
  },
};
</script>