<!--
 * @Author: ttheitao
 * @Description: 
 * @Date: 2022-05-27 17:14:56
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-07-04 11:55:32
-->
<template>
    <div class="base-config-wrap">
        <div class="x6-config-form">
            <div class="x6-config-item">
                <div class="label">单元类型</div>
                <div class="content">执行</div>
            </div>
            <div class="x6-config-item">
                <div class="label">单元名称</div>
                <div class="content">
                    <el-input
                        v-model="cellProp.labels[0].attrs.labelText.text"
                        @input="onPropChange"
                        size="small"
                    />
                </div>
            </div>
            <div class="x6-config-item">
                <div class="label">层级</div>
                <div class="content">
                    <el-input-number
                        v-model="cellProp.zIndex"
                        @change="onPropChange"
                        label="层级"
                        size="small"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import baseMixin from "../mixins/baseMixin.js";
export default {
    mixins: [baseMixin],
};
</script>

<style lang="less" scoped>
</style>