<template>
  <div v-loading="loading" class="w-full h-full">
    <el-row class="h-full">
      <el-col :span="1" class="h-full bd-right">
        <leftTab/>
      </el-col>
      <el-col class="h-full bd-right p-5" :span="4">
        <leftItem
            delAble
            isChildren
            :defaultRow="defaultRow"
            :topTitle="obname"
            :topSubTitle="$route.query.obcode"
            :tableData="showData"
            field="name"
            @row-del="rowDel"
            @row-click="rowClick"
            @top-add="addDialog = true"
        />
      </el-col>
      
      <el-col v-if="detailData != null" :span="19" class="h-full p-5" style="display: flex;flex-direction: column;">
          <template v-if="!showGraph">
            <el-alert v-if="$route.query.enabled == 2">
            聚合对象的事件在成员对象事件发生之后触发
            </el-alert>
            <el-alert v-if="$route.query.enabled == 2" type="error" style="margin-top:10px">
            聚合对象的事件中直接修改成员对象数据时必须要在事件条件中完善边界条件，否则会导致无限循环。
            </el-alert>
            <event-form
                
                class="inline-block overflow-y-auto scroller"
                style="flex-grow: 1;"
                :formData="detailData"
                ref="eventDetail"
                :behaviorList="behaviorList"
                :fieldList="fieldList"
                :dictionaryList="dictionaryList"
                :relationshipList="relationshipList"
                :obuuid="obuuid"
                @addFn="onSubmit"
                @closeLoading="closeLoading"
            />

            <div>
            <el-button @click="commit()" type="primary" :loading="btnLoading">{{
                btnLoading ? "提交中 ..." : "保存"
            }}
            </el-button>

            <el-button @click="triggerAll()" type="danger">触发所有数据</el-button>

            <el-button @click="triggerOne()" type="warning">触发单条数据</el-button>
            </div>
        </template>

      </el-col>
    </el-row>
    <b-modal
        ref="elmodal"
        mode="drawer"
        title="新增事件"
        v-if="addDialog"
        :show="addDialog"
        @sure="$refs.eventForm.commit()"
        @cancel="addDialog = false"
    >
      <event-form
          slot="content"
          ref="eventForm"
          :fieldList="fieldList"
          :behaviorList="behaviorList"
          :dictionaryList="dictionaryList"
          :relationshipList="relationshipList"
          :obuuid="obuuid"
          @addFn="addFn"
          @closeLoading="closeLoading"
      />
    </b-modal>
  </div>
</template>

<script>
import object from "@/api/objects";
import leftTab from "@/views/objects/components/leftTab.vue";
import leftItem from "@/components/leftItem.vue";
import eventForm from "@/views/objects/components/eventForm.vue";

export default {
  name: "viewsList",
  components: {
    leftItem,
    eventForm,
    leftTab
  },
  props: {
    obuuid: {
      type: String,
      default: "global",
    },
    obname: {
      type: String,
      default: "全局字段",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      listData: [],
      defaultRow: "",
      addDialog: false,
      behaviorList: [], //行为数据
      dictionaryList: [],
      relationshipList: [],
      fieldList: [], //字段列表数据
      detailData: null, //详情
      loading: false,
      btnLoading: false,
      events_arr: {},
      showGraph: false,
    };
  },
  computed: {
    showData() {
      let data = [];
      for (var i = 0; i < this.listData.length; i++) {
        if (!data[this.listData[i].event]) {
          data[this.listData[i].event] = []
        }
        data[this.listData[i].event].push(this.listData[i])
      }

      let childData = []
      for (let data_key in data) {
        let child
        if (this.events_arr[data_key]) {
          child = {'uuid':'uuid-uuid'+data_key,'row_click':false,'name': this.events_arr[data_key], 'children': data[data_key]};
        } else {
          child = {'uuid':'uuid-uuid'+data_key,'row_click':false,'name': data_key, 'children': data[data_key]};
        }
        childData.push(child)
      }
      return childData;
    },
  },
  methods: {
    getData() {
      this.loading = true;
      object.getEventsList(this.obuuid, {page: 1, size: 9999}).then((res) => {
        if (
            res.data.data &&
            res.data.data.data &&
            res.data.data.data.length != 0
        ) {
          this.defaultRow = res.data.data.data[0];
          this.listData = res.data.data.data;
          this.loading = false;
        } else {
          this.listData = [];
          this.detailData = null;
          this.loading = false;
        }
      });
    },
    rowClick(data) {
      //点击左侧
      this.detailData = null;
      this.$nextTick(() => {
        this.detailData = JSON.parse(JSON.stringify(data));
      });
    },
    rowDel(data) {
      //删除左侧列表内容
      object.deleteEvent(data.uuid).then((res) => {
        if (res.data.code == 200) {
          this.getData("del");
        }
      });
    },
    closeLoading() {
      //关闭弹框里按钮loading
      this.$nextTick(() => {
        this.btnLoading = false;
      });
      if (this.$refs.elmodal && this.$refs.elmodal.loading) {
        this.$refs.elmodal.loading = false;
      }
    },
    addFn(data) {
      object.addEvent(this.obuuid, data).then((res) => {
        if (res.data.code == 200) {
          this.addDialog = false;
          this.getData();
        } else {
          this.closeLoading();
        }
      });
    },
    commit() {
      this.btnLoading = true;
      this.$refs.eventDetail.commit();
    },
    triggerAll() {
      if (!this.detailData || !this.detailData.uuid) {
        this.$message.error('无效的事件配置！')
        return
      } else if (!this.detailData.rules || !this.detailData.rules.children || !this.detailData.rules.children.length) {
        this.$confirm('该事件配置没有触发条件，请谨慎操作', '提示').then(res => {
          if (res == 'confirm') {
            this.sub_triggerAll()
          }
        }).catch(() => {})
        return
      } else {
        this.sub_triggerAll()
      }
    },
    /**
     * @description: 触发单条数据
     */    
    triggerOne() {
      if (!this.detailData || !this.detailData.uuid) {
        this.$message.error('无效的事件配置！')
        return
      } else if (!this.detailData.rules || !this.detailData.rules.children || !this.detailData.rules.children.length) {
        this.$confirm('该事件配置没有触发条件，请谨慎操作', '提示').then(res => {
          if (res == 'confirm') {
            this.sub_triggerOne()
          }
        }).catch(() => {})
        return
      } else {
        this.sub_triggerOne()
      }
    },

    sub_triggerOne(){
      this.$prompt(
          "请输入数据id",
          "提示"
      ).then((res) => {
        if(res.action !== 'confirm') return ;
        if(!res.value || parseInt(res.value) <= 0) return;
        this.loading = true;
        object.eventsTriggerOne(this.detailData.uuid,res.value).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.$message.success('操作成功');
          }
        }).catch((res) => {
          console.log(res)
          this.loading = false;
          this.$message.error('请求失败或超时')
        });
      }).catch(()=> {});
    },
    sub_triggerAll() {
      this.$confirm(
          "确认触发所有数据的该事件吗？请再次检查配置中是否含有动态数据，动态数据可能会导致不可预知的结果",
          "提示"
      ).then((res) => {
        if (res == "confirm") {
          this.loading = true;
          object.eventsTriggerAll(this.detailData.uuid).then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              this.$message.success('操作成功');
            }
          }).catch((res) => {
            console.log(res)
            this.loading = false;
            this.$message.error('请求失败或超时')
          });
        }
      }).catch(() => {});
    },
    onSubmit(data) {
      //保存
      object.editEvent(this.detailData.uuid, data).then((res) => {
        if (res.data.code == 200) {
          this.btnLoading = false;
          this.getData();
        }
      });
    },
    getSelectList() {
      if (this.dictionaryList.length == 0) {
        object.getDicts(this.obuuid).then((res) => {
          if (res.data.code == 200) {
            if (res.data.data && res.data.data.length != 0) {
              let obj = {label: "普通字典", options: res.data.data};
              this.dictionaryList.push(obj);
            }
            object.getDicts("global").then((res1) => {
              if (
                  res1.data.code == 200 &&
                  res1.data.data &&
                  res1.data.data.length != 0
              ) {
                let obj1 = {label: "全局字典", options: res1.data.data};
                this.dictionaryList.unshift(obj1);
              }
              this.$store.commit(
                  "setValueFormDatas",
                  JSON.stringify({
                    obuuid: this.obuuid,
                    dictionaryList: this.dictionaryList,
                    fieldList: this.fieldList,
                    relationshipList: this.relationshipList,
                  })
              );
            });
          }
        });
      }
      if (this.fieldList.length == 0) {
        object.getFields(this.obuuid).then((res) => {
          if (
              res.data.code == 200 &&
              res.data.data &&
              res.data.data.data &&
              res.data.data.data.length != 0
          ) {
            this.fieldList = res.data.data.data;
            this.$store.commit(
                "setValueFormDatas",
                JSON.stringify({
                  obuuid: this.obuuid,
                  dictionaryList: this.dictionaryList,
                  fieldList: this.fieldList,
                  relationshipList: this.relationshipList,
                })
            );
          }
        });
      }
      if (this.relationshipList.length == 0) {
        object.getMyRelations(this.obuuid).then((res) => {
          if (
              res.data.code == 200 &&
              res.data.data &&
              res.data.data.data &&
              res.data.data.data.length != 0
          ) {
            this.relationshipList = res.data.data.data;
            this.$store.commit(
                "setValueFormDatas",
                JSON.stringify({
                  obuuid: this.obuuid,
                  dictionaryList: this.dictionaryList,
                  fieldList: this.fieldList,
                  relationshipList: this.relationshipList,
                })
            );
          }
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.dataObjects.valueFormDatas == "") {
      this.getSelectList();
    } else {
      let valueFormDatas = JSON.parse(
          this.$store.state.dataObjects.valueFormDatas
      );
      if (valueFormDatas.obuuid && valueFormDatas.obuuid != this.obuuid) {
        this.getSelectList();
      } else {
        this.dictionaryList = valueFormDatas.dictionaryList;
        this.fieldList = valueFormDatas.fieldList;
        this.relationshipList = valueFormDatas.relationshipList;
      }
    }
    object.getBehaviorList(this.obuuid).then((res) => {
      if (
          res.data.code == 200 &&
          res.data.data &&
          res.data.data.data &&
          res.data.data.data.length != 0
      ) {
        this.behaviorList = res.data.data.data;
      }
    });

    for (let event_key in object.systemEvents) {
      this.events_arr[object.systemEvents[event_key].value] = object.systemEvents[event_key].name;
    }
    this.getData();
  },
};
</script>

<style lang="less" scoped>

</style>
