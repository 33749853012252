<!--
 * @Author: ttheitao
 * @Description: 条件业务配置
 * @Date: 2022-05-27 17:14:56
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-04 16:54:45
-->
<template>
    <div class="base-config-wrap">
        <div class="x6-config-form">
            <el-form>
                <treeForm
                    :obuuid="obuuid"
                    :dictionaryList="dictionaryList"
                    :relationshipList="relationshipList"
                    :formData="
                        data.rules && data.rules !== null ? [data.rules] : null
                    "
                    :fieldList="fieldList"
                    @input="onTreeFormChange"
                />
            </el-form>
            <div class="x6-config-item">
                <div class="label">是否启用</div>
                <div class="content">
                    <el-switch v-model="data.enabled" @change="onPropChange" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dataMixin from "../mixins/dataMixin.js";
import treeForm from "@/views/objects/components/treeForm.vue";


export default {
    mixins: [dataMixin],
    components: {
      treeForm,
    },
    created() {
        // console.log("obuuid", this.obuuid);
        // console.log("dictionaryList", this.dictionaryList);
        // console.log("relationshipList", this.relationshipList);
        // console.log("formData", this.formData);
        // console.log("fieldList", this.fieldList);
    },
    methods: {
        onTreeFormChange(value) {
            this.data.rules = value;
            this.onPropChange();
        },
    },
};
</script>

<style lang="less" scoped>
</style>