<template>
  <div v-loading="loading" class="w-full h-full" style="display: flex;flex-direction: column;">
    <div class="top-tool">
      <leftTab />
    </div>
    <el-row class="h-full" style="display: flex">
      <FieldsManage :obuuid="obuuid" @getData="getData"/>
    </el-row>
    <b-modal
      ref="elmodal"
      mode="drawer"
      title="新增字段"
      v-if="addDialog"
      :show="addDialog"
      @sure="$refs.baseForm.commit()"
      @cancel="addDialog = false"
    >
      <div slot="content">
        <base-form
          ref="baseForm"
          @addFn="addFn"
          @closeLoading="closeLoading"
          @changeType="changeType"
        />
        <value-form
          ref="virtualForm"
          :obuuid="obuuid"
          v-if="addType == 'virtual'"
          :dictionaryList="dictionaryList"
          :fieldList="fieldList"
          :relationshipList="relationshipList"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

import object from "@/api/objects";
import leftTab from "@/views/objects/components/saasTab.vue";
import leftItem from "@/components/leftItem.vue";
import baseForm from "@/views/objects/components/baseForm.vue";
import valueForm from "@/views/objects/components/valueForm.vue";
import FieldsManage from "@/views/objects/fieldsManage.vue";

import collect from "collect.js"
import { mapGetters } from "vuex";
import { RadioButton,ButtonGroup } from "element-ui";

export default {
  name: "fieldsList",
  components: {
    leftTab,
    leftItem,
    baseForm,
    valueForm,
    FieldsManage,
    'el-button-group':ButtonGroup,
    'el-radio-button': RadioButton,
  },
  props: {
    obuuid: {
      type: String,
      default: "global",
    },
    obname: {
      type: String,
      default: "全局字段",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      listData: [], //字段列表
      loading: false, //加载
      btnLoading: false,
      detailData: null,
      defaultRow: "",
      appendType: false, //虚拟字段
      addDialog: false,
      addType: "base", //默认新增非虚拟字段
      fieldTypes: object.fieldTypes,
      show: false,
      status: 1,
      dictionaryList: [],
      relationshipList: [],
      fieldList: [], //字段列表数据
    };
  },
  computed:{
    btns(){
      return [
        { type: "user", desc: "用户" ,need:this.needShowAddBtn('user')},
        { type: "archi", desc: "架构" ,need:this.needShowAddBtn('archi')},
        { type: "flow", desc: "流程" ,need:this.needShowAddBtn('flow')},
      ]
    }
  },
  methods: {
    /**
     * @description: 根据字段是否存在返回是否需要展示某个按钮
     * @param {*} type <String> 字段code 或者 补充类型type
     * @return {*}
     */    
    needShowAddBtn(type){
      if(type == 'user'){
        return !(this.needShowAddBtn('user_id') && this.needShowAddBtn('user_table') && this.needShowAddBtn('user_department_id'))
      }
      if(type == 'archi'){
        return !(this.needShowAddBtn('archi_id') && this.needShowAddBtn('archi_table') && this.needShowAddBtn('archi_type'))
      }
      if(type == 'flow'){
        return !(this.needShowAddBtn('flow_engine_id') && this.needShowAddBtn('flow_engine_status'))
      }
      for(let i = 0;i<this.fieldList.length;i ++){
        if(this.fieldList[i].code == type){
          return true
        }
      }
      return false
    },
    /**
     * @description: 创建默认的字段
     * @param {*} type String 创建的类型 archi user flow
     * @param {*} desc String 创建的描述 架构 用户 流程
     * @return {*}
     */
    createDefaultField(type, desc) {
      this.$confirm("确认补充" + desc + "的字段吗？", "提示")
        .then((res) => {
          if (res == "confirm") {
            this.loading = true;
            object
              .addDefaultField(this.obuuid, type)
              .then((res) => {
                this.loading = false;
                this.$message.success("操作成功！");
                this.getData();
              })
              .catch((res) => {
                this.$message.error("操作失败！");
                this.loading = false;
              });
          }
        })
        .catch((e) => {});
    },
    changeDisplay(mode) {
      if (mode === 2) {
        this.$router.push({
          name: "designForm",
          query: this.$route.query,
        });
      }
    },
    getData() {
      this.loading = true;
      object.getFields(this.obuuid).then((res) => {
        if (
          res.data.code == 200 &&
          res.data.data &&
          res.data.data.data &&
          res.data.data.data.length != 0
        ) {
          if (this.detailData == null) {
            if(this.$route.query.field_uuid){
              let field = collect(res.data.data.data)
                .where('uuid',this.$route.query.field_uuid)
                .first()
              this.defaultRow = field;
              this.rowClick(field)
            }else{
              this.defaultRow = res.data.data.data[0];
            }
          } else {
            this.defaultRow = this.detailData;
          }
          this.listData = res.data.data.data;
          this.fieldList = res.data.data.data;
          this.$store.commit(
            "setValueFormDatas",
            JSON.stringify({
              obuuid: this.obuuid,
              dictionaryList: this.dictionaryList,
              fieldList: this.fieldList,
              relationshipList: this.relationshipList,
            })
          );
          this.loading = false;
        } else {
          this.listData = [];
          this.detailData = null;
          this.appendType = false;
          this.loading = false;
        }
      });
    },
    rowClick(data) {
      //点击左侧
      // this.loading = true;
      object
        .getFieldDetail(data.uuid)
        .then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.detailData = null;
            this.appendType = false;
            this.$nextTick(() => {
              this.detailData = res.data.data;
              this.show = true;
              if (data.type == "append") {
                this.appendType = true;
              } else {
                this.appendType = false;
              }
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    changeType(type) {
      //新增部分
      this.addType = type;
    },
    changeAppendType(type) {
      //编辑部分
      if (type == "base") {
        this.appendType = false;
      } else {
        this.appendType = true;
      }
    },
    addField(data) {
      if (this.addDialog) {
        //新增
        data.object_uuid = this.obuuid;
        object.addField(data).then((res) => {
          this.closeLoading()
          if (res.data.code == 200) {
            this.addDialog = false;
            this.getData();
          }
        });
      } else {
        //编辑
        object.editField(data.uuid, data).then((res) => {
          this.closeLoading()
          if (res.data.code == 200) {
            this.rowClick(this.detailData);
          }
        }).catch(res=>{
          this.closeLoading()
        });
      }
    },
    /**
     * @description: 关闭除页面loading外的所有Loading
     */    
    closeLoading() {
      //关闭弹框里按钮loading
      this.$nextTick(() => {
        this.btnLoading = false;
      });
      if (this.$refs.elmodal && this.$refs.elmodal.loading) {
        this.$refs.elmodal.loading = false;
      }
    },
    addFn(data) {
      if (this.addDialog) {
        if (this.addType == "virtual") {
          this.$refs.virtualForm.commit();
          if (this.$refs.virtualForm.validation) {
            let obj = JSON.parse(JSON.stringify(this.$refs.virtualForm.extra));
            data.extra = obj;
            this.addField(data);
          } else {
            this.closeLoading()
          }
        } else {
          this.addField(data);
        }
      } else {
        if (this.appendType) {
          this.$refs.virtualForm.commit();
          if (this.$refs.virtualForm.validation) {
            let obj = JSON.parse(JSON.stringify(this.$refs.virtualForm.extra));
            data.extra = obj;
            this.addField(data);
          } else {
            this.closeLoading()
          }
        } else {
          this.addField(data);
        }
      }
    },
    onSubmit() {
      //保存
      this.btnLoading = true;
      this.$refs.baseForm.commit();
    },
    getSelectList() {
      if (this.dictionaryList.length == 0) {
        object.getDicts(this.obuuid).then((res) => {
          if (res.data.code == 200) {
            let obj = { label: "普通字典", options: res.data.data };
            this.dictionaryList.push(obj);
            object.getDicts("global").then((res1) => {
              if (
                res1.data.code == 200 &&
                res1.data.data &&
                res1.data.data.length != 0
              ) {
                let obj1 = { label: "全局字典", options: res1.data.data };
                this.dictionaryList.unshift(obj1);
              }
              this.$store.commit(
                "setValueFormDatas",
                JSON.stringify({
                  obuuid: this.obuuid,
                  dictionaryList: this.dictionaryList,
                  fieldList: this.fieldList,
                  relationshipList: this.relationshipList,
                })
              );
            });
          }
        });
      }
      if (this.relationshipList.length == 0) {
        object.getMyRelations(this.obuuid).then((res) => {
          if (
            res.data.code == 200 &&
            res.data.data &&
            res.data.data.data &&
            res.data.data.data.length != 0
          ) {
            this.relationshipList = res.data.data.data;
            this.$store.commit(
              "setValueFormDatas",
              JSON.stringify({
                obuuid: this.obuuid,
                dictionaryList: this.dictionaryList,
                fieldList: this.fieldList,
                relationshipList: this.relationshipList,
              })
            );
          }
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.dataObjects.valueFormDatas == "") {
      this.getSelectList();
    } else {
      let valueFormDatas = JSON.parse(
        this.$store.state.dataObjects.valueFormDatas
      );
      if (valueFormDatas.obuuid && valueFormDatas.obuuid != this.obuuid) {
        this.getSelectList();
      } else {
        this.dictionaryList = valueFormDatas.dictionaryList;
        this.fieldList = valueFormDatas.fieldList;
        this.relationshipList = valueFormDatas.relationshipList;
      }
    }
    this.getData();
    // this.$globalAction('aggregateObjectConfig',{object_uuid:this.obuuid})
  },
};
</script>

<style lang="less">
  .h-full {
    .top-tool {
      height: 60px;
    }
  }
</style>
<style lang="less" scoped>
@import "../../../manage-views/css/manageAdd.less";
</style>