<template>
  <div v-loading="loading" class="w-full h-full">
    <el-row class="h-full">
      <el-col :span="1" class="h-full bd-right">
        <leftTab />
      </el-col>
      <el-col class="h-full bd-right p-5" :span="4">
        <leftItem
          delAble
          :defaultRow="defaultRow"
          :topTitle="obname"
          :topSubTitle="$route.query.obcode"
          :tableData="listData"
          field="name"
          @row-del="rowDel"
          @row-click="rowClick"
          @top-add="addDialog = true"
        />
      </el-col>
      <el-col v-if="detailData != null" :span="10" class="h-full p-5">
        <div
          class="inline-block overflow-y-auto scroller w-full"
          style="height: calc(100% - 44px)"
        >
          <behaviors-form
            :formData="detailData"
            ref="behaviorsDetail"
            v-if="behaviorClasses.length != 0"
            :behaviorClasses="behaviorClasses"
            :dictionaryList="dictionaryList"
            :fieldList="fieldList"
            :relationshipList="relationshipList"
            :obuuid="obuuid"
            @addFn="addBehaviors"
            @closeLoading="closeLoading"
          />
        </div>
        <el-button @click="onSubmit" type="primary" :loading="btnLoading">{{
          btnLoading ? "提交中 ..." : "保存"
        }}</el-button>
        <el-button @click="triggerAll()" type="danger">触发所有数据</el-button>

        <el-button @click="triggerOne()" type="warning">触发单条数据</el-button>
      </el-col>
    </el-row>
    <b-modal
      ref="elmodal"
      mode="drawer"
      title="新增行为"
      v-if="addDialog"
      :show="addDialog"
      @sure="$refs.behaviorform.commit()"
      @cancel="addDialog = false"
    >
      <div slot="content">
        <behaviors-form
          ref="behaviorform"
          v-if="behaviorClasses.length != 0"
          :behaviorClasses="behaviorClasses"
          :dictionaryList="dictionaryList"
          :fieldList="fieldList"
          :relationshipList="relationshipList"
          :obuuid="obuuid"
          @addFn="addBehaviors"
          @closeLoading="closeLoading"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import object from "@/api/objects";
import leftTab from "@/views/objects/components/leftTab.vue";
import leftItem from "@/components/leftItem.vue";
import behaviorsForm from "@/views/objects/components/behaviorsForm.vue";
export default {
  name: "behaviorsList",
  components: {
    leftItem,
    behaviorsForm,
    leftTab,
  },
  props: {
    obuuid: {
      type: String,
      default: "global",
    },
    obname: {
      type: String,
      default: "全局字段",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      listData: [],
      defaultRow: "",
      addDialog: false,
      fieldTypes: object.fieldTypes,
      behaviorClasses: [], //行为类列表
      dictionaryList: [],
      relationshipList: [],
      fieldList: [], //字段列表数据
      detailData: null, //详情
      loading: false,
      btnLoading: false,
      testModalShow: false, //测试弹框
      testModalValue: "",
      logData: [], //日志数据
      value: null,
    };
  },
  computed: {},
  methods: {
    triggerOne(){
      this.$prompt(
          "请输入数据id",
          "提示"
      ).then((res) => {
        if(res.action !== 'confirm') return ;
        if(!res.value || parseInt(res.value) <= 0) return;
        this.loading = true;
        object.behaviorTriggerOne(this.detailData.uuid,res.value).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.$message.success('操作成功');
          }
        }).catch((res) => {
          console.log(res)
          this.loading = false;
          this.$message.error('请求失败或超时')
        });
      }).catch(()=> {});
    },
    triggerAll() {
      this.$confirm(
          "确认触发所有数据的该行为吗？请再次检查配置中是否含有动态数据，动态数据可能会导致不可预知的结果",
          "提示"
      ).then((res) => {
        if (res == "confirm") {
          this.loading = true;
          object.behaviorTriggerAll(this.detailData.uuid).then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              this.$message.success('操作成功');
            }
          }).catch((res) => {
            console.log(res)
            this.loading = false;
            this.$message.error('请求失败或超时')
          });
        }
      }).catch(() => {});
    },
    getData(type) {
      this.loading = true;
      object.getBehaviorList(this.obuuid).then((res) => {
        if (
          res.data.code == 200 &&
          res.data.data &&
          res.data.data.data &&
          res.data.data.data.length != 0
        ) {
          if (this.detailData == null) {
            this.defaultRow = res.data.data.data[0];
          } else {
            if (type != undefined) {
              if (type == "del") {
                this.defaultRow = res.data.data.data[0];
              }
            } else {
              this.defaultRow = this.detailData;
            }
          }
          this.listData = res.data.data.data;
          this.loading = false;
        } else {
          this.listData = [];
          this.detailData = null;
          this.loading = false;
        }
      });
    },
    rowClick(data) {
      //点击左侧
      this.detailData = null;
      this.$nextTick(() => {
        this.detailData = data;
        if (data.items && data.items.payload && data.items.payload.value) {
          this.value = data.items.payload.value;
        }
      });
    },
    rowDel(data) {
      //删除左侧列表内容
      object.deleteBehavior(data.uuid).then((res) => {
        if (res.data.code == 200) {
          this.getData("del");
        }
      });
    },
    closeLoading() {
      //关闭弹框里按钮loading
      this.$nextTick(() => {
        this.btnLoading = false;
      });
      if (this.$refs.elmodal && this.$refs.elmodal.loading) {
        this.$refs.elmodal.loading = false;
      }
    },
    addBehaviors(data) {
      data.object_uuid = this.obuuid;
      if (this.addDialog) {
        object.addBehavior(this.obuuid, data).then((res) => {
          if (res.data.code == 200) {
            this.addDialog = false;
            this.getData();
          } else {
            this.closeLoading();
          }
        });
      } else {
        object.editBehavior(this.detailData.uuid, data).then((res) => {
          if (res.data.code == 200) {
            this.btnLoading = false;
            this.getData();
          } else {
            this.btnLoading = false;
          }
        });
      }
    },
    onSubmit() {
      //保存
      this.btnLoading = true;
      this.$refs.behaviorsDetail.commit();
    },
    getSelectList() {
      if (this.dictionaryList.length == 0) {
        object.getDicts(this.obuuid).then((res) => {
          if (res.data.code == 200) {
            // this.dictionaryList=res.data.data;
            let obj = { label: "普通字典", options: res.data.data };
            this.dictionaryList.push(obj);
            object.getDicts("global").then((res1) => {
              if (
                res1.data.code == 200 &&
                res1.data.data &&
                res1.data.data.length != 0
              ) {
                let obj1 = { label: "全局字典", options: res1.data.data };
                this.dictionaryList.unshift(obj1);
                // this.dictionaryList=this.dictionaryList.concat(res1.data.data)
              }
              this.$store.commit(
                "setValueFormDatas",
                JSON.stringify({
                  obuuid: this.obuuid,
                  dictionaryList: this.dictionaryList,
                  fieldList: this.fieldList,
                  relationshipList: this.relationshipList,
                })
              );
            });
          }
        });
      }
      if (this.fieldList.length == 0) {
        
        object.getFields(this.obuuid).then((res) => {
          if (
            res.data.code == 200 &&
            res.data.data &&
            res.data.data.data &&
            res.data.data.data.length != 0
          ) {
            this.fieldList = res.data.data.data;
            this.$store.commit(
              "setValueFormDatas",
              JSON.stringify({
                obuuid: this.obuuid,
                dictionaryList: this.dictionaryList,
                fieldList: this.fieldList,
                relationshipList: this.relationshipList,
              })
            );
          }
        });
      }
      if (this.relationshipList.length == 0) {
        object.getMyRelations(this.obuuid).then((res) => {
          if (
            res.data.code == 200 &&
            res.data.data &&
            res.data.data.data &&
            res.data.data.data.length != 0
          ) {
            this.relationshipList = res.data.data.data;
            this.$store.commit(
              "setValueFormDatas",
              JSON.stringify({
                obuuid: this.obuuid,
                dictionaryList: this.dictionaryList,
                fieldList: this.fieldList,
                relationshipList: this.relationshipList,
              })
            );
          }
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.dataObjects.valueFormDatas == "") {
      this.getSelectList();
    } else {
      let valueFormDatas = JSON.parse(
        this.$store.state.dataObjects.valueFormDatas
      );
      if (valueFormDatas.obuuid && valueFormDatas.obuuid != this.obuuid) {
        this.getSelectList();
      } else {
        this.dictionaryList = valueFormDatas.dictionaryList;
        this.fieldList = valueFormDatas.fieldList;
        this.relationshipList = valueFormDatas.relationshipList;
      }
    }
    object.getBehaviorClasses().then((res) => {
      if (res.data.code == 200 && res.data.data && res.data.data.length != 0) {
        this.behaviorClasses = res.data.data;
      }
    });
    this.getData();
  },
};
</script>

<style lang="less" scoped>
</style>