/*
 * @Author: ttheitao
 * @Description: please input some description
 * @Date: 2022-05-26 13:52:13
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-04 15:04:23
 */

import { Graph } from "@antv/x6";
import '@antv/x6-vue-shape';
import PortGenerater from "./PortGenerater";
import Condition from '../customNodes/Condition.vue';
import ConditionGroup from '../customNodes/ConditionGroup.vue';

export default class CustomCells {
    // 自定义节点
    static customNodes = [
        {
            name: 'Start',
            options: {
                inherit: 'circle',
                width: 60,
                height: 60,
                attrs: {
                    body: {
                        stroke: '#409EFF',
                        // fill: '#409EFF',
                    },
                    label: {
                        textWrap: {
                            width: '90%',      // 宽度减少 10px
                            height: "90%",
                            ellipsis: true,  // 文本超出显示范围时，自动添加省略号
                            breakWord: true, // 是否截断单词
                        },
                        // fill: "#fff",
                        // fontSize:14,
                        // fontWeight:'bold',
                    },
                },
                label: "开始",
                // 业务数据
                data: {},
                ports: new PortGenerater().addTop().addRight().addBottom().addLeft().get(),
            },
            overwrite: true
        },
        {
            name: 'Event',
            options: {
                inherit: 'rect',
                width: 80,
                height: 40,
                attrs: {
                    body: {
                        stroke: '#409EFF',
                        // fill: '#409EFF',
                    },
                    label: {
                        textWrap: {
                            width: '90%',      // 宽度减少 10px
                            height: "90%",
                            ellipsis: true,  // 文本超出显示范围时，自动添加省略号
                            breakWord: true, // 是否截断单词
                        },
                        // fill: "#fff",
                    }
                },
                label: "事件",
                // 业务数据
                data: {
                    // 事件类型：1 - 系统事件, 2 - 自定义事件
                    type: 1,
                    // 事件名称
                    name: "事件",
                    // 事件名
                    event: "",
                    // 是否启用
                    enabled: true,
                },
                ports: new PortGenerater().addTop().addRight().addBottom().addLeft().get(),
            },
            overwrite: true
        },
        {
            name: 'Rule',
            options: {
                inherit: 'polygon',
                width: 80,
                height: 80,
                attrs: {
                    body: {
                        stroke: '#e6a23c',
                        // fill: '#e6a23c',
                    },
                    label: {
                        textWrap: {
                            width: '90%',      // 宽度减少 10px
                            height: "90%",
                            ellipsis: true,  // 文本超出显示范围时，自动添加省略号
                            breakWord: true, // 是否截断单词
                        },
                        // fill: "#fff",
                    }
                },
                label: "条件",
                // 业务数据
                data: {
                    // 条件
                    rules: null,
                    // 是否启用
                    enabled: true,
                },
                ports: new PortGenerater().addTop().addRight().addBottom().addLeft().get(),
            },
            overwrite: true
        },
        {
            name: 'Behavior',
            options: {
                inherit: 'rect',
                width: 80,
                height: 40,
                attrs: {
                    body: {
                        stroke: '#409EFF',
                        // fill: '#409EFF',
                        rx: 20,
                        ry: 20,
                    },
                    label: {
                        textWrap: {
                            width: '90%',      // 宽度减少 10px
                            height: "90%",
                            ellipsis: true,  // 文本超出显示范围时，自动添加省略号
                            breakWord: true, // 是否截断单词
                        },
                        // fill: "#fff",
                    }
                },
                label: "行为",
                // 业务数据
                data: {
                    // 行为名称
                    name: "",
                    // 行为类
                    class: "",
                    // 荷载
                    payload: "",
                    // 是否启用
                    enabled: true,
                },
                ports: new PortGenerater().addTop().addRight().addBottom().addLeft().get(),
            },
            overwrite: true
        },
        // {
        //     name: 'Condition',
        //     options: {
        //         inherit: "vue-shape",
        //         width: 200,
        //         height: 50,
        //         component: {
        //             template: `<condition></condition>`,
        //             components: {
        //                 Condition,
        //             },
        //         },
        //         data: {
        //             leftValue: {
        //                 class: "",// 左值类
        //                 payload: null, // 左值荷载
        //             },
        //             operator: "", // 操作符
        //             rightValue: {
        //                 class: "",// 右值类
        //                 payload: null, // 右值荷载
        //             },
        //             enabled: true, // 是否启用
        //         },
        //         ports: new ProtGenerater().addTop().addRight().addBottom().addLeft().get(),
        //     },
        //     overwrite: true
        // },
        // {
        //     name: 'ConditionGroup',
        //     options: {
        //         inherit: "vue-shape",
        //         width: 200,
        //         height: 200,
        //         component: {
        //             template: `<condition-group></condition-group>`,
        //             components: {
        //                 ConditionGroup,
        //             },
        //         },
        //         data: {
        //             enabled: true, // 是否启用
        //         },
        //         ports: new ProtGenerater().addTop().addRight().addBottom().addLeft().get(),
        //     },
        //     overwrite: true
        // }
    ];

    // 自定义vue节点 节点的属性在节点创建方法 create{name}Node 中
    static customVueNodes = [
        {
            name: "Condition",
            options: {
                template: `<condition></condition>`,
                components: {
                    Condition,
                },
            },
            overwrite: true
        },
        {
            name: "ConditionGroup",
            options: {
                template: `<condition-group></condition-group>`,
                components: {
                    ConditionGroup,
                }
            },
            overwrite: true
        }
    ];

    // 自定义边
    static customEdges = [
        {
            name: 'RunEdge',
            options: {
                inherit: 'edge',
                attrs: {
                    line: {
                        stroke: '#a0a0a0',
                    },
                },
                labels: [
                    {
                        markup: [
                            {
                                tagName: 'rect',
                                selector: 'labelBody',
                            },
                            {
                                tagName: 'text',
                                selector: 'labelText',
                            },
                        ],
                        attrs: {
                            labelText: {
                                text: '优先级：1',
                                fill: '#909399',
                                textAnchor: 'middle',
                                textVerticalAnchor: 'middle',
                            },
                            labelBody: {
                                ref: 'labelText',
                                refX: -6,
                                refY: -3,
                                refWidth: '100%',
                                refHeight: '100%',
                                refWidth2: 12,
                                refHeight2: 6,
                                stroke: '#909399',
                                fill: '#fff',
                                strokeWidth: 1,
                                rx: 5,
                                ry: 5,
                            },
                        },
                        position: {
                            distance: 0.5,
                            args: {
                                keepGradient: true,
                                ensureLegibility: true,
                            },
                        },
                    },
                ],
                // 业务数据
                data: {
                    // 优先级
                    priority: 1,
                    // 是否启用
                    enabled: true,
                },
                zIndex: 0,
            },
            overwrite: true
        },
        {
            name: 'RuleEdge',
            options: {
                inherit: 'edge',
                attrs: {
                    line: {
                        stroke: '#67C23A',
                    },
                },
                labels: [
                    {
                        markup: [
                            {
                                tagName: 'rect',
                                selector: 'labelBody',
                            },
                            {
                                tagName: 'text',
                                selector: 'labelText',
                            },
                        ],
                        attrs: {
                            labelText: {
                                text: '优先级：1',
                                fill: '#909399',
                                textAnchor: 'middle',
                                textVerticalAnchor: 'middle',
                            },
                            labelBody: {
                                ref: 'labelText',
                                refX: -6,
                                refY: -3,
                                refWidth: '100%',
                                refHeight: '100%',
                                refWidth2: 12,
                                refHeight2: 6,
                                stroke: '#909399',
                                fill: '#fff',
                                strokeWidth: 1,
                                rx: 5,
                                ry: 5,
                            },
                        },
                        position: {
                            distance: 0.5,
                            args: {
                                keepGradient: true,
                                ensureLegibility: true,
                            },
                        },
                    },
                ],
                // 业务数据
                data: {
                    // 规则条件的附属边，根据条件执行的结果走 pass | fail
                    type: 'pass',
                    // 优先级
                    priority: 1,
                    // 是否启用
                    enabled: true,
                },
                zIndex: 0,
            },
            overwrite: true
        }
    ];

    /**
     * @description: 注册单元到X6
     * @return {void}
     */
    static register() {
        CustomCells.customNodes.forEach(node => {
            Graph.registerNode(node.name, node.options, node.overwrite);
        });

        CustomCells.customVueNodes.forEach(node => {
            Graph.registerVueComponent(node.name, node.options, node.overwrite);
        });

        CustomCells.customEdges.forEach(edge => {
            Graph.registerEdge(edge.name, edge.options, edge.overwrite);
        });
    }

    /**
     * @description: 获取所有的单元类型
     * @return {array}
     */
    static getAllTypes() {
        const types = [];
        CustomCells.customNodes.forEach(node => {
            types.push(node.name);
        });

        CustomCells.customEdges.forEach(edge => {
            types.push(edge.name);
        });

        return types;
    }
}