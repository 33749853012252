import { render, staticRenderFns } from "./relationSelect.vue?vue&type=template&id=89c83dda&scoped=true&"
import script from "./relationSelect.vue?vue&type=script&lang=js&"
export * from "./relationSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89c83dda",
  null
  
)

export default component.exports