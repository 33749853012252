<!-- eslint-disable -->
<template>
  <div class="w-full">
    <el-form
      ref="form"
      :rules="rules"
      :model="form"
      style="padding-right: 5px; padding-bottom: 5px"
      label-width="110px"
    >
      <p class="type-label-before-blue">基本信息</p>
      <el-form-item label="行为类别" prop="class">
        <el-select
          clearable
          filterable
          class="w-full"
          v-model="form.class"
          placeholder="请选择"
          @change="changeClass($event, behaviorClasses)"
        >
          <el-option
            v-for="item in behaviorClasses"
            v-if="obuuid !== 'global' || item.class.indexOf('-') > -1"
            :key="item.class"
            :label="
              item.class.indexOf('-') > -1
                ? '[全局] ' + item.desc
                : '[普通] ' + item.desc
            "
            :value="item.class"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="中文名称" prop="name">
        <el-input clearable v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-switch v-model="form.enabled"></el-switch>
      </el-form-item>
      <div class="w-full" v-if="show">
        <p class="type-label-before-blue">行为类配置</p>
        <div v-for="(val, index) in formTypeData" :key="index">
          <!-- 字段类型  type == field -->
          <el-form-item
            v-if="val.type == 'field'"
            :label="val.desc"
            :prop="val.required ? val.field : ''"
          >
            <field-select
              :object_uuid="obuuid"
              :needVirtual="
                val.needVirtual === undefined ? true : val.needVirtual
              "
              :effect="{
                value: form[val.effect],
                type: getEffectType(val.effect),
              }"
              v-model="form[val.field]"
            >
            </field-select>
          </el-form-item>
          <!-- 字段类型结束 -->

          <!-- 字典类型：选择当前对象的字典或者全局字典  type == dictionary -->
          <el-form-item
            v-else-if="val.type == 'dictionary'"
            :label="val.desc"
            :prop="val.required ? val.field : ''"
          >
            <DictionarySelect :object_uuid="obuuid" v-model="form[val.field]"></DictionarySelect>
          </el-form-item>
          <!-- 字典类型结束 -->

          <!-- 关联关系类型 type == relationship -->
          <el-form-item
            v-else-if="val.type == 'relationship'"
            :label="val.desc"
            :prop="val.required ? val.field : ''"
          >
            <RelationSelect
              :object_uuid="obuuid"
              :effect="{
                value: form[val.effect],
                type: getEffectType(val.effect),
              }"
              v-model="form[val.field]"
            />
          </el-form-item>
          <!-- 关联关系类型结束 -->

          <!-- 普通选择类型 type == select -->
          <el-form-item
            v-else-if="val.type == 'select' && !val.view_uuid"
            :label="val.desc"
            :prop="val.required ? val.field : ''"
          >
            <el-select
              class="w-full"
              clearable
              v-model="form[val.field]"
              placeholder="请选择"
            >
              <el-option
                v-for="(val, key, index) in val.options"
                :key="index"
                :label="val"
                :value="key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 普通选择类型结束 -->
          <!-- 视图选择类型开始 -->
          <el-form-item
            v-else-if="val.type == 'select' && val.view_uuid"
            :label="val.desc"
            :prop="val.required ? val.field : ''"
          >
            <view-data-select
              v-model="form[val.field]"
              :view_uuid="val.view_uuid"
              :label="val.label ? val.label : 'name'"
              :value_key="val.value_key ? val.value_key : 'id'"
            />
          </el-form-item>
          <!-- 视图选择类型结束 -->

          <!-- 开关 switch 类型 -->
          <el-form-item
            v-else-if="val.type == 'switch'"
            :label="val.desc == '抛出字典转换失败错误' ? '抛出错误' : val.desc"
            :prop="val.required ? val.field : ''"
          >
            <el-switch v-model="form[val.field]"></el-switch>
          </el-form-item>
          <!-- 开关 switch 类型结束 -->

          <!-- 取值类 valueClass 类型 -->
          <ValueForm
            v-else-if="val.type == 'valueClass'"
            :formData="form[val.field]"
            :obuuid="obuuid"
            v-model="form[val.field]"
            realTime
            :title="val.desc"
            :needTitle="false"
            :dictionaryList="JSON.parse(JSON.stringify(dictionaryList))"
            :relationshipList="[]"
            :fieldList="JSON.parse(JSON.stringify(fieldList))"
          />
          <!-- 取值类 valueClass 类型结束 -->

          <!-- 规则条件类型 rule 类型开始-->
          <tree-form
            v-else-if="val.type == 'rule'"
            ref="treeForm"
            :obuuid="obuuid"
            v-model="form[val.field]"
            :dictionaryList="JSON.parse(JSON.stringify(dictionaryList))"
            :relationshipList="JSON.parse(JSON.stringify([]))"
            :formData="
              form[val.field] && form[val.field] != null
                ? [form[val.field]]
                : null
            "
            :fieldList="JSON.parse(JSON.stringify(fieldList))"
          />
          <!-- 规则条件类型 rule 类型结束 -->

          <!-- 对象取值 Object 类型开始 -->
          <el-form-item
            v-else-if="val.type == 'object'"
            :label="val.desc"
            :prop="val.required ? val.field : ''"
          >
            <object-select v-model="form[val.field]"> </object-select>
          </el-form-item>
          <!-- 对象取值 Object 类型结束 -->

          <!-- 视图 View 类型开始 -->
          <el-form-item
            v-else-if="val.type == 'view'"
            :label="val.desc"
            :prop="val.required ? val.field : ''"
          >
            <view-select
              v-model="form[val.field]"
              :effect="{
                value: form[val.effect],
                type: getEffectType(val.effect),
              }"
              :object_uuid="null"
            >
            </view-select>
          </el-form-item>
          <!-- 视图 view 类型结束 -->

          <!-- TIPS 类型开始 -->
          <el-form-item v-else-if="val.type == 'tips'" :label="val.desc">
            <el-alert effect="dark" :type="val.showType">
              <strong>{{ val.value }}</strong>
            </el-alert>
          </el-form-item>
          <!-- TIPS 类型结束 -->

          <!-- 短信发送模版 类型开始 -->
          <el-form-item
              v-else-if="val.type == 'SMSTemplate'"
              :label="val.desc"
              :prop="val.required ? val.field : ''"
              :style=""
          >
            <sms-template-select v-model="form[val.field]"> </sms-template-select>
          </el-form-item>
          <!-- 短信发送模版 类型结束 -->

          <!-- 其它类型全部用input -->
          <el-form-item
            v-else
            :label="val.desc"
            :prop="val.required ? val.field : ''"
          >
            <el-input v-model="form[val.field]"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import object from "@/api/objects";
import ValueForm from "@/views/objects/components/valueForm.vue";
import ViewSelect from "../../../components/metaSelect/ViewSelect.vue";
import FieldSelect from "../../../components/metaSelect/FieldSelect.vue";
import ObjectSelect from "../../../components/metaSelect/ObjectSelect.vue";
import RelationSelect from "../../../components/metaSelect/RelationSelect.vue";
import ViewDataSelect from "../../../components/metaSelect/ViewDataSelect.vue";
import DictionarySelect from "../../../components/metaSelect/DictionarySelect.vue";
import SmsTemplateSelect from "../../../components/smsSelect/SMSTemplateSelect.vue";
import { Alert } from "element-ui";

// import ValueForm from "./valueForm.vue";
export default {
  name: "behaviorsForm",
  components: {
    ValueForm,
    ViewSelect,
    FieldSelect,
    ObjectSelect,
    RelationSelect,
    ViewDataSelect,
    DictionarySelect,
    SmsTemplateSelect,
    'el-alert': Alert,
  },
  props: {
    formData: {
      type: Object,
      default: null,
    },
    obuuid: {
      type: String,
      default: "global",
    },
    dictionaryList: {
      type: Array,
      default: () => [],
    },
    fieldList: {
      type: Array,
      default: () => [],
    },
    relationshipList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        name: "",
        class: "",
        enabled: true, //是否启用
      },
      rules: {
        class: [{ required: true, message: "请选择", trigger: "change" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      show: false,
      formTypeData: [],
      title: "",
      showFieldList: this.fieldList,
      behaviorClasses: [],
    };
  },
  computed: {},
  watch: {
    form: {
      handler(val){
        let form = JSON.parse(JSON.stringify(val));
        let data = {
          name: form.name,
          enabled: form.enabled,
          items: { class: form.class, payload: {} },
        };
        delete form.class;
        delete form.enabled;
        delete form.name;
        data.items.payload = form;
        this.$emit('addFn', data);
      },
      deep: true,
    }
  },
  methods: {
    /**
     * @description: 获取字段的类型
     * @param {*} field 字段名称
     * @return {*}
     */
    getEffectType(field) {
      let type = "unknown";
      this.formTypeData.forEach((element) => {
        if (element.field == field) {
          type = element.type;
        }
      });
      return type;
    },

    /**
     * @description: 行为类被选择
     * @param {*} type
     * @param {*} classes
     * @return {*}
     */
    changeClass(type, classes) {
      let inputs = classes.filter((v) => v.class == type)[0].inputs;
      this.rules = {
        class: [{ required: true, message: "请选择", trigger: "change" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      };
      this.extra = { class: type, payload: {} };
      this.form.class = type;
      // this.form.name = this.form.name;
      // this.form = { class: type, enabled: true, name: this.form.name };
      let _this = this;
      _this.formTypeData = [];
      this.$nextTick(() => {
        for (let key in inputs) {
          if (inputs[key].required) {
            if (
              inputs[key].type == "select" ||
              inputs[key].type == "switch" ||
              inputs[key].type == "field" ||
              inputs[key].type == "dictionary" ||
              inputs[key].type == "relationship"
            ) {
              if (inputs[key].type != "switch") {
                _this.rules[key] = [
                  { required: true, message: "请选择", trigger: "change" },
                ];
              }
            } else {
              _this.rules[key] = [
                { required: true, message: "请输入", trigger: "blur" },
              ];
            }
          }
          let obj = JSON.parse(JSON.stringify(inputs[key]));
          _this.$set(_this.extra.payload, key, inputs[key].default);
          _this.$set(_this.form, key, inputs[key].default);
          _this.$set(obj, "field", key);
          _this.formTypeData.push(obj);
        }
      });

      this.show = true;
    },
    commit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let form = JSON.parse(JSON.stringify(this.form));
          let data = {
            name: form.name,
            enabled: form.enabled,
            items: { class: form.class, payload: {} },
          };
          delete form.class;
          delete form.enabled;
          delete form.name;
          data.items.payload = form;
          this.$emit("addFn", data);
        } else {
          this.$emit("closeLoading");
        }
      });
    },
  },
  created() {},
  mounted() {
    if (this.formData != null) {
      let data = JSON.parse(JSON.stringify(this.formData));
      this.form.name = data.name;
      this.form.enabled = data.enabled;
      object
        .getBehaviorClasses()
        .then((res) => {
          this.behaviorClasses = res.data.data;
          if (data.items != undefined && data.items.class) {
            this.changeClass(data.items.class, this.behaviorClasses);
            this.form.class = data.items.class;
            if (data.items.payload) {
              this.$nextTick(() => {
                this.form = Object.assign(this.form, data.items.payload);
              });
            }
            // this.form = data.items.payload
          }
        })
        .catch(() => {
          this.$message.error("获取行为类数据失败，请刷新后重试！");
        });
    } else {
      object
        .getBehaviorClasses()
        .then((res) => {
          this.behaviorClasses = res.data.data;
        })
        .catch(() => {
          this.$message.error("获取行为类数据失败，请刷新后重试！");
        });
    }
  },
};
</script>

<style lang="less">
</style>
