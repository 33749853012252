<!--
 * @LastEditTime: 2022-10-28 14:10:25
 * @Description: 对象管理左侧操作按钮
 * @FilePath: /dataview-next/src/views/objects/components/leftTab.vue
 * @Date: 2022-01-19 17:11:03
 * @Author: lisushuang
 * @LastEditors: zx
-->
<template>
  <div class="tab">
    <div class="back" @click="backClick">
      <i class="iconfont iconc-chevron-left"></i>
      返回
    </div>
    <el-menu 
      :default-active="type" 
      class="el-menu-demo" mode="horizontal"
    >
      <el-menu-item 
        :index="item.type"
        v-for="(item, index) in listData"
        v-show="$route.query.enabled != 2 || !item.noAggregate"
        :key="index"
        @click="clickItem(item)"
      >
        <svg aria-hidden="true" class="color-icon">
          <use :xlink:href="item.icon" />
        </svg>
        <span>{{ item.name }}</span>
      </el-menu-item>
    </el-menu>
  </div>
  
</template>

<script>
import obOpMixin from "@/views/objects/objectOperationMixin";
import { Menu, MenuItem } from "element-ui";

export default {
  name: "leftTab",
  components: {
    'el-menu': Menu,
    'el-menu-item': MenuItem,
  },
  mixins: [obOpMixin],
  props: {},
  data() {
    return {
      listData: [
        { name: "字段集", icon: "#iconshuziziliao", type: "field" ,noAggregate:false},
        { name: "关联关系", icon: "#icona-shigongjinduhover", type: "relation" ,noAggregate:false},
        { name: "视图集", icon: "#iconshujubiaogeyinqing", type: "view" ,noAggregate:false},
        { name: "事件蓝图", icon: "#icona-tongjibaobiaohover", type: "eventGraph"  ,noAggregate:false},
        { name: "表单", icon: "#icona-biaodanyinqinghover", type: "form" ,noAggregate:false},
      ],
      type: "",
      obuuid: "",
      obname: "",
    };
  },
  computed: {},
  methods: {
    clickItem(data) {
      if (data.type == "form") {
        this.$router.replace({
          path: "/dwh/form-list",
          query: {
            obuuid: this.obuuid,
            obname: this.obname,
            type: data.type,
            obcode: this.$route.query.obcode,
            enabled:this.$route.query.enabled,
          },
        });
      } else {
        this.$router.replace({
          path: "/dwh/objects/detail",
          query: {
            obuuid: this.obuuid,
            obname: this.obname,
            type: data.type,
            is_virtual:this.obuuid.indexOf('formModule') > -1 ? 1 : 0,
            enabled:this.$route.query.enabled,
            obcode: this.$route.query.obcode,
          },
        });
      }
    },
    backClick() {
      this.$router.push({ path: '/data-store/list' })
    },
  },
  mounted() {
    this.type = this.$route.query.type;
    this.obuuid = this.$route.query.obuuid;
    this.obname = this.$route.query.obname;

    if(this.$route.query.enabled == 2){
      this.listData.unshift({ name: "聚合配置", icon: "icongis_baocun", type: "aggregateConfig",noAggregate:false },)
    }
  },
};
</script>
<style lang="less" scoped>
  .tab {
    position: relative;
    .back {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 20px;
      z-index: 9;
      transform: translateY(-50%);
      i {
        font-size: 18px;
      }
    }
    .el-menu-demo {
      display: flex;
      justify-content: center;
      span {
        padding-left: 6px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
.tab-item {
  text-align: center;
  padding: 5px 0;
  cursor: pointer;
  i {
    font-size: 25px;
  }
  p {
    font-size: 12px;
    padding-top: 10px;
  }
}
.active-tab {
  color: #007FE0;
}
</style>
