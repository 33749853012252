<!--
 * @Author: lisushuang
 * @Date: 2021-09-23 17:53:16
 * @LastEditors: lisushuang
 * @LastEditTime: 2022-08-23 18:10:47
 * @Description: 字段表单
 * @FilePath: /dataview-next/src/views/objects/components/baseForm.vue
-->
<template>
  <div class="w-full h-full">
    <p class="type-label-before-blue">基本信息</p>
    <el-form ref="form" :rules="rules" :model="form" label-width="110px">
      <el-form-item label="字段标识" prop="code">
        <el-input
          :disabled="formData != null ? true : false"
          v-model="form.code"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="字段UUID"
        prop="uuid"
        v-if="form && form.uuid !== undefined"
      >
        {{ form.uuid ? form.uuid : ""
        }}<el-button
          style="margin-left: 10px"
          type="primary"
          @click="$globalAction('showMetaQoutes', form)"
          >查看引用</el-button
        >
      </el-form-item>
      <el-form-item label="中文名称" prop="name">
        <el-input clearable v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="字段备注" prop="comment">
        <el-input type="textarea" autosize v-model="form.comment"></el-input>
      </el-form-item>
      <el-form-item label="字段类型" prop="type">
        <el-select
          :disabled="formData != null ? true : false"
          class="w-full"
          clearable
          v-model="form.type"
          placeholder="请选择"
          @change="selectChange"
        >
          <el-option
            v-for="item in fieldTypes"
            :key="item.value"
            :label="item.name"
            :value="item.value"
            :disabled="item.value == 'string'"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="默认值">
        <el-input
          :disabled="formData != null ? true : false"
          v-model="form.default"
        ></el-input>
      </el-form-item>
      <el-form-item label="允许搜索">
        <el-switch v-model="form.is_search"></el-switch>
      </el-form-item>
      <el-form-item v-if="formData != null ? true : false" label="创建时间">
        <el-input :disabled="true" v-model="form.created_at"></el-input>
      </el-form-item>
      <el-form-item v-if="formData != null ? true : false" label="修改时间">
        <el-input :disabled="true" v-model="form.updated_at"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import object from "@/api/objects";
export default {
  name: "baseForm",
  components: {},
  props: {
    formData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        // 表单数据
        code: "", // 字段标识
        name: "", // 字段名称
        comment: "", // 字段备注
        type: "", // 字段类型
        default: "", // 字段默认值
        is_search: false, // 字段是否允许搜索
        created_at: "",
        updated_at: "",
        class: "", // 字段填写的取值类信息
      },
      // 表单验证规则
      rules: {
        code: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "code的长度在2到30个字符之间",
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z0-9_]+$/,
            message: "code只能是字母和数字以及下划线",
          },
        ],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        type: [{ required: true, message: "请选择", trigger: "change" }],
      },
      // 字段类型值：从 objects 对象引用
      fieldTypes: object.fieldTypes,
    };
  },
  watch: {
    // 监听 form.name 在新增时让名称和备注同步修改
    "form.name": {
      handler(val) {
        if (this.formData === null) {
          this.form.comment = val;
        }
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    /**
     * @description: 字段类型切换时触发
     * @param {*} data  选择的数据
     * @return {*}
     */
    selectChange(data) {
      if (data == "append") {
        this.$emit("changeType", "virtual");
      } else {
        delete this.form.extra;
        this.$emit("changeType", "base");
      }
    },

    /**
     * @description: 提交事件，抛出 addFn或者 closeLoading
     * @param {*}
     * @return {*}
     */
    commit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.form));
          this.$emit("addFn", data);
        } else {
          this.$emit("closeLoading");
        }
      });
    },
  },
  mounted() {
    if (this.formData != null) {
      this.form = JSON.parse(JSON.stringify(this.formData));
    }
  },
};
</script>

<style lang="less">
</style>