<!--
 * @Author: ttheitao
 * @Description: 
 * @Date: 2022-06-13 11:10:30
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-06-15 18:18:06
-->
<template>
    <div class="x6-debug-container">
        <!-- 缩略图列表 -->
        <div class="thumb-list scrollbar">
            <div
                :class="{ item: true, active: item.active }"
                v-for="(item, index) in runList"
                :key="index"
            >
                {{ item.blueprint.name }}
            </div>
        </div>
        <!-- 实例列表 -->
        <div class="instance-list" ref="graphList"></div>
        <!-- 表单 -->
        <div class="debug-form scrollbar">
            <el-form class="main">
                <el-form-item label="curl (bash)">
                    <el-input
                        type="textarea"
                        :rows="20"
                        placeholder="请输入内容"
                        v-model="curlData"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="单节点动画时长（毫秒）">
                    <el-input
                        type="number"
                        placeholder="节点的动画时长，单位毫秒"
                        v-model="ms"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
            <div class="btns">
                <el-button type="primary" @click="runDebug"> 执行 </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import EventDebug from "./js/EventDebug.js";

export default {
    name: "EventDebug",
    data() {
        return {
            instance: null,
            curlData: "",
            ms: 500,
            runList: [
                // {
                //     // 缩略图
                //     thumb: "",
                //     // 蓝图uuid
                //     blueprintUuid: track.code,
                //     // 当前已执行栈
                //     tracks: [],
                //     // 蓝图执行序列
                //     zIndex: 0,
                // },
            ],
        };
    },
    created() {
        this.instance = EventDebug.vm(this);
    },
    methods: {
        runDebug() {
            this.instance.runDebug();
        },
    },
};
</script>

<style lang="less" scoped>
.x6-debug-container {
    height: 100%;
    display: flex;

    .thumb-list {
        width: 170px;
        height: 100%;
        margin-right: 20px;
        .item {
            border: 1px solid #dcdfe6;
            margin-bottom: 10px;
            padding: 10px;
            border-radius: 5px;
            background: #fff;
            &.active {
                color: #fff;
                border: 1px solid #409eff;
                background: #409eff;
            }
            .thumb {
                // width: 150px;
                height: 150px;
            }
        }
    }
    .instance-list {
        flex: 1;
        width: 100%;
        height: 100%;
        border: 1px solid #c0c4c0;
        position: relative;
        overflow: hidden;
    }
    .debug-form {
        width: 200px;
        height: 100%;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        .main {
            flex: 1;
        }
        .btns {
            text-align: right;
        }
    }

    .scrollbar {
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #dddee0;
        }
        &::-webkit-scrollbar-thumb:hover {
            background-color: #c7c9cc;
        }
        &::-webkit-scrollbar-thumb:active {
            background-color: #c7c9cc;
        }
    }
}
</style>