<!--
 * @LastEditTime: 2022年8月10日 15:10:08
 * @Description: 事件选择器
 * @FilePath: /dwh_front/src/components/smsSelect/SMSTemplateSelect.vue
 * @Date: 2022年8月10日 15:10:12
 * @Author: lw
 * @LastEditors: lw
-->

<template>
  <el-select
      filterable
      allow-create
      v-model="selectValue"
      style="width: 100%"
      v-loading="selectLoading"
      default-first-option
      element-loading-spinner="el-icon-loading"
  >
    <el-option
        v-for="(item, index) in selectData"
        :key="index"
        :label="item.name"
        :value="item.uuid"
    >
    </el-option>
  </el-select>
</template>

<script>
import objects from "@/api/objects";

export default {
  name: "SMSTemplateSelect",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    selectValue() {
      this.$emit("input", this.selectValue);
    },
    value(val, oldVal) {
      if (val && !oldVal && !this.selectValue) {
        this.selectValue = val;
      }
    },
  },
  data() {
    return {
      selectData: [],
      selectValue: "",
      selectLoading: true,
    };
  },
  methods: {
    getSelectData() {
      this.selectLoading = true;
      // 重新请求事件列表数据
      objects.mapi.common({
        __method_name__: "dataList",
        object_uuid: "object62f31ac61ecfa",
        view_uuid: "view62f35bd1e7e1d",
        transcode: 0,
      }).then((res) => {
        this.selectLoading = false;
        if (res.data.code == 200) {
          this.selectData = res.data.data;
        } else {
          this.selectData = [];
        }
      }).catch(() => {
        this.selectLoading = false;
        this.selectData = [];
      });
    },
  },
  mounted() {
    this.getSelectData();
    this.selectValue = this.value
  },
};
</script>
