<!--
 * @Author: ttheitao
 * @Description: 
 * @Date: 2022-05-27 17:14:56
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-09-23 10:09:51
-->
<template>
    <div class="base-config-wrap">
        <div class="x6-config-form">
            <div class="x6-config-item">
                <div class="label">中文名称</div>
                <div class="content">
                    <el-input
                        v-model="data.name"
                        size="small"
                        @input="onPropChange"
                    />
                </div>
            </div>
            <div class="x6-config-item">
                <div class="label">事件类别</div>
                <div class="content">
                    <el-select
                        class="w-full"
                        clearable
                        v-model="data.type"
                        placeholder="请选择"
                        size="small"
                        @change="onPropChange"
                    >
                        <el-option
                            v-for="item in eventTypes"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="tips">
                    <i class="el-icon-info"></i> 系统事件不受优先级影响
                </div>
            </div>
            <div class="x6-config-item">
                <div class="label">事件名</div>
                <div class="content">
                    <el-input
                        v-model="data.event"
                        placeholder="请填写"
                        v-if="data.type == 2"
                        size="small"
                        @input="onPropChange"
                    />
                    <el-select
                        class="w-full"
                        clearable
                        v-model="data.event"
                        placeholder="请选择"
                        v-if="data.type == 1"
                        size="small"
                        @change="onPropChange"
                    >
                        <el-option
                            v-for="item in systemEvents"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="x6-config-item">
                <div class="label">是否启用</div>
                <div class="content">
                    <el-switch v-model="data.enabled" @change="onPropChange" />
                </div>
            </div>
            <el-divider content-position="left">附加操作（蓝图保存后生效）</el-divider>
            <div style="text-align: center; margin-top: 10px">
                <el-button @click="triggerAll()" type="danger" size="small">
                    触发所有数据
                </el-button>

                <el-button @click="triggerOne()" type="warning" size="small">
                    触发单条数据
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import dataMixin from "../mixins/dataMixin.js";
import object from "@/api/objects";

export default {
    mixins: [dataMixin],
    data() {
        return {
            systemEvents: object.systemEvents, //系统事件
            eventTypes: object.eventTypes, //事件类别
        };
    },
    methods: {
        triggerAll() {
            if (!this.data.event) {
                return this.$message.error("请选择事件名！");
            }

            if (!this.data.enabled) {
                return this.$message.error("事件已禁用！");
            }

            this.$confirm(
                "确认触发所有数据的该事件吗？请再次检查配置中是否含有动态数据，动态数据可能会导致不可预知的结果",
                "提示"
            )
                .then((res) => {
                    if (res == "confirm") {
                        const loading = this.$loading();
                        object
                            .eventsTriggerAll(this.cellProps.id)
                            .then((res) => {
                                loading.close();
                                if (res.data.code == 200) {
                                    this.$message.success("操作成功");
                                }
                            })
                            .catch((res) => {
                                console.log(res);
                                loading.close();
                                this.$message.error("请求失败或未保存当前蓝图");
                            });
                    }
                })
                .catch(() => {});
        },
        triggerOne() {
            this.$prompt("请输入数据id", "提示")
                .then((res) => {
                    if (res.action !== "confirm") return;
                    if (!res.value || parseInt(res.value) <= 0) return;
                    const loading = this.$loading();
                    object
                        .eventsTriggerOne(this.cellProps.id, res.value)
                        .then((res) => {
                            loading.close();
                            if (res.data.code == 200) {
                                this.$message.success("操作成功");
                            }
                        })
                        .catch((res) => {
                            console.log(res);
                            loading.close();
                            this.$message.error("请求失败或未保存当前蓝图");
                        });
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="less" scoped>
.fixed-width {
}
</style>