<template>
  <div v-loading="loading" class="w-full h-full overflow-hidden" style="display: flex;flex-direction: column;">
    <div class="top-tool">
      <leftTab />
    </div>
    <el-row class="h-full" style="display: flex;flex-direction: column;">
      <div class="top-button">
        <el-button type="primary" @click="addDialog = true">新增</el-button>
      </div>
      <div class="list-data">
        <el-table
          v-loading="btnLoading"
          ref="listData"
          :data="listData"
          border
          center
          style="width: 100%;"
          height="calc(100%)"
          highlight-current-row
        >
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column prop="uuid" label="uuid" width="180"></el-table-column>
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="code" label="关系标识" width="180"></el-table-column>
          <el-table-column label="关系类型" width="180">
            <template slot-scope="scope">
              <div
                class="type-container"
                :style="getType(scope.row) ?
                'border: 1px solid green;color: rgb(66, 182, 9);background: rgba(103, 194, 58, 0.4)' :
                'border: 1px solid rgb(245, 108, 108);color: rgb(245, 108, 108);background: rgba(245, 108, 108, 0.4)'
                "
                @click="openEdit(scope.row)"
              >
                {{ getType(scope.row) ? '简单模式' : '专业模式' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名称">
            <template slot-scope="scope">
              <div>
                <el-input
                  v-model="scope.row.name"
                  @blur="saveRowData(scope.row, scope.$index)"
                ></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="关联对象">
            <template slot-scope="scope">
              <div>
                <ObjectSelect
                  v-model="scope.row.related_uuid"
                  :objectsData="objectData"
                  @on-change="saveRowData(scope.row, scope.$index, true)"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="关联视图">
            <template slot-scope="scope">
              <RelationSelect v-model="scope.row" :index="scope.$index" @saveRowData="saveRowData" />
            </template>
          </el-table-column>
          <el-table-column :key='num' label="映射的字段">
            <template slot-scope="scope">
              <div>
                <el-select
                  class="w-full"
                  clearable
                  :value="getSimpleModeFieldUuid(scope.row)"
                  placeholder="本对象在关联表或本表中变成了哪个字段？"
                  @input="solveSimpleFieldChange($event, scope.row, scope.$index)"
                >
                  <el-option
                    v-for="(item, index) in SimpleModeFields(scope.row)"
                    :key="item.uuid + index"
                    v-show="item.type != 'append'"
                    :disabled="item.uuid == scope.row.related_pivot_uuid"
                    :label="
                      (item.object_uuid === obuuid ? '本表 - ' : '关联表 - ') +
                      item.name
                    "
                    :value="item.uuid"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-popconfirm
                :title="'是否确认删除' + scope.row.name + '?'"
                @confirm="deleteItem(scope.row)"
              >
                <span slot="reference" style="color: rgb(245, 108, 108)">删除</span>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-row>
    <b-modal
      ref="elmodal"
      mode="drawer"
      title="新增关联"
      v-if="addDialog"
      :show="addDialog"
      @sure="$refs.relationsForm.commit()"
      @cancel="addDialog = false"
    >
      <relations-form
        slot="content"
        ref="relationsForm"
        :objectData="objectData"
        :ownerFieldData="ownerFieldData"
        :obuuid="obuuid"
        @addFn="addFn"
        @closeLoading="closeLoading"
      />
    </b-modal>
    <b-modal
      ref="elEditmodal"
      mode="drawer"
      title="编辑关联"
      v-if="editDialog"
      :show="editDialog"
      @sure="$refs.relationsEditForm.commit()"
      @cancel="editDialog = false"
    >
      <relations-form
        slot="content"
        ref="relationsEditForm"
        :formData="detailData"
        :objectData="objectData"
        :ownerFieldData="ownerFieldData"
        :obuuid="obuuid"
        @addFn="addFn($event, true)"
        @closeLoading="closeLoading"
      />
    </b-modal>
  </div>
</template>

<script>
import object from "@/api/objects";
import leftTab from "@/views/objects/components/saasTab.vue";
// import leftItem from "@/components/leftItem.vue";
import relationsForm from "@/views/objects/components/relationsForm.vue";
import ObjectSelect from "@/components/metaSelect/ObjectSelect";
import RelationSelect from "@/views/objects/components/relationSelect.vue";
import collect from "collect.js";
import { Popconfirm } from "element-ui";
export default {
  name: "relationsList",
  components: {
    // leftItem,
    relationsForm,
    leftTab,
    ObjectSelect,
    RelationSelect,
    'el-popconfirm': Popconfirm,
  },
  props: {
    obuuid: {
      type: String,
      default: "global",
    },
    obname: {
      type: String,
      default: "全局字段",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      listData: [],
      copyListData: [],
      objectData: [], //字段集
      defaultRow: "",
      show: false,
      addDialog: false,
      fieldTypes: object.fieldTypes,
      dictsList: [], //字典数据
      loading: false,
      btnLoading: false,
      testModalShow: false, //测试弹框
      testModalValue: "",
      logData: [], //日志数据
      ownerFieldData: [], //本表的字段
      ownerFields: [],
      relatedFieldData: [], //对象的字段数据
      pivotFieldData: [], //中间表字段数据
      num: 0,
      /* 编辑复杂类型 */
      editDialog: false,
      detailData: null, //详情
    };
  },
  computed: {},
  methods: {
    async getData(type) {
      this.loading = true;
      await this.getSelectList();
      object.getMyRelations(this.obuuid).then((res) => {
        if (
          res.data.code == 200 &&
          res.data.data &&
          res.data.data.data &&
          res.data.data.data.length != 0
        ) {
          if (this.detailData == null) {
            if (this.$route.query.relationship_uuid) {
              let one = collect(res.data.data.data)
                .where("uuid", this.$route.query.relationship_uuid)
                .first();
              if (one) {
                this.defaultRow = one;
              }
            } else {
              this.defaultRow = res.data.data.data[0];
            }
          } else {
            if (type != undefined) {
              if (type == "del") {
                this.defaultRow = res.data.data.data[0];
              }
              if (type == "change") {
                if (this.$store.state.dataObjects.valueFormDatas != "") {
                  let valueFormDatas = JSON.parse(
                    this.$store.state.dataObjects.valueFormDatas
                  );
                  this.$store.commit(
                    "setValueFormDatas",
                    JSON.stringify({
                      obuuid: this.obuuid,
                      dictionaryList: valueFormDatas.dictionaryList,
                      fieldList: valueFormDatas.fieldList,
                      relationshipList: res.data.data.data,
                    })
                  );
                }
              }
            } else {
              this.defaultRow = this.detailData;
            }
          }
          this.listData = res.data.data.data;
          this.copyListData = JSON.parse(JSON.stringify(this.listData))
          this.loading = false;
        } else {
          this.listData = [];
          this.copyListData = []
          this.detailData = null;
          this.loading = false;
        }
      });
    },
    getSelectList() {
      if (this.objectData.length == 0) {
        object.getAll(1, 10000).then((res) => {
          this.objectData = res.data.data.data;
        });
      }
      if (this.ownerFieldData.length == 0) {
        object.getFields(this.obuuid).then((res) => {
          if (
            res.data.code == 200 &&
            res.data.data &&
            res.data.data.data &&
            res.data.data.data.length != 0
          ) {
            this.ownerFieldData = res.data.data.data;
            this.ownerFields = this.ownerFieldData;
          }
        });
      }
    },
    rowClick(data) {
      //点击左侧
      this.detailData = null;
      this.$nextTick(() => {
        this.detailData = data;
        this.editDialog = true
      });
    },
    rowDel(data) {
      //删除左侧列表内容
      object.deleteRealtion(data.uuid).then((res) => {
        if (res.data.code == 200) {
          this.getData("del");
        }
      });
    },
    closeLoading() {
      if (this.$refs.elmodal && this.$refs.elmodal.loading) {
        this.$refs.elmodal.loading = false;
      }
      if (this.$refs.elEditmodal && this.$refs.elEditmodal.loading) {
        this.$refs.elEditmodal.loading = false;
      }
    },
    addFn(data, boo) {
      console.log(boo, 'boo')
      if(boo) {
        data.data_id = this.detailData.id
        this.btnLoading = true;
        object.editRelation(this.detailData.uuid, data).then((res) => {
          this.editDialog = false;
          this.btnLoading = false;
          if (res.status === 200 && res.data?.code === 200) {
            this.getData("change");
            this.$message.success('修改成功!');
          }
        })
        this.$refs.elEditmodal.loading = false;
        return
      }
      object.addRelation(data).then((res) => {
        if (res.data.code == 200) {
          this.addDialog = false;
          this.getData("change");
        } else {
          this.$refs.elmodal.loading = false;
        }
      });
    },
    openLogs() {
      //查看日志
      object.apiLogs(this.detailData.uuid).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data) {
            this.logData = res.data.data;
          }
        }
      });
    },
    onSubmit(info) {
      //保存
      this.btnLoading = true;
      info.data_id = info.id
      const { code, name, owner_pivot_uuid, owner_primary_uuid, owner_uuid, pivot_uuid, related_pivot_uuid, related_primary_uuid, related_uuid, type, view_uuid } = info
      const params = {
        code,
        name,
        owner_pivot_uuid,
        owner_primary_uuid,
        owner_uuid,
        pivot_uuid,
        related_pivot_uuid,
        related_primary_uuid,
        related_uuid,
        type,
        view_uuid
      }
      object.editRelation(info.uuid, params).then((res) => {
        this.btnLoading = false;
        if (res.status === 200 && res.data?.code === 200) {
          this.$message.success('修改成功!');
        }
      })
    },
    getRelatedFieldData() {
      object.getFields(this.obuuid).then((res) => {
        if (res.data.code == 200 && res.data.data && res.data.data.data) {
          this.relatedFieldData = res.data.data.data;
          this.pivotFieldData = this.relatedFieldData;
        }
      })
    },
    /* 判断 */
    getSimpleModeFieldUuid(data) {
      if (data.SimpleModeFieldUuid) {
        return data.SimpleModeFieldUuid
      }
      if (data.type === 1) {
        // 反向一对一返回 对方在中间表的字段，否则就是自己在中间表的字段
        if (data.pivot_uuid === this.obuuid) {
          data.SimpleModeFieldUuid = data.related_pivot_uuid
          return data.related_pivot_uuid;
        } else {
          data.SimpleModeFieldUuid = data.owner_pivot_uuid
          return data.owner_pivot_uuid;
        }
      } else if (data.type === 2) {
        // 一对多永远只返回自己在中间表的字段
        data.SimpleModeFieldUuid = data.owner_pivot_uuid
        return data.owner_pivot_uuid;
      }
    },
    SimpleModeFields(data) {
      let fields = [];
      if (data.type === 2) {
        fields = this.relatedFieldData;
      }
      if (data.type === 1) {
        fields = collect(this.ownerFields)
          .merge(this.relatedFieldData)
          .unique("uuid")
          .toArray();
      }
      return fields;
    },
    /* boo 关联对象修改  清除视图选择 */
    saveRowData(data, index, boo) {
      if (boo && data.view_uuid !== this.copyListData[index].view_uuid) {
        data.view_uuid = ''
      }
      if (data.type === 1) {
        // 反向一对一返回 对方在中间表的字段，否则就是自己在中间表的字段
        if (data.pivot_uuid === this.obuuid) {
          data.related_pivot_uuid = data.SimpleModeFieldUuid
        } else {
          data.owner_pivot_uuid = data.SimpleModeFieldUuid
        }
      } else if (data.type === 2) {
        data.owner_pivot_uuid = data.SimpleModeFieldUuid
      }
      delete data.SimpleModeFieldUuid
      const oldInfo = this.copyListData[index]
      let num = 0
      for (const key in oldInfo) {
        if (Object.hasOwnProperty.call(oldInfo, key)) {
          const element = oldInfo[key];
          if (data[key] !== element) {
            console.log(key, '111111111')
            this.copyListData[index][key] = data[key]
            num += 1
          }
        }
      }
      if (num === 0) {
        return
      }
      const info = JSON.parse(JSON.stringify(data))
      this.onSubmit(info)
    },
    /**
     * @description: 简略模式请求的字段变化
     * @param {String} item 变化的item-uuid
     * @return {Void}
     */
    solveSimpleFieldChange(item, data, $index) {
      this.$set(data, 'SimpleModeFieldUuid', item)
      item = collect(this.SimpleModeFields).where("uuid", item).first();
      if (!item) {
        this.num += 1
        this.saveRowData(data, $index)
        return;
      }
      data.pivot_uuid = item.object_uuid;

      // 如果没有设置自己的主键，默认设置为id
      if (!data.owner_primary_uuid) {
        let owner_id = this.ownerFields.find((item) => item.name === "id");
        if (owner_id) data.owner_primary_uuid = owner_id.uuid;
      }
      // 如果没有设置被关联的主键，默认设置为id
      if (!data.related_primary_uuid) {
        let related_id = this.relatedFieldData.find(
          (item) => item.name === "id"
        );
        if (related_id) data.related_primary_uuid = related_id.object_uuid;
      }
      // 一对一关系处理
      if (data.type === 1) {
        // 正向一对一
        if (item.object_uuid !== this.obuuid) {
          // 中间表是对方对象
          data.pivot_uuid = item.object_uuid;
          this.pivotFieldData = this.relatedFieldData;
          // 那么这个字段是对方对象代表自己的字段
          data.owner_pivot_uuid = item.uuid;
          let related_id = collect(this.pivotFieldData)
            .where("code", "id")
            .first();
          data.related_pivot_uuid = related_id.uuid;
        } else {
          // 中间表是自己
          data.pivot_uuid = this.obuuid;
          this.pivotFieldData = this.ownerFields;
          // 反向一对一，中间表就是自己，那么代表自己的字段就是自己的id
          let owner_id = collect(this.pivotFieldData)
            .where("code", "id")
            .first();
          data.owner_pivot_uuid = owner_id.uuid;
          // 代表对方表的就是选择的那个字段
          data.related_pivot_uuid = item.uuid;
        }
      }
      // 一对多关系处理
      if (data.type === 2) {
        this.pivotFieldData = this.relatedFieldData;
        // 一对多只有自己在对方表的映射字段才有用
        data.owner_pivot_uuid = item.uuid;
        let related_id = collect(this.pivotFieldData)
          .where("code", "id")
          .first();
        data.related_pivot_uuid = related_id.uuid;
      }
      this.num += 1
      this.saveRowData(data, $index)
    },
    /* 删除 */
    deleteItem(data) {
      //删除关联关系
      object.deleteRealtion(data.uuid).then((res) => {
        if (res.data.code == 200) {
          this.getData("del");
        }
      });
    },
    getType(dataInfo) {
      return this.checkIfCanUseSimpleMode(true, dataInfo)
    },
    /**
     * @description: 检查是否可以使用简略模式
     * @param {Boolean} needInfo 是否需要展示报错信息
     * @return {Boolean} true可以使用，false不可以使用
     */
    checkIfCanUseSimpleMode(needInfo = true, dataInfo) {
      // 检查本对象主键是否是id
      let owner_id = collect(this.ownerFields).where("code", "id").first();
      if (
        dataInfo.owner_primary_uuid &&
        owner_id &&
        dataInfo.owner_primary_uuid !== owner_id.uuid
      ) {
        return false;
      }
      // 检查关联对象主键是否是id
      let related_id = collect(this.relatedFieldData)
        .where("code", "id")
        .first();
      if (
        dataInfo.related_primary_uuid &&
        related_id &&
        dataInfo.related_primary_uuid !== related_id.uuid
      ) {
        return;
      }
      // 如果是正向一对一 或者 一对多，那么关联对象映射是否是id
      if (
        dataInfo.related_pivot_uuid &&
        dataInfo.pivot_uuid &&
        ((dataInfo.type === 1 && dataInfo.pivot_uuid !== this.obuuid) ||
          dataInfo.type === 2)
      ) {
        if (related_id && dataInfo.related_pivot_uuid !== related_id.uuid) {
          if (needInfo)
          return false;
        }
      }
      // 如果是反向一对一，那么本对象映射是否是id
      if (
        dataInfo.pivot_uuid &&
        dataInfo.owner_pivot_uuid &&
        dataInfo.type === 1
      ) {
        if (owner_id && dataInfo.owner_pivot_uuid === this.obuuid) {
          return false;
        }
      }
      // 多对多关联不允许使用简略模式
      if (dataInfo.type === 3) {
        return false;
      }

      return true;
    },
    openEdit(data) {
      if (this.getType(data)) {
        return
      }
      console.log(data, '专业版打印')
      this.rowClick(data)
    },
  },
  mounted() {
    this.getData();
    this.getRelatedFieldData();
  },
};
</script>

<style lang="less" scoped>
.top-button {
  height: 52px;
  box-sizing: border-box;
  padding: 10px;
  text-align: right;
}
.list-data {
  flex: 1;
  overflow: hidden;
  padding: 0 10px;
  .type-container {
    display: inline-block;
    width: 60px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 4px;
  }
}
</style>
