<template>
  <div class="w-full h-full">
    <el-form
      ref="form"
      class="scroller overflow-y-auto overflow-x-hidden"
      :rules="rules"
      :model="form"
      label-width="110px"
    >
      <p class="type-label-before-blue">
        基本信息
        <el-button size="mini" type="primary" @click="showInfo = !showInfo">{{
          showInfo ? "收起" : "修改"
        }}</el-button>
      </p>
      <div v-if="showInfo">
        <el-form-item label="中文名称" prop="name">
          <el-input clearable v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="事件类别" prop="type">
          <el-select
            class="w-full"
            clearable
            v-model="form.type"
            placeholder="请选择"
          >
            <el-option
              v-for="item in eventTypes"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事件名" prop="event" v-if="form.type == 2">
          <el-input class="w-full" v-model="form.event" placeholder="请填写" />
        </el-form-item>
        <el-form-item label="事件名" prop="event" v-if="form.type == 1">
          <el-select
            class="w-full"
            clearable
            v-model="form.event"
            placeholder="请选择"
          >
            <el-option
              v-for="item in systemEvents"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div v-else>
        <p>
          事件描述：【{{ form.type == 2 ? "普通事件" : "系统事件" }} -
          {{ form.type == 1 ? getEventName() : form.event }}】- {{ form.name }}
        </p>
        <!-- <p v-if="formData && formData.uuid">uuid：{{formData.uuid}}</p> -->
      </div>

      <p class="type-label-before-blue">事件行为/顺序</p>

      <BehaviorSelect
        :object_uuid="obuuid"
        v-model="form.behavior"
      ></BehaviorSelect>
      <treeForm
        ref="treeForm"
        :obuuid="obuuid"
        :dictionaryList="dictionaryList"
        :relationshipList="relationshipList"
        :formData="formData && formData != null ? [formData.rules] : null"
        :fieldList="fieldList"
      />
    </el-form>
  </div>
</template>

<script>
import object from "@/api/objects";
import treeForm from "@/views/objects/components/treeForm.vue";
import BehaviorSelect from "@/components/metaSelect/BehaviorSelect.vue";

export default {
  name: "eventForm",
  components: {
    treeForm,
    BehaviorSelect,
  },
  props: {
    formData: {
      type: Object,
      default: null,
    },
    behaviorList: {
      type: Array,
      default: () => [],
    },
    fieldList: {
      type: Array,
      default: () => [],
    },
    dictionaryList: {
      type: Array,
      default: () => [],
    },
    relationshipList: {
      type: Array,
      default: () => [],
    },
    obuuid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showInfo: false,
      form: {
        name: "",
        type: "",
        event: "", //事件名
        behavior: [], //行为
        rules: [], //规则
        object_uuid: this.obuuid,
      },
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        type: [{ required: true, message: "请选择", trigger: "change" }],
        event: [{ required: true, message: "请选择", trigger: "change" }],
        behavior: [{ required: true, message: "请选择", trigger: "change" }],
      },
      systemEvents: object.systemEvents, //系统事件
      eventTypes: object.eventTypes, //事件类别
    };
  },
  computed: {},
  methods: {
    getEventName() {
      return object.getSystemEventName(this.form.event);
    },
    commit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.form));
          let rules = this.$refs.treeForm.data[0];
          // if(rules.children.length==0){
          // 	this.$message({
          // 		type: 'error',
          // 		message: '验证逻辑不能为空'
          // 	});
          // 	this.$emit('closeLoading')
          // 	return
          // }
          data.rules = JSON.parse(JSON.stringify(rules));
          for (let i = 0; i < data.behavior.length; i++) {
            if (data.behavior[i] == "请选择行为") {
              data.behavior.splice(i, 1);
            }
          }
          if (data.behavior.length == 0) {
            this.$message({
              type: "error",
              message: "请至少选择一个行为",
            });
            this.$emit("closeLoading");
            return;
          }
          this.$emit("addFn", data);
        } else {
          this.$emit("closeLoading");
        }
      });
    },
    getTypeName() {},
  },
  created() {},
  mounted() {
    if (this.formData != null) {
      let data = JSON.parse(JSON.stringify(this.formData));
      this.form.name = data.name;
      this.form.type = data.type;
      this.form.event = data.event; //事件名
      this.form.behavior = data.behavior; //行为
      this.form.rules = JSON.parse(JSON.stringify(data.rules)); //规则
    } else {
      this.showInfo = true;
    }
  },
};
</script>

<style lang="less">
</style>