<!--
 * @Author: ttheitao
 * @Description: x6 graph
 * @Date: 2022-05-25 15:40:57
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-04 16:47:37
-->
<template>
    <div class="x6-graph-container" :loading="loading">
        <div class="top-options">
            <div>
                <el-input
                    v-model="blueprint.name"
                    size="small"
                    style="width: 200px"
                    placeholder="蓝图名称"
                />
                <el-tag style="margin-left: 10px">
                    蓝图uuid: {{ blueprint.uuid }}
                </el-tag>
                <span style="font-size: 14px; margin-left: 10px">
                    是否启用：
                </span>
                <el-switch v-model="blueprint.enabled"> </el-switch>
            </div>
            <div>
                <el-button
                    type="warning"
                    size="small"
                    @click="showDebugModal = true"
                >
                    调试
                </el-button>
                <el-button type="primary" size="small" @click="submit">
                    保存
                </el-button>
            </div>
        </div>
        <div class="x6-graph-box">
            <div class="x6-stencil-box scrollbar-style1">
                <div
                    class="group"
                    v-for="(group, index) in sencilGroups"
                    :key="index"
                >
                    <div class="title">{{ group.name }}</div>
                    <div class="stencils">
                        <div
                            class="item"
                            :class="{
                                enabled: !child.enabled,
                                [child.category]: true,
                            }"
                            v-for="(child, cindex) in group.sencils"
                            :key="cindex"
                            draggable="true"
                            @dragstart="handleSencilDrag($event, child)"
                        >
                            <i class="iconfont" :class="child.icon"></i>
                            <span class="name">{{ child.name }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 画布 -->
            <div class="x6-main" ref="x6Main"></div>
            <!-- 单元配置面板 -->
            <div class="x6-panel scrollbar-style1" v-show="showPancel">
                <component
                    v-if="showPancel"
                    :is="curPanel"
                    :x6Proxy="x6Proxy"
                    :obuuid="obuuid"
                    :behaviorList="behaviorList"
                    :fieldList="fieldList"
                    :dictionaryList="dictionaryList"
                    :relationshipList="relationshipList"
                ></component>
                <el-empty
                    v-else
                    description="暂无面板信息"
                    :image-size="80"
                ></el-empty>
            </div>
            <!-- 右键菜单 -->
            <div class="contextmenu" ref="contextmenu">
                <div
                    @click="addNode('Event')"
                    :class="{ disabled: !menuStatus.Event }"
                >
                    插入事件
                </div>
                <div
                    @click="addNode('Rule')"
                    :class="{ disabled: !menuStatus.Rule }"
                >
                    插入条件
                </div>
                <div
                    @click="addNode('Behavior')"
                    :class="{ disabled: !menuStatus.Behavior }"
                >
                    插入行为
                </div>
                <div
                    @click="removeSelect()"
                    :class="{ disabled: !menuStatus.Del }"
                >
                    删除
                </div>
            </div>
        </div>
        <el-dialog
            title="蓝图调试器"
            :fullscreen="true"
            :visible.sync="showDebugModal"
            custom-class="blueprint-debug-dialog"
        >
            <event-debug v-if="showDebugModal"></event-debug>
        </el-dialog>
    </div>
</template>

<script>
/* eslint-disable */

import X6Graph from "./js/X6Graph.js";
import Tool from "./js/Tool.js";
import cellStart from "./panels/cellStart";
import cellEvent from "./panels/cellEvent";
import cellRule from "./panels/cellRule";
import cellBehavior from "./panels/cellBehavior";
import cellRunEdge from "./panels/cellRunEdge";
import cellRuleEdge from "./panels/cellRuleEdge";
import cellConditionGroup from "./panels/cellConditionGroup";
import cellCondition from "./panels/cellCondition";
import "./css/index.less";
import objects from "@/api/objects.js";
import { Loading } from "element-ui";
import EventDebug from "./EventDebug.vue";
import Store from "./js/Store";
import { Tag, Empty } from "element-ui";

export default {
    name: "x6Base",
    props: {
        // 蓝图uuid
        blueprint_uuid: {
            type: String,
            default: "",
        },
        formData: {
            type: Object,
            default: null,
        },
        behaviorList: {
            type: Array,
            default: () => [],
        },
        fieldList: {
            type: Array,
            default: () => [],
        },
        dictionaryList: {
            type: Array,
            default: () => [],
        },
        relationshipList: {
            type: Array,
            default: () => [],
        },
        obuuid: {
            type: String,
            default: "",
        },
    },
    components: {
        cellStart,
        cellEvent,
        cellRule,
        cellBehavior,
        cellRunEdge,
        cellRuleEdge,
        EventDebug,
        cellCondition,
        cellConditionGroup,
        'el-tag': Tag,
        'el-empty': Empty,
    },
    data() {
        return {
            x6Proxy: null,
            curCell: null,
            showPancel: false,
            blueprint: {
                uuid: "",
                name: "事件蓝图",
                cells: [],
                enabled: true,
                extra: {},
            },
            loading: false,
            showDebugModal: false,
            sencilGroups: [
                {
                    name: "事件（后端）",
                    sencils: [
                        {
                            name: "事件", // 节点名称
                            type: "Event", // 节点类型
                            category: "backend", // 节点分类
                            icon: "iconjijia_konghang", // iconfont 图标
                            enabled: true, // 是否启用
                        },
                        {
                            name: "行为",
                            type: "Behavior",
                            category: "backend",
                            icon: "icongis_baocun",
                            enabled: true,
                        },
                    ],
                },
                {
                    name: "视图（前端）",
                    sencils: [
                        {
                            name: "字段",
                            type: "Field",
                            category: "front",
                            icon: "iconbimgis_shuxing",
                            enabled: false,
                        },
                    ],
                },
                {
                    name: "规则（通用）",
                    sencils: [
                        {
                            name: "嵌套条件",
                            type: "Rule",
                            category: "common",
                            icon: "iconbim_goujianliebiao",
                            enabled: true,
                        },
                        {
                            name: "条件组",
                            type: "ConditionGroup",
                            category: "common",
                            icon: "iconjijia_tongji",
                            enabled: true,
                        },
                        {
                            name: "条件",
                            type: "Condition",
                            category: "common",
                            icon: "iconjijia_zhucai",
                            enabled: true,
                        },
                    ],
                },
            ],
        };
    },
    watch: {
        curCell: function (newVal) {
            this.showPancel = false;
            this.$nextTick(() => {
                if (newVal) {
                    this.showPancel = true;
                }
            });
        },
    },
    computed: {
        menuStatus: function () {
            const res = {
                Event: true,
                Rule: true,
                Behavior: true,
                Del: true,
            };
            this.curCell = this.x6Proxy ? this.x6Proxy.curCell : null;

            if (this.curCell) {
                if (this.x6Proxy.graph.isEdge(this.curCell)) {
                    res.Event = false;
                    res.Rule = false;
                    res.Behavior = false;
                } else {
                    if (this.curCell.shape === "Start") {
                        res.Rule = false;
                        res.Behavior = false;
                        res.Del = false;
                    } else if (this.curCell.shape === "Event") {
                        res.Event = false;
                    } else if (
                        ["Behavior", "Rule"].includes(this.curCell.shape)
                    ) {
                        res.Event = false;
                    }
                }
            }
            return res;
        },
        curPanel: function () {
            let shape = this.curCell.shape;
            if (this.curCell.shape === "vue-shape") {
                shape = this.curCell.component;
            }

            return `cell-${shape}`;
        },
    },
    mounted() {
        if (this.blueprint_uuid) {
            this.getData();
        } else {
            this.initX6Graph();
        }

        // 存储变量
        Store.obuuid = this.obuuid;
        Store.relationshipList = this.relationshipList;
        Store.dictionaryList = this.dictionaryList;
        Store.fieldList = this.fieldList;
        Store.behaviorList = this.behaviorList;
    },
    methods: {
        getData() {
            this.loading = true;
            objects
                .showBlueprint(this.blueprint_uuid)
                .then((res) => {
                    this.loading = false;
                    if (res.data.code === 200) {
                        this.blueprint = res.data.data;
                        this.blueprint.cells = Tool.dbToX6StyleCell(
                            this.blueprint.cells
                        );
                        this.initX6Graph();
                    }
                })
                .catch((err) => {
                    this.$message.error("请求服务器失败");
                    this.loading = false;
                    console.error(err);
                });
        },
        initX6Graph() {
            this.x6Proxy = new X6Graph(this, this.blueprint.cells);
        },
        addNode(type = "Behavior") {
            if (!this.menuStatus[type]) {
                return;
            }

            const curCell = this.curCell;
            const method = `add${type}Node`;
            this.x6Proxy[method](
                curCell.store.data.position.x + 160,
                curCell.store.data.position.y + Tool.randomRange(-100, 100)
            );
        },
        removeSelect() {
            if (this.menuStatus.Del) {
                this.x6Proxy.removeSelect();
            }
        },
        submit() {
            const loading = Loading.service({ fullscreen: true });
            this.blueprint.cells = this.x6Proxy.graph.toJSON().cells;
            if (this.blueprint.uuid) {
                objects
                    .editBlueprint(this.blueprint.uuid, this.blueprint)
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.blueprint = res.data.data;
                            this.blueprint.cells = Tool.dbToX6StyleCell(
                                this.blueprint.cells
                            );
                        }
                        this.$message.success("操作成功");
                        loading.close();
                    })
                    .catch((err) => {
                        this.$message.error("请求服务器失败");
                        console.error(err);
                        loading.close();
                    });
            } else {
                objects
                    .addBlueprint({
                        name: this.blueprint.name,
                        cells: this.blueprint.cells,
                        enabled: this.blueprint.enabled,
                        bindings: [this.obuuid],
                    })
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.blueprint = res.data.data;
                            this.blueprint.cells = Tool.dbToX6StyleCell(
                                this.blueprint.cells
                            );
                        }
                        this.$message.success("操作成功");
                        loading.close();
                    })
                    .catch((err) => {
                        this.$message.error("请求服务器失败");
                        console.error(err);
                        loading.close();
                    });
            }
            // this.$emit("submit", this.x6Proxy.graph.toJSON());
        },
        handleSencilDrag(e, sencil) {
            if (!sencil.enabled) {
                return false;
            }
            this.x6Proxy.handleSencilDrag(e, sencil);
        },
    },
};
</script>

<style lang="less" scoped>
.x6-graph-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .top-options {
        width: 100%;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .x6-graph-box {
        position: relative;
        display: flex;
        height: 100%;
        border: 1px solid #eaebed;
        overflow: hidden;

        .x6-main {
            flex: 1;
            margin-left: 190px;
            width: 100% !important;
            height: 100% !important;
        }
        .x6-panel {
            display: flex;
            flex-direction: column;
            width: 300px;
            // border-left: 1px solid #eaebed;
            padding: 10px;
            overflow: auto;
            z-index: 1;
        }

        .x6-stencil-box {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 190px;
            height: 100%;
            background: #f9f9f9;
            overflow: auto;

            .group {
                margin-bottom: 10px;
                .title {
                    padding: 0 10px;
                    line-height: 30px;
                    font-weight: bold;
                    font-size: 14px;
                    background: #ededed;
                }

                .stencils {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    .item {
                        width: 50px;
                        height: 50px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin: 10px 0 0 10px;
                        border-radius: 3px;
                        border: 1px solid #ccc;
                        box-sizing: border-box;
                        background: #fff;
                        cursor: move;
                        .name {
                            font-size: 12px;
                        }

                        &.enabled {
                            cursor: not-allowed;
                            opacity: 0.5;
                        }

                        &.backend {
                            color: #409eff;
                            border: 1px solid #409eff;

                            &:hover {
                                background: #409eff;
                                color: #fff;
                            }
                        }

                        &.front {
                            color: #67c23a;
                            border: 1px solid #67c23a;

                            &:hover {
                                background: #67c23a;
                                color: #fff;
                            }
                        }

                        &.common {
                            color: #e6a23c;
                            border: 1px solid #e6a23c;

                            &:hover {
                                background: #e6a23c;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    .contextmenu {
        position: absolute;
        display: none;
        left: 0;
        top: 0;
        width: 180px;
        padding: 5px 0;
        background: #fff;
        box-shadow: 0 2px 10px rgb(0 0 0 / 12%);
        div {
            padding: 10px;
            line-height: 20px;
            font-size: 14px;
            background: #fff;
            &:hover {
                background: #f5f5f5;
                cursor: pointer;
            }
            &.disabled {
                cursor: not-allowed;
                opacity: 0.4;
            }
        }
    }
}
</style>

<style lang="less">
.blueprint-debug-dialog {
    display: flex;
    flex-direction: column;
    .el-dialog__body {
        flex: 1;
        overflow: auto;
    }
}
</style>