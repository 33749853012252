<template>
  <div class="w-full">
    <p class="relative" style="height: 35px; text-align: left">
      <span
        class="type-label-before-blue"
        v-html="childData == null ? '新增字典项' : '编辑字典项'"
      ></span>
      <slot name="btn"></slot>
    </p>
    <el-form
      style="height: calc(100% - 35px); overflow-y: auto"
      :model="child"
      :rules="rules"
      ref="child"
      label-width="110px"
    >
      <el-form-item label="标识" prop="code">
        <el-input
          :disabled="childData != null ? true : false"
          v-model="child.code"
        ></el-input>
      </el-form-item>
      <el-form-item label="中文名称" prop="name">
        <el-input v-model="child.name"></el-input>
      </el-form-item>
      <el-form-item label="取值类型" prop="type">
        <el-select
          filterable
          clearable
          v-model="child.type"
          class="w-full"
          placeholder="请选择"
        >
          <el-option
            v-for="item in valueType"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="child.type == 4 || child.type == 5"
        label="取值"
        prop="value"
      >
        <el-select class="w-full" v-model="child.value" placeholder="请选择">
          <el-option-group
            v-for="group in dictionaryList"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.id"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item v-if="child.type == 1" label="取值" prop="value">
        <el-select
          class="w-full"
          clearable
          v-model="child.value"
          placeholder="请选择"
        >
          <el-option
            v-for="item in fieldData"
            :key="item.uuid"
            :label="item.name"
            :value="item.uuid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="child.type == 3" label="取值" prop="value">
        <el-input v-model="child.value"></el-input>
      </el-form-item>

      <value-form
        ref="virtualForm"
        :obuuid="obuuid"
        v-if="child.type == 6"
        :formData="child.value"
        :dictionaryList="dictionaryList"
        :fieldList="[]"
        :relationshipList="[]"
      />
    </el-form>
  </div>
</template>

<script>
import object from "@/api/objects";
import valueForm from "@/views/objects/components/valueForm.vue";
import { OptionGroup } from "element-ui";

export default {
  name: "dictsChild",
  components: { valueForm, "el-option-group": OptionGroup },
  props: {
    obuuid: {
      type: String,
      default: "",
    },
    dictUuid: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: null,
    },
    fieldData: {
      type: Array,
      default: () => [],
    },
    dictionaryList: {
      type: Array,
      default: () => [],
    },
    childData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      child: {
        code: "",
        type: 3,
        value: "",
        name: "",
        object_uuid: this.obuuid,
      },
      valueType: object.dictionaryItemType,
      rules: {
        code: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "code的长度在1到30个字符之间",
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z0-9_]+$/,
            message: "code只能是字母和数字以及下划线",
          },
        ],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        value: [{ required: true, message: "请输入", trigger: "blur" }],
        type: [{ required: true, message: "请选择", trigger: "change" }],
      },
    };
  },
  watch: {
    "child.type": {
      handler(val, val1) {
        if (!this.childData || val1 != 3) {
          this.child.value = "";
        }
      },
    },
  },
  methods: {
    commit() {
      console.log(this.$refs.virtualForm, "this.refs.virtualform");
      if (this.$refs.virtualForm) {
        this.$refs.virtualForm.commit();
        this.child.value = this.$refs.virtualForm.extra;
      }

      this.$refs.child.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.child));
          this.$emit("addFn", data);
        } else {
          this.$emit("closeLoading");
        }
      });
    },
  },
  mounted() {
    if (this.childData != null) {
      this.child = JSON.parse(JSON.stringify(this.childData));
    }
  },
};
</script>

<style lang="less">
</style>