<!--
 * @Author: ttheitao
 * @Description: 行为业务配置
 * @Date: 2022-05-27 17:14:56
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-09-26 12:00:54
-->
<template>
    <div class="base-config-wrap">
        <BehaviorsForm
            ref="behaviorForm"
            @addFn="saveBehavior"
            :obuuid="obuuid"
            :formData="formdata"
        ></BehaviorsForm>
        <div class="actions">
            <el-button type="success" @click="saveBehavior()" size="small">
                保存
            </el-button>
        </div>
        <el-divider content-position="left">附加操作（蓝图保存后生效）</el-divider>
        <div style="text-align: right; margin-top: 10px">
            <el-button @click="triggerAll()" type="danger" size="small">
                触发所有数据
            </el-button>

            <el-button @click="triggerOne()" type="warning" size="small">
                触发单条数据
            </el-button>
        </div>
    </div>
</template>

<script>
import dataMixin from "../mixins/dataMixin.js";
import BehaviorsForm from "@/views/objects/components/behaviorsForm.vue";
import objects from "@/api/objects";

export default {
    mixins: [dataMixin],
    components: {
        BehaviorsForm,
    },
    data() {
        return {};
    },
    computed: {
        formdata: function () {
            return {
                name: this.data.name,
                items: {
                    class: this.data.class,
                    payload: this.data.payload,
                },
                enabled: this.data.enabled,
            };
        },
    },
    methods: {
        saveBehavior(data = null) {
            if (data === null) {
                this.$refs.behaviorForm.commit();
            } else {
                this.data.name = data.name;
                this.data.class = data.items.class;
                this.data.payload = data.items.payload;
                this.data.enabled = data.enabled;

                // this.$message.success("操作成功");

                this.onPropChange();
            }
        },
        triggerAll() {
            this.$confirm(
                "确认触发所有数据的该行为吗？请再次检查配置中是否含有动态数据，动态数据可能会导致不可预知的结果",
                "提示"
            )
                .then((res) => {
                    if (res == "confirm") {
                        const loading = this.$loading();
                        objects
                            .behaviorTriggerAll(this.cellProps.id)
                            .then((res) => {
                                loading.close();
                                if (res.data.code == 200) {
                                    this.$message.success("操作成功");
                                }
                            })
                            .catch((res) => {
                                console.log(res);
                                loading.close();
                                this.$message.error("请求失败或未保存当前蓝图");
                            });
                    }
                })
                .catch(() => {});
        },
        triggerOne() {
            this.$prompt("请输入数据id", "提示")
                .then((res) => {
                    if (res.action !== "confirm") return;
                    if (!res.value || parseInt(res.value) <= 0) return;
                    const loading = this.$loading();
                    objects
                        .behaviorTriggerOne(this.cellProps.id, res.value)
                        .then((res) => {
                            loading.close();
                            if (res.data.code == 200) {
                                this.$message.success("操作成功");
                            }
                        })
                        .catch((res) => {
                            console.log(res);
                            loading.close();
                            this.$message.error("请求失败或未保存当前蓝图");
                        });
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="less" scoped>
.actions {
    text-align: right;
}
</style>