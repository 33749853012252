<template>
  <div v-loading="loading" class="w-full h-full">
    <el-row class="h-full">
      <el-col :span="1" v-if="obuuid != 'global'" class="h-full bd-right">
        <leftTab />
      </el-col>
      <el-col class="h-full bd-right p-5" :span="4">
        <leftItem
          delAble
          :defaultRow="defaultRow"
          :topTitle="obname"
          :topSubTitle="$route.query.obcode"
          :tableData="listData"
          field="name"
          @row-del="rowDel"
          @row-click="rowClick"
          @top-add="addDialog = true"
        />
      </el-col>
      <el-col v-if="detailData != null" :span="10" class="h-full p-5">
        <div class="inline-block w-full h-full">
          <p class="type-label-before-blue">基本信息</p>
          <el-form
            :model="detailData"
            :rules="rules"
            ref="detailData"
            label-width="110px"
            class="demo-ruleForm"
          >
            <el-form-item label="字典名称" prop="name">
              <el-input v-model="detailData.name"></el-input>
            </el-form-item>
            <el-form-item label="字典标识" prop="code">
              <el-input disabled v-model="detailData.code"></el-input>
            </el-form-item>
          </el-form>
          <div class="mb-5">
            <el-button type="primary" @click="onSubmit" :loading="btnLoading">{{
              btnLoading ? "提交中 ..." : "保存"
            }}</el-button>
            <el-button @click="addChildDict">新增字典项</el-button>
            <el-button
              :disabled="!canReplicate"
              v-if="$route.query.obuuid !== 'global'"
              type="warning"
              @click="replicateToGlobal"
              >复制到全局字典</el-button
            >

            <el-button
              v-if="$route.query.obuuid == 'global'"
              type="danger"
              @click="migrateGlobalDict(detailData.uuid)"
              >迁移</el-button
            >

            <el-button type="danger" @click="previewDict">预览</el-button>
          </div>
          <p class="type-label" v-if="dictChildData.length != 0">字典项</p>
          <div
            style="height: calc(100% - 365px)"
            class="scroller overflow-y-auto"
            v-if="dictChildData.length != 0"
          >
            <el-table
              :data="dictChildData"
              border
              style="width: 100%"
              height="100%"
            >
              <el-table-column
                type="index"
                label="序号"
                width="55"
              ></el-table-column>
              <el-table-column prop="code" label="标识"></el-table-column>
              <el-table-column prop="name" label="中文名称"></el-table-column>
              <el-table-column prop="value" label="取值">
                <template slot-scope="scope">
                  <span v-if="scope.row.type == 3 && scope.row.value"
                    >(固定值){{ scope.row.value }}</span
                  >
                  <span v-if="scope.row.type == 1">字段</span>
                  <span v-if="scope.row.type == 4">字典</span>
                  <span v-if="scope.row.type == 5">列表</span>
                  <span v-if="scope.row.type == 6">取值类</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <span class="operation-primary" @click="editChild(scope.row)"
                    >编辑</span
                  >
                  <el-popconfirm
                    @confirm="delChild(scope.row)"
                    title="确定删除吗？"
                  >
                    <span class="operation-del" slot="reference">删除</span>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
      <el-col v-if="addChild" :span="9" class="h-full inline-block p-5 bd-left">
        <dict-child-from
          ref="dictChild"
          class="overflow-x-hidden"
          style="height: calc(100% - 36px)"
          :dictionaryList="dictionaryList"
          :fieldData="fieldData"
          @addFn="addChildItem"
          :obuuid="obuuid"
          :childData="childData"
          @closeLoading="closeLoading"
        />
        <el-button
          type="primary"
          @click="
            $refs.dictChild.commit();
            btnLoading = true;
          "
          :loading="btnLoading"
          >{{ btnLoading ? "提交中 ..." : "保存" }}</el-button
        >
        <el-button
          @click="
            addChild = false;
            btnLoading = false;
            childData = null;
          "
          >取消</el-button
        >
      </el-col>
    </el-row>
    <b-modal
      ref="elmodal"
      mode="drawer"
      title="新增字段字典"
      v-if="addDialog"
      :show="addDialog"
      @sure="sure"
      @cancel="addDialog = false"
    >
      <div slot="content">
        <p class="type-label-before-blue">基本信息</p>
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="字典名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="字典标识" prop="code">
            <el-input v-model="form.code"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import object from "@/api/objects";
import leftTab from "@/views/objects/components/leftTab.vue";
import leftItem from "@/components/leftItem.vue";
import dictChildFrom from "@/views/objects/components/dictChildFrom.vue";
import obOpMixin from "@/views/objects/objectOperationMixin";
import objects from "../../../api/objects";
import { Popconfirm } from "element-ui";
export default {
  name: "dictsLists",
  components: {
    leftItem,
    dictChildFrom,
    leftTab,
    "el-popconfirm": Popconfirm,
  },
  mixins: [obOpMixin],
  props: {
    obuuid: {
      type: String,
      default: "",
    },
    obname: {
      type: String,
      default: "全局字段",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      listData: [],
      dictChildData: [], //子项数据
      dictionaryList: [], //全局字典以及普通字典
      defaultRow: "",
      addDialog: false,
      addChild: false,
      form: {
        name: "",
        code: "",
      },
      fieldData: [],
      childData: null, //编辑的子项
      detailData: null, //详情
      loading: false,
      btnLoading: false,
      rules: {
        code: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "code的长度在2到30个字符之间",
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z0-9_]+$/,
            message: "code只能是字母和数字以及下划线",
          },
        ],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      listuuid: "",
    };
  },
  computed: {
    canReplicate() {
      for (let i = 0; i < this.dictChildData.length; i++) {
        let item = this.dictChildData[i];
        if (item.type != 3) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    previewDict() {
      this.loading = true;
      let needID = 0;
      this.dictChildData.forEach((element) => {
        if (element.type != 3) {
          needID = 1;
        }
      });
      if (this.obuuid !== "global" && needID) {
        this.$prompt("请输入数据id进行查看", "提示")
          .then((res) => {
            if (res.action == "confirm") {
              if (!res.value) {
                this.loading = false;
                this.$message.error("请填入一个有效的id值！");
                return;
              }
              objects
                .getDictInfo(this.detailData.uuid, this.obuuid, res.value)
                .then((res) => {
                  this.loading = false;
                  if (res.data.code == 200) {
                    this.$alert(
                      res.data.data,
                      "字典数据：" + this.detailData.name
                    );
                  }
                })
                .catch(() => {
                  this.$message.error("请求数据失败");
                  this.loading = false;
                });
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else if (!needID) {
        let jsons = {};
        this.dictChildData.forEach((element) => {
          jsons[element.code] = element.value;
        });
        this.loading = false;
        this.$alert(jsons, "字典数据：" + this.detailData.name);
        return;
      } else {
        objects
          .getDictInfo(this.detailData.uuid, this.obuuid)
          .then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              this.$alert(res.data.data, "字典数据：" + this.detailData.name);
            }
          })
          .catch(() => {
            this.$message.error("请求数据失败");
            this.loading = false;
          });
      }
    },

    getData(type) {
      //有传值则代表删除 默认显示0
      this.loading = true;
      object.getDicts(this.obuuid).then((res) => {
        if (
          res.data.code == 200 &&
          res.data.data &&
          res.data.data.length != 0
        ) {
          if (this.detailData == null) {
            this.defaultRow = res.data.data[0];
          } else {
            if (type != undefined) {
              if (type == "del") {
                this.defaultRow = res.data.data[0];
              }
              if (type == "change") {
                if (this.$store.state.dataObjects.valueFormDatas != "") {
                  let valueFormDatas = JSON.parse(
                    this.$store.state.dataObjects.valueFormDatas
                  );
                  this.$store.commit(
                    "setValueFormDatas",
                    JSON.stringify({
                      obuuid: this.obuuid,
                      dictionaryList: res.data.data,
                      fieldList: valueFormDatas.fieldList,
                      relationshipList: valueFormDatas.relationshipList,
                    })
                  );
                }
              }
            } else {
              this.defaultRow = this.detailData;
            }
          }
          this.listData = res.data.data;
          if (
            res.data.data &&
            res.data.data.length != 0 &&
            this.dictionaryList.length == 0
          ) {
            //只添加一次
            let obj = { label: "普通字典", options: res.data.data };
            this.dictionaryList.push(obj);
          }
          this.loading = false;
        } else {
          this.listData = [];
          this.detailData = null;
          this.childData = null;
          this.loading = false;
        }
      });
    },
    addChildDict() {
      this.addChild = false;
      this.childData = null;
      this.$nextTick(() => {
        this.addChild = true;
      });
    },
    rowClick(data) {
      //点击左侧
      object.getDictDetail(data.uuid).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data && res.data.data.item) {
            this.dictChildData = res.data.data.item;
          }
        }
      });
      this.detailData = null;
      this.$nextTick(() => {
        this.detailData = data;
        this.listuuid = data.uuid;
      });
    },

    /**
     * @description: 复制本字典到全局字典
     * @param {*}
     * @return {*}
     */
    replicateToGlobal() {
      object.replicateToGlobal(this.detailData.uuid, false).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message.success(res.data.msg);
        }
        if (res.data.code == "confirm") {
          this.$confirm("确认复制吗？")
            .then((res) => {
              if (res == "confirm") {
                object
                  .replicateToGlobal(this.detailData.uuid, true)
                  .then((res) => {
                    if (res.data.code == 200) {
                      this.$message.success(res.data.msg);
                    }
                  });
              }
            })
            .catch(() => {});
        }
      });
    },
    rowDel(data) {
      //删除左侧列表内容
      object.deleteDict(data.uuid).then((res) => {
        if (res.data.code == 200) {
          this.getData("del");
        }
      });
    },
    delChild(data) {
      //删除子项
      object.deleteDictItem(data.uuid).then((res) => {
        if (res.data.code == 200) {
          this.rowClick(this.detailData);
        }
      });
    },
    editChild(data) {
      data = JSON.parse(JSON.stringify(data));
      if (data.type == 6) {
        data.value = JSON.parse(data.value) || "";
      }
      this.addChild = false;
      this.childData = null;
      this.$nextTick(() => {
        this.childData = data;
        this.addChild = true;
      });
    },
    addChildItem(data) {
      //添加字典项
      data.dictionary_uuid = this.detailData.uuid;
      if (data.type == 6) {
        data.value = JSON.stringify(data.value);
      }
      if (this.childData == null) {
        //新增子项
        object
          .addDictItem(data)
          .then((res) => {
            if (res.data.code == 200) {
              this.btnLoading = false;
              this.addChild = false;
              this.rowClick(this.detailData);
            }
          })
          .catch(() => {
            this.btnLoading = false;
          });
      } else {
        //编辑子项
        object
          .editDictItem(data.uuid, data)
          .then((res) => {
            if (res.data.code == 200) {
              this.btnLoading = false;
              this.childData = null;
              this.addChild = false;
              this.rowClick(this.detailData);
            }
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },
    closeLoading() {
      //关闭loading
      this.$nextTick(() => {
        this.btnLoading = false;
      });
      if (this.$refs.elmodal && this.$refs.elmodal.loading) {
        this.$refs.elmodal.loading = false;
      }
    },
    sure() {
      //新增
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.form));
          data.object_uuid = this.obuuid;
          object.addDict(data).then((res) => {
            if (res.data.code == 200) {
              this.$refs.form.resetFields();
              this.addDialog = false;
              this.getData("change");
            } else {
              this.$refs.elmodal.loading = false;
            }
          });
        } else {
          this.$refs.elmodal.loading = false;
        }
      });
    },
    onSubmit() {
      //保存
      this.btnLoading = true;
      let data = {
        code: this.detailData.code,
        name: this.detailData.name,
        object_uuid: this.obuuid,
      };
      object.editDict(this.detailData.uuid, data).then((res) => {
        if (res.data.code == 200) {
          this.btnLoading = false;
          this.getData("change");
        }
      });
    },
    getFieldData() {
      object.getFields(this.obuuid).then((res) => {
        if (
          res.data.code == 200 &&
          res.data.data &&
          res.data.data.data &&
          res.data.data.data.length != 0
        ) {
          this.fieldData = res.data.data.data;
        }
      });
      object.getDicts("global").then((res) => {
        if (
          res.data.code == 200 &&
          res.data.data &&
          res.data.data.length != 0
        ) {
          let obj = { label: "全局字典", options: res.data.data };
          this.dictionaryList.push(obj);
        }
      });
    },
  },
  mounted() {
    this.getFieldData();
    this.getData();
  },
};
</script>

<style lang="less" scoped>
</style>