<template>
  <div v-if="show" class="w-full h-full">
    <el-form
      ref="form"
      class="scroller overflow-y-auto overflow-x-hidden"
      :rules="rules"
      :model="form"
      label-width="110px"
    >
      <p class="type-label-before-blue">基本信息</p>
      <el-form-item label="任务名称" prop="desc">
        <el-input clearable v-model="form.desc"></el-input>
      </el-form-item>
      <!-- <el-form-item label="实体类型" prop="type">
				<el-select  class="w-full" v-model="form.type" placeholder="请选择" >
					<el-option
					v-for="item in entityType"
					:disabled="item.value==1"
					:key="item.value"
					:label="item.name"
					:value="item.value">
					</el-option>
				</el-select>
			</el-form-item> -->
      <!-- <el-form-item label="实体uuid" prop="entity_uuid">
				<el-select  class="w-full" v-model="form.entity_uuid" placeholder="请选择" >
					<el-option
					v-for="item in fieldList"
					:key="item.uuid"
					:label="item.name"
					:value="item.uuid">
					</el-option>
				</el-select>
			</el-form-item> -->
      <el-form-item label="通用验证失败提示" prop="message">
        <el-input clearable v-model="form.message"></el-input>
      </el-form-item>
      <treeForm
        ref="treeForm"
        messageField
        :obuuid="obuuid"
        :dictionaryList="dictionaryList"
        :relationshipList="relationshipList"
        :formData="formData && formData != null ? [formData.rules] : null"
        :fieldList="fieldList"
      >
      </treeForm>
    </el-form>
  </div>
</template>

<script>
import object from "@/api/objects";
import treeForm from "@/views/objects/components/treeForm.vue";
// import { mapGetters } from "vuex";
export default {
  name: "eventForm",
  components: {
    treeForm,
  },
  props: {
    formData: {
      type: Object,
      default: null,
    },
    fieldList: {
      type: Array,
      default: () => [],
    },
    obuuid: {
      type: String,
      default: "",
    },
    dictionaryList: {
      type: Array,
      default: () => [],
    },
    relationshipList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        desc: "",
        type: 2, //固定值未2字段
        message: "",
        entity_uuid: "",
        rules: [], //规则
        object_uuid: this.obuuid,
      },
      rules: {
        desc: [{ required: true, message: "请输入", trigger: "blur" }],
        message: [{ required: true, message: "请输入", trigger: "blur" }],
        type: [{ required: true, message: "请选择", trigger: "change" }],
        entity_uuid: [{ required: true, message: "请选择", trigger: "change" }],
      },
      systemEvents: object.systemEvents, //系统事件
      entityType: object.entityType, //事件类别
      show: false,
    };
  },
  computed: {},
  methods: {
    commit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.form));
          let rules = this.$refs.treeForm.data[0];
          // if (rules.children.length == 0) {
          //   this.$message({
          //     type: "error",
          //     message: "验证逻辑不能为空",
          //   });
          //   this.$emit("closeLoading");
          //   return;
          // }
          data.rules = JSON.parse(JSON.stringify(rules));
          this.$emit("addFn", data);
        } else {
          this.$emit("closeLoading");
        }
      });
    },
  },
  created() {},
  mounted() {
    if (this.formData != null) {
      let data = JSON.parse(JSON.stringify(this.formData));
      this.form.desc = data.desc;
      this.form.type = 2;
      this.form.message = data.message;
      this.form.entity_uuid = data.entity_uuid;
      this.form.rules = JSON.parse(JSON.stringify(data.rules)); //规则
      this.show = true;
    } else {
      this.show = true;
    }
  },
};
</script>

<style lang="less">
</style>