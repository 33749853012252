<!--
 * @Author: ttheitao
 * @Description: 
 * @Date: 2022-07-05 10:17:18
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-07-05 10:17:19
-->
<template>
    <div class="base-config-wrap">
        <div class="x6-config-form">
            <div class="x6-config-item">
                <div class="label">是否启用</div>
                <div class="content">
                    <el-switch v-model="data.enabled" @change="onPropChange" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dataMixin from "../mixins/dataMixin.js";

export default {
    mixins: [dataMixin],
    data() {
        return {};
    },
};
</script>

<style lang="less" scoped>
</style>