<!--
 * @Author: ttheitao
 * @Description: 
 * @Date: 2022-07-01 09:30:48
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-07-05 13:57:28
-->
<template>
    <div class="cg-container">
        <div class="type">
            <i class="iconfont iconjijia_tongji"></i> 条件组（且，全部成立才可）
        </div>
    </div>
</template>

<script>
import BaseOptions from "./mixins/BaseOptions.js";

export default {
    name: "conditionGroup",
    mixins: [BaseOptions],
    data() {
        return {
            hasPanel: false,
        };
    },
};
</script>

<style lang="less" scoped>
.cg-container {
    width: 100%;
    height: 100%;
    border: 2px solid #e6a23c;
    border-radius: 5px;
    font-size: 14px;
    background: #fff;

    .type {
        padding: 5px;
        background: #e6a23c;
        color: #fff;
    }
}
</style>