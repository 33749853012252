<!--
 * @Author: ttheitao
 * @Description: 
 * @Date: 2022-07-01 09:30:48
 * @LastEditors: zx
 * @LastEditTime: 2022-10-11 18:18:23
-->
<template>
    <div class="condition-container" @click="handleValueForm">
        <div class="type"><i class="iconfont iconjijia_zhucai"></i></div>
        <div class="main">
            <div class="item left-value">{{ leftValue }}</div>
            <div class="item operator">{{ operatorName }}</div>
            <div class="item right-value">{{ rightValue }}</div>
        </div>
        <div
            class="value-form scrollbar-style1"
            @mousewheel.stop="mousewheel"
            v-if="showValueForm"
            :style="valueFormStyle"
        >
            <ValueForm
                ref="leftValue"
                title="左侧取值"
                realTime
                :obuuid="obuuid"
                :showClose="true"
                :dictionaryList="dictionaryList"
                :fieldList="fieldList"
                :relationshipList="relationshipList"
                :formData="data.leftValue ? data.leftValue : null"
                @change-data="changeData($event, 'leftValue')"
                @close="showValueForm = false"
            />
            <div style="padding: 5px; margin-top: 15px">
                <div style="font-weight: bold; margin-left: 10px">比较值</div>
                <el-select
                    clearable
                    class="w-full mb-5"
                    placeholder="请选择比较值"
                    :value="data.operator"
                    @input="operatorChange"
                >
                    <el-option
                        v-for="item in filterType"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <ValueForm
                ref="leftValue"
                title="右侧取值"
                realTime
                :obuuid="obuuid"
                :dictionaryList="dictionaryList"
                :fieldList="fieldList"
                :relationshipList="relationshipList"
                :formData="data.rightValue ? data.rightValue : null"
                @change-data="changeData($event, 'rightValue')"
            />
<!-- 
            <div style="padding: 5px; margin-top: 15px">
                <div style="font-weight: bold; margin-left: 10px">是否启用</div>
                <el-switch
                    style="margin-left: 10px; margin-top: 10px"
                    v-model="data.enabled"
                    @change="onDataChange"
                />
            </div> -->
            <!-- <div class="close" @click.stop="showValueForm = false">
                <span class="iconfont iconjijia_qingchu"></span>
            </div> -->
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import object from "@/api/objects";
import ValueForm from "@/views/objects/components/valueForm.vue";
import Store from "@/components/x6Graph/js/Store.js";
import Tool from "../js/Tool";
import BaseOptions from "./mixins/BaseOptions.js";

export default {
    name: "Condition",
    mixins: [BaseOptions],
    components: {
        ValueForm,
    },
    data() {
        return {
            showValueForm: false, // 显示配置面板
            valueFormStyle: { top: 0 }, // 面板样式
            filterType: object.filterType, // 比较类型
            // 节点业务数据
            data: null,
        };
    },
    computed: {
        obuuid: () => Store.obuuid,
        relationshipList: () => Store.relationshipList,
        dictionaryList: () => Store.dictionaryList,
        fieldList: () => Store.fieldList,
        behaviorList: () => Store.behaviorList,
        leftValue: function () {
            let value = "选择左值";
            const leftValue = this.data.leftValue;

            if (leftValue.class) {
                value = "左侧值";
                if (leftValue.class === "ConstValue") {
                    value = leftValue.payload.const_value;
                }
            }

            return value;
        },
        operatorName: function () {
            let value = "比较值";

            if (this.data?.operator) {
                value = this.filterType.filter(
                    (item) => item.value === this.data.operator
                )[0].name;

                value = value.split(" ")[0];
            }

            return value;
        },
        rightValue: function () {
            let value = "选择右值";
            const rightValue = this.data.rightValue;

            if (rightValue.class) {
                value = "右侧值";
                if (rightValue.class === "ConstValue") {
                    value = rightValue.payload.const_value;
                }
            }

            return value;
        },
    },
    created() {
        const node = this.getNode();
        this.data = Tool.deepCopy(node.data);

        node.on("change:size", (args) => {
            this.autoTop();
        });

        this.autoTop();
    },
    methods: {
        // 这个一个固定方法，当节点失去选中时会调用
        closePanel() {
            this.showValueForm = false;
        },
        autoTop() {
            const node = this.getNode();
            this.valueFormStyle.top = node.getProp("size").height + "px";
        },
        handleValueForm() {
            if (!this.isMoving) {
                this.showValueForm = true;
            }
        },
        operatorChange(value) {
            this.data.operator = value;
            this.onDataChange();
        },
        mousewheel(e) {
            // console.log(e);
        },
        changeData(value, type) {
            this.data[type] = value;
            this.onDataChange();
        },
    },
};
</script>

<style lang="less" scoped>
.condition-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 14px;
    background: #fff;
    border-radius: 5px;
    .type {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        box-sizing: border-box;
        background: #e6a23c;
    }

    .main {
        flex: 1;
        display: flex;
        justify-content: space-between;
        border: 2px solid #e6a23c;
        border-left: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        .item {
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #ccc;
            box-sizing: border-box;

            &.left-value {
                flex: 1;
            }

            &.operator {
                width: 60px;
                padding: 0px;
            }

            &.right-value {
                flex: 1;
                border: none;
            }
        }
    }

    .value-form {
        position: absolute;
        z-index: -1;
        // left: 30px;
        // width: calc(100% - 30px);
        // width: 100%;
        width: 500px;
        min-height: 100px;
        max-height: 500px;
        overflow-x: hidden;
        overflow-y: auto;
        background: #fff;
        box-shadow: 0 0 10px #999;
        cursor: default;

        // .close {
        //     position: absolute;
        //     top: -13px;
        //     right: -13px;
        //     z-index: 2000;
        //     span {
        //         font-size: 26px;
        //     }
        //     cursor: pointer;
        // }
    }
}
</style>