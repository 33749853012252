<!--
 * @Author: ttheitao
 * @Description: 开始节点面板
 * @Date: 2022-05-27 14:49:43
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-06-01 15:18:18
-->
<template>
    <div>
        <el-collapse v-model="activeNames">
            <el-collapse-item title="单元配置" name="1">
                <BaseConfig ref="baseConfig" :x6Proxy="x6Proxy"></BaseConfig>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import BaseConfig from "./BaseConfig.vue";
import indexMixin from "../mixins/indexMixin.js";

export default {
    name: "CellStart",
    mixins: [indexMixin],
    components: {
        BaseConfig,
    },
    data() {
        return {
            activeNames: ["1"],
        };
    },
};
</script>

<style>
</style>