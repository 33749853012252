<template>
  <div class="w-full">
    <el-form ref="form" :rules="rules" :model="form" label-width="110px">
      <p class="type-label-before-blue">基本信息</p>
      <el-form-item label="标识" prop="code">
        <el-input
          :disabled="formData != null ? true : false"
          v-model="form.code"
        ></el-input>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input clearable v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-switch v-model="form.is_search"></el-switch>
      </el-form-item>
      <el-form-item label="触发时机" prop="trigger">
        <el-select class="w-full" v-model="form.trigger" clearable placeholder="请选择">
          <el-option
            v-for="item in triggers"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <p class="type-label-before-blue">配置信息</p>
      <el-form-item label="请求地址" prop="url">
        <el-input clearable v-model="form.url"></el-input>
      </el-form-item>
      <el-form-item label="请求方式" prop="method">
        <el-select class="w-full" v-model="form.method"  clearable placeholder="请选择">
          <el-option
            v-for="item in requestMethods"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Header头">
        <el-select class="w-full" v-model="form.header"  clearable placeholder="请选择">
          <el-option-group
            v-for="group in dictsList"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.id"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item label="Query数据">
        <el-select class="w-full" v-model="form.query"  clearable placeholder="请选择">
          <el-option-group
            v-for="group in dictsList"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.id"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item label="Form数据">
        <el-select class="w-full" v-model="form.form"  clearable placeholder="请选择">
          <el-option-group
            v-for="group in dictsList"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.id"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item label="RAW数据">
        <el-select class="w-full" v-model="form.raw"  clearable placeholder="请选择">
          <el-option-group
            v-for="group in dictsList"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.id"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import object from "@/api/objects";
import { mapGetters } from "vuex";
export default {
  name: "apiForm",
  components: {},
  props: {
    formData: {
      type: Object,
      default: null,
    },
    obuuid: {
      type: String,
      default: "",
    },
    dictsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        code: "",
        name: "",
        type: "",
        is_search: false,
        trigger: "",
        url: "",
        method: "",
        header: "",
        query: "",
        raw: "",
        form: "",
        object_uuid: "",
      },
      rules: {
        code: [{ required: true, message: "请输入", trigger: "blur" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        url: [{ required: true, message: "请输入", trigger: "blur" }],
        trigger: [{ required: true, message: "请选择", trigger: "change" }],
        method: [{ required: true, message: "请选择", trigger: "change" }],
      },
      triggers: object.triggers,
      requestMethods: object.requestMethods,
    };
  },
  computed: {
    ...mapGetters({ getValueDictionary: "keyword" }),
  },
  methods: {
    commit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.form));
          this.$emit("addFn", data);
        } else {
          this.$emit("closeLoading");
        }
      });
    },
  },
  mounted() {
    if (this.obuuid) {
      this.form.object_uuid = this.obuuid;
    }
    if (this.formData != null) {
      this.form = JSON.parse(JSON.stringify(this.formData));
    }
  },
};
</script>

<style lang="less">
</style>