<!--
 * @LastEditTime: 2022-08-04 17:04:25
 * @Description: 行为选择器
 * @FilePath: /dwh_front/src/components/metaSelect/BehaviorSelect.vue
 * @Date: 2022-05-10 10:29:26
 * @Author: lisushuang
 * @LastEditors: ttheitao
-->
<!-- eslint-disable -->
<template>
  <div class="meta-behavior-select" v-if="object_uuid" v-loading="loading">
    <div class="behavior-one">
      <div style="text-align: left; margin-bottom: 10px">
        <el-button
          type="success"
          size="small"
          style="margin-right: 10px"
          @click="addOneRow"
          >添加一行</el-button
        >
        <el-button
          type="danger"
          size="small"
          style="margin-right: 5px"
          @click="addBehavior"
          >添加行为并选中</el-button
        >
      </div>
      <el-table
        :data="selectValue"
        ref="dragTable"
        border
        highlight-current-row
        row-key="field_uuid"
        v-show="selectValue.length"
        v-if="triggerShow"
      >
        <el-table-column label="排序" width="60">
          <template slot-scope="scope">
            <i
              style="font-size: 18px; cursor: move"
              class="iconfont icongis_dantihua"
            />
          </template>
        </el-table-column>
        <el-table-column label="行为">
          <template slot-scope="scope">
            <el-select
              class="w-full"
              v-model="selectValue[scope.$index]"
              placeholder="请选择行为"
              size="small"
            >
              <el-option
                v-for="item in behaviorLists"
                :key="item.uuid"
                :label="item.name"
                :value="item.uuid"
                :disabled="selectValue.indexOf(item.uuid) > -1"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-popconfirm
              @confirm="delOrder(scope.$index)"
              title="确定取消绑定吗？"
            >
              <span class="operation-del" slot="reference">取消绑定</span>
            </el-popconfirm>
            <span
              class="operation-primary"
              @click="showBehaviorDetail(scope.$index)"
              style="cursor: pointer"
              >查看详情</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        width="70%"
        top="0"
        title="行为面板"
        :visible="true"
        @close="
          addBehaviorDialog = false;
          behaviorDetail = null;
        "
        v-if="addBehaviorDialog"
        v-loading="dialogLoading"
      >
        <div
          style="
            max-height: 70vh;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-right: 5px;
          "
        >
          <BehaviorsForm
            ref="behaviorForm"
            @addFn="saveBehavior"
            :obuuid="object_uuid"
            :formData="behaviorDetail"
          ></BehaviorsForm>
        </div>
        <div name="footer" style="margin-top: 10px; text-align: center">

          <el-button @click="triggerOne()" v-if="behaviorDetail !== null" type="warning">触发单条数据</el-button>

          <el-button type="success" @click="saveBehavior()">保存</el-button>
          <el-button
            type="danger"
            v-if="behaviorDetail !== null"
            @click="delAndCancelBind"
            >删除并取消绑定</el-button
          >
          <el-button
            type="info"
            @click="
              addBehaviorDialog = false;
              behaviorDetail = null;
            "
            >取消</el-button
          >
          <el-button @click="triggerAll()" v-if="behaviorDetail !== null" type="warning">触发所有数据</el-button>

        </div>
      </el-dialog>
    </div>
  </div>
</template>>
<script>
import objects from "@/api/objects";
import Sortable from "sortablejs";
import BehaviorsForm from "@/views/objects/components/behaviorsForm.vue";
import { Popconfirm } from "element-ui";

export default {
  name: "BehaviorSelect",
  components: { 
    BehaviorsForm,
    'el-popconfirm': Popconfirm,
  },
  props: {
    object_uuid: {
      type: String,
      default: "",
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      behaviorLists: [], // 行为列表
      loading: true, // 加载中
      selectValue: [], // 选中的行为
      sortable: null, // 拖拽

      // 添加行为以及查看行为详情的弹窗
      addBehaviorDialog: false,
      // 行为的详情数据
      behaviorDetail: null,
      dialogLoading: false,

      triggerShow:true
    };
  },
  watch: {
    selectValue(val) {
      if (this.behaviorLists.length) {
        this.$emit("input", val);
      }
    },
    value(val) {
      if(!this.selectValue || !this.selectValue.length){
        this.selectValue = val;
      }
    },
  },
  methods: {
    /**
     * @description: 设置行为多选的拖拽排序
     */
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          let value = JSON.parse(JSON.stringify(this.selectValue))
          const targetRow = value.splice(evt.oldIndex, 1)[0];
          value.splice(evt.newIndex, 0, targetRow);
          this.triggerShow = false
          this.sortable.destroy()
          this.$nextTick(() => {
            this.selectValue = value
            this.triggerShow = true
            this.$nextTick(() => {
              this.setSort()
            })
          })
        },
      });
    },

    /**
     * @description: 删除某一行行为绑定
     * @param {Integer} index 行为索引
     */
    delOrder(index) {
      this.selectValue.splice(index, 1);
    },

    /**
     * @description: 添加一行行为绑定
     */
    addOneRow() {
      if (this.selectValue.length >= this.behaviorLists.length) {
        this.$message.error("不得超出总的行为数");
        return;
      }
      // 如果selectValue中已经有了请选择行为
      if (this.selectValue.indexOf("请选择行为") > -1) {
        this.$message.error("已经存在了一个未选择的行为！");
        return ;
      }
      this.selectValue.push("请选择行为");
      console.log(this.selectValue);
    },

    /**
     * @description: 新增行为，打开弹窗，详情置空
     */
    addBehavior() {
      this.addBehaviorDialog = true;
      this.behaviorDetail = null;
    },

    /**
     * @description: 保存行为类配置
     * @param {Object} data 行为类配置
     */
    saveBehavior(data = null) {
      if (data === null) {
        this.$refs.behaviorForm.commit();
      } else {
        console.log(data, "saveBehavior");
        if (this.behaviorDetail === null) {
          // 新增
          this.dialogLoading = true;
          objects
            .addBehavior(this.object_uuid, data)
            .then((res) => {
              this.dialogLoading = false;
              if (res.data.code == 200) {
                console.log(res.data.data, "res.data.data");
                this.selectValue.push(res.data.data.uuid);
                this.getBehaviorList();
              }
            })
            .catch(() => (this.dialogLoading = false));
        } else {
          // 编辑
          this.dialogLoading = true;
          data.object_uuid = this.object_uuid;
          objects
            .editBehavior(this.behaviorDetail.uuid, data)
            .then((res) => {
              this.dialogLoading = false;
              if (res.data.code == 200) {
                this.getBehaviorList();
              }
            })
            .catch(() => (this.dialogLoading = false));
        }
      }
    },

    /**
     * @description: 展示行为的详情
     * @param {Integer} index 行为索引
     */
    showBehaviorDetail(index) {
      const uuid = this.selectValue[index];
      if (!uuid || uuid === "请选择行为") this.$message.error("无效的行为索引");
      const behavior = this.behaviorLists.find((item) => item.uuid === uuid);
      if (behavior) {
        this.behaviorDetail = behavior;
        this.addBehaviorDialog = true;
      }
    },

    /**
     * @description:  删除并取消绑定
     */
    delAndCancelBind() {
      this.$confirm("确定要删除并取消绑定吗?", "提示")
        .then((res) => {
          if (res === "confirm") {
            if (this.behaviorDetail === null) {
              return;
            }
            this.dialogLoading = true;
            objects
              .deleteBehavior(this.behaviorDetail.uuid)
              .then((res) => {
                this.dialogLoading = false;
                if (res.data.code == 200) {
                  this.behaviorDetail = null;
                  this.addBehaviorDialog = false;
                  this.getBehaviorList();
                }
              })
              .catch(() => (this.dialogLoading = false));
          }
        })
        .catch(() => {});
    },

    /**
     * @description: 触发单条数据
     */
    triggerOne(){
      this.$prompt(
          "请输入数据id",
          "提示"
      ).then((res) => {
        if(res.action !== 'confirm') return ;
        if(!res.value || parseInt(res.value) <= 0) return;
        this.loading = true;
        objects.behaviorTriggerOne(this.behaviorDetail.uuid,res.value).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.$message.success('操作成功');
          }
        }).catch((res) => {
          console.log(res)
          this.loading = false;
          this.$message.error('请求失败或超时')
        });
      }).catch(()=> {});
    },

    /**
     * @description: 触发所有数据
     */    
    triggerAll() {
      this.$confirm(
          "确认触发所有数据的该行为吗？请再次检查配置中是否含有动态数据，动态数据可能会导致不可预知的结果",
          "提示"
      ).then((res) => {
        if (res == "confirm") {
          this.loading = true;
          objects.behaviorTriggerAll(this.behaviorDetail.uuid).then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              this.$message.success('操作成功');
            }
          }).catch((res) => {
            console.log(res)
            this.loading = false;
            this.$message.error('请求失败或超时')
          });
        }
      }).catch(() => {});
    },

    /**
     * @description: 获取本对象的所有行为数据
     */
    getBehaviorList() {
      this.addBehaviorDialog = false;
      this.behaviorDetail = null;
      this.loading = true;
      objects
        .getBehaviorList(this.object_uuid)
        .then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.behaviorLists = res.data.data.data;
            if (!this.selectValue.length) {
              this.selectValue.push("请选择行为");
            } else {
              // 判断selectValue中的uuid在behaviorLists中是否存在，不存在则删除
              let newValue = [];
              for (let i = 0; i < this.selectValue.length; i++) {
                const item = this.selectValue[i];
                if (
                  this.behaviorLists.find((item2) => item2.uuid === item) ||
                  item == "请选择行为"
                ) {
                  newValue.push(item);
                }
              }
              this.selectValue = newValue;
            }
            if (this.sortable === null) {
              this.setSort();
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
  },
  mounted() {
    console.log(this.value, "asdfasdfasdf");
    if (!this.object_uuid) {
      this.$message.error("无可用对象");
    } else {
      this.selectValue = this.value;
      this.getBehaviorList();
    }
  },
};
</script>
<style scoped>
</style>