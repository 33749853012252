<!--
 * @LastEditTime: 2022-08-05 16:21:49
 * @Description: 对象管理左侧操作按钮
 * @FilePath: /dataview-next/src/views/objects/components/leftTab.vue
 * @Date: 2022-01-19 17:11:03
 * @Author: lisushuang
 * @LastEditors: luocheng
-->
<template>
  <ul class="w-full h-full">
    <li  v-if="$route.query.is_virtual == 1" >
      <el-alert title="表单对象" type="error" effect="dark" :closable="false"> </el-alert>
    </li>
    <li
      class="tab-item"
      @click="clickItem(item)"
      :class="type == item.type ? 'active-tab' : ''"
      v-for="(item, index) in listData"
      v-show="$route.query.enabled != 2 || !item.noAggregate"
      :key="index"
    >
      <i :class="item.icon" class="iconfont"></i>
      <p>{{ item.name }}</p>
    </li>


  </ul>
</template>

<script>
import obOpMixin from "@/views/objects/objectOperationMixin";

export default {
  name: "leftTab",
  components: {},
  mixins: [obOpMixin],
  props: {},
  data() {
    return {
      listData: [
        { name: "字段集", icon: "iconbimgis_shuxing", type: "field" ,noAggregate:false},
        { name: "关联关系", icon: "iconfujiguanlian", type: "relation" ,noAggregate:false},
        { name: "视图集", icon: "iconbangongyongpin", type: "view" ,noAggregate:false},
        { name: "验证集", icon: "iconhetongshencha", type: "validate" ,noAggregate:true},
        { name: "行为集", icon: "icongis_baocun", type: "behavior" ,noAggregate:false },
        { name: "事件集", icon: "iconjijia_konghang", type: "event"  ,noAggregate:false},
        { name: "事件蓝图", icon: "iconjijia_konghang", type: "eventGraph"  ,noAggregate:false},
        { name: "字段字典", icon: "iconjijia_dinge", type: "dict" ,noAggregate:false},
        { name: "API集成", icon: "iconbimgis_jiemianfenxi", type: "api" ,noAggregate:false },
        { name: "表单", icon: "iconbim_gongchengjisuan", type: "form" ,noAggregate:false},
        { name: "迁移对象", icon: "iconsanfangjiance", type: "migrate",noAggregate:false },
        { name: "同步数据", icon: "iconsanfangjiance", type: "syncData",noAggregate:false },
        { name: "查看引用", icon: "iconlvyuepingjia", type: "showQoutes",noAggregate:false },
        { name: "返回", icon: "iconchexiao1", type: "back" ,noAggregate:false},
      ],
      type: "",
      obuuid: "",
      obname: "",
    };
  },
  computed: {},
  methods: {
    clickItem(data) {
      if(data.type == 'aggregateConfig'){
        this.$globalAction('aggregateObjectConfig',{object_uuid:this.obuuid})
      }else if(data.type == 'showQoutes'){
        this.$globalAction('showMetaQoutes',{uuid:this.obuuid,name:this.obname})
      }else if (data.type == "migrate") {
        this.$globalAction('MetaMigrate', {type: 'object', uuid: this.obuuid})
      }else if (data.type == "syncData") {
        this.$globalAction('MetaMigrate', {type: 'object_data', uuid: this.obuuid,search:[]})
      } else if (data.type == "form") {
        this.$router.replace({
          path: "/dwh/formList",
          query: {
            obuuid: this.obuuid,
            obname: this.obname,
            type: data.type,
            obcode: this.$route.query.obcode,
            enabled:this.$route.query.enabled,
          },
        });
      } else if (data.type == "back") {
        this.$router.go(-1);
      } else {
        this.$router.replace({
          path: "/dwh/objects/detail",
          query: {
            obuuid: this.obuuid,
            obname: this.obname,
            type: data.type,
            is_virtual:this.obuuid.indexOf('formModule') > -1 ? 1 : 0,
            enabled:this.$route.query.enabled,
            obcode: this.$route.query.obcode,
          },
        });
      }
    },
  },
  mounted() {
    this.type = this.$route.query.type;
    this.obuuid = this.$route.query.obuuid;
    this.obname = this.$route.query.obname;

    if(this.$route.query.enabled == 2){
      this.listData.unshift({ name: "聚合配置", icon: "icongis_baocun", type: "aggregateConfig",noAggregate:false },)
    }
  },
};
</script>
<style lang="less" scoped>
.tab-item {
  text-align: center;
  padding: 5px 0;
  cursor: pointer;
  i {
    font-size: 25px;
  }
  p {
    font-size: 12px;
    padding-top: 10px;
  }
}
.active-tab {
  color: #007FE0;
}
</style>
