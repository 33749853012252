<!--
 * @Author: ttheitao
 * @Description: 开始节点配置
 * @Date: 2022-05-27 17:14:56
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-07-04 11:55:37
-->
<template>
    <div class="base-config-wrap">
        <div class="x6-config-form">
            <div class="x6-config-item">
                <div class="label">单元类型</div>
                <div class="content">开始</div>
            </div>
            <div class="x6-config-item">
                <div class="label">单元名称</div>
                <div class="content">
                    <el-input
                        v-model="cellProp.attrs.text.text"
                        @input="onPropChange"
                        size="small"
                    />
                </div>
            </div>
            <div class="x6-config-item">
                <div class="label">单元宽度</div>
                <div class="content">
                    <el-input
                        v-model="cellProp.size.width"
                        @input="onPropChange"
                        size="small"
                    />
                </div>
            </div>
            <div class="x6-config-item">
                <div class="label">单元高度</div>
                <div class="content">
                    <el-input
                        v-model="cellProp.size.height"
                        @input="onPropChange"
                        size="small"
                    />
                </div>
            </div>
            <div class="x6-config-item">
                <div class="label">层级</div>
                <div class="content">
                    <el-input-number
                        v-model="cellProp.zIndex"
                        @change="onPropChange"
                        label="层级"
                        size="small"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import baseMixin from "../mixins/baseMixin.js";

export default {
    mixins: [baseMixin],
};
</script>

<style lang="less" scoped>
</style>