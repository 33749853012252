<!--
 * @Author: ttheitao
 * @Description: 条件节点面板
 * @Date: 2022-05-27 14:49:43
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-07-05 10:17:14
-->
<template>
    <div>
        <el-collapse v-model="activeNames">
            <el-collapse-item title="单元配置" name="1">
                <BaseConfig ref="baseConfig" :x6Proxy="x6Proxy"></BaseConfig>
            </el-collapse-item>
            <el-collapse-item title="单元配置" name="2">
                <DataConfig ref="DataConfig" :x6Proxy="x6Proxy"></DataConfig>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import BaseConfig from "./BaseConfig.vue";
import DataConfig from "./DataConfig.vue";
import indexMixin from "../mixins/indexMixin.js";

export default {
    mixins: [indexMixin],
    components: {
        BaseConfig,
        DataConfig,
    },
    data() {
        return {
            activeNames: ["1", "2"],
        };
    },
};
</script>

<style>
</style>