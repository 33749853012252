import Tool from "../../js/Tool";

/*
 * @Author: ttheitao
 * @Description: 
 * @Date: 2022-07-05 09:28:49
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-07-05 09:53:27
 */
export default {
    inject: ["getGraph", "getNode"],
    data() {
        return {
            hasPanel: true, // 当前组件是否包含配置面板，如果为 true ,则 method 中还需要对应的 closePanel 方法
            isMoving: false, // 当前节点是否在移动状态
        }
    },
    methods: {
        onDataChange() {
            const node = this.getNode();
            node.setData(Tool.deepCopy(this.data));
        }
    }
}