<!--
 * @Author: ttheitao
 * @Description: some description
 * @Date: 2022-08-03 15:43:09
 * @LastEditors: zx
 * @LastEditTime: 2022-09-23 17:59:20
-->
<template>
  <el-container style="height: 100%; background: #fff;">
    <component
      :is="type + 'sList'"
      :obuuid="obuuid"
      :obname="obname"
      v-if="type"
    ></component>
  </el-container>
</template>

<script>
import fieldsList from "./components/fieldsList.vue";
import apisList from "./components/apisList.vue";
import relationsList from "./components/relationsList.vue";
import dictsList from "./components/dictsList.vue";
import validatesList from "./components/validatesList.vue";
import viewsList from "@/views/objects/components/saasViewList.vue";
import eventsList from "./components/eventsList.vue";
import behaviorsList from "./components/behaviorsList.vue";
import eventGraphsList from "./components/eventGraphsList.vue";
import { Container } from "element-ui";

export default {
  name: "detail",
  components: {
    fieldsList,
    apisList,
    relationsList,
    dictsList,
    viewsList,
    validatesList,
    eventsList,
    behaviorsList,
    eventGraphsList,
    'el-container': Container,
  },
  data() {
    return {
      type: "",
      obuuid: "",
      obname: "",
      status: "",
    };
  },
  watch: {
    "$route.query.type": {
      handler(val) {
        this.type = "";
        // setTimeout(() => {
        this.type = val;
        // }, 500)
      },
      deep: true,
    },
    "$route.query.obuuid": {
      handler(val) {
        this.type = "";
        this.obuuid = val;
        // setTimeout(() => {
        this.type = this.$route.query.type;
        // }, 500)
      },
      deep: true,
    },
    $route: {
      handler(to, from) {
        //监听路由
        if (to && from && from.name == to.name) {
          this.type = "";
          this.$nextTick(() => {
            this.type = this.$route.query.type;
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.type = this.$route.query.type;
    this.obuuid = this.$route.query.obuuid;
    this.obname = this.$route.query.obname;
  },
};
</script>

<style lang="less" scoped>
</style>