<template>
    <div v-loading="loading" class="w-full h-full" style="display: flex;flex-direction: column;">
        <div class="top-tool">
            <leftTab />
        </div>
        <el-row class="h-full">
            <el-col :span="24" class="h-full p-5">
                <x6-graph
                    v-if="showX6Graph"
                    :obuuid="obuuid"
                    :blueprint_uuid="blueprint_uuid"
                    :behaviorList="behaviorList"
                    :fieldList="fieldList"
                    :dictionaryList="dictionaryList"
                    :relationshipList="relationshipList"
                    @submit="submit"
                >
                </x6-graph>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import object from "@/api/objects";
import leftTab from "@/views/objects/components/saasTab.vue";
import x6Graph from "@/components/x6Graph/index.vue";

export default {
    name: "eventGraphsList",
    components: {
        leftTab,
        x6Graph,
    },
    props: {
        obuuid: {
            type: String,
            default: "global",
        },
        obname: {
            type: String,
            default: "全局字段",
        },
        type: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            loading: false,
            relationshipList: [],
            dictionaryList: [],
            fieldList: [],
            behaviorList: [],
            blueprint_uuid: "",
            showX6Graph: false,
        };
    },
    computed: {},
    created() {
        this.getBlueprint();
    },
    mounted() {
        if (this.$store.state.dataObjects.valueFormDatas == "") {
            this.getSelectList();
        } else {
            let valueFormDatas = JSON.parse(
                this.$store.state.dataObjects.valueFormDatas
            );
            if (valueFormDatas.obuuid && valueFormDatas.obuuid != this.obuuid) {
                this.getSelectList();
            } else {
                this.dictionaryList = valueFormDatas.dictionaryList;
                this.fieldList = valueFormDatas.fieldList;
                this.relationshipList = valueFormDatas.relationshipList;
            }
        }

        object.getBehaviorList(this.obuuid).then((res) => {
            if (
                res.data.code == 200 &&
                res.data.data &&
                res.data.data.data &&
                res.data.data.data.length != 0
            ) {
                this.behaviorList = res.data.data.data;
            }
        });
    },
    methods: {
        getSelectList() {
            if (this.dictionaryList.length == 0) {
                object.getDicts(this.obuuid).then((res) => {
                    if (res.data.code == 200) {
                        if (res.data.data && res.data.data.length != 0) {
                            let obj = {
                                label: "普通字典",
                                options: res.data.data,
                            };
                            this.dictionaryList.push(obj);
                        }
                        object.getDicts("global").then((res1) => {
                            if (
                                res1.data.code == 200 &&
                                res1.data.data &&
                                res1.data.data.length != 0
                            ) {
                                let obj1 = {
                                    label: "全局字典",
                                    options: res1.data.data,
                                };
                                this.dictionaryList.unshift(obj1);
                            }
                            this.$store.commit(
                                "setValueFormDatas",
                                JSON.stringify({
                                    obuuid: this.obuuid,
                                    dictionaryList: this.dictionaryList,
                                    fieldList: this.fieldList,
                                    relationshipList: this.relationshipList,
                                })
                            );
                        });
                    }
                });
            }
            if (this.fieldList.length == 0) {
                object.getFields(this.obuuid).then((res) => {
                    if (
                        res.data.code == 200 &&
                        res.data.data &&
                        res.data.data.data &&
                        res.data.data.data.length != 0
                    ) {
                        this.fieldList = res.data.data.data;
                        this.$store.commit(
                            "setValueFormDatas",
                            JSON.stringify({
                                obuuid: this.obuuid,
                                dictionaryList: this.dictionaryList,
                                fieldList: this.fieldList,
                                relationshipList: this.relationshipList,
                            })
                        );
                    }
                });
            }
            if (this.relationshipList.length == 0) {
                object.getMyRelations(this.obuuid).then((res) => {
                    if (
                        res.data.code == 200 &&
                        res.data.data &&
                        res.data.data.data &&
                        res.data.data.data.length != 0
                    ) {
                        this.relationshipList = res.data.data.data;
                        this.$store.commit(
                            "setValueFormDatas",
                            JSON.stringify({
                                obuuid: this.obuuid,
                                dictionaryList: this.dictionaryList,
                                fieldList: this.fieldList,
                                relationshipList: this.relationshipList,
                            })
                        );
                    }
                });
            }
        },
        getBlueprint() {
            this.loading = true;
            object
                .getBlueprintBindingsList(this.obuuid, undefined, 10000)
                .then((res) => {
                    this.loading = false;
                    if (res.data.code === 200) {
                        const data = res.data.data.data;
                        if (data.length) {
                            this.blueprint_uuid = data[0].blueprint_uuid;
                        }
                        this.showX6Graph = true;
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.$message.error("请求服务器失败");
                });
        },
        submit() {
            // some
        },
    },
};
</script>

<style lang="less" scoped>
    .h-full {
      .top-tool {
        height: 60px;
      }
    }
</style>