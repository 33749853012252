/*
 * @Author: ttheitao
 * @Description: 桩生成器
 * @Date: 2022-05-26 11:25:21
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-06-17 18:34:44
 */
export default class ProtGenerater {
    groups = {};
    items = [];
    constructor() { }

    /**
     * 新增节点
     * @param {String} position  top | right | bottom | left
     * @param {Number | String} index 
     */
    add(position = 'top', index = 1) {
        const groupName = `${position}_${index}`;
        this.groups[groupName] = {
            position: position,
            attrs: this._getAttr()
        }

        const check = this.items.filter((item) => {
            item.group === groupName;
        });

        if (check.length < 1) {
            this.items.push({
                group: groupName
            });
        }

        return this;
    }

    addTop(index = 1) {
        this.add('top', index);
        return this;
    }

    addRight(index = 1) {
        this.add('right', index);
        return this;
    }

    addLeft(index = 1) {
        this.add('left', index);
        return this;
    }

    addBottom(index = 1) {
        this.add('bottom', index);
        return this;
    }

    get() {
        return {
            groups: this.groups,
            items: this.items,
        };
    }

    _getAttr(type = 'circle', r = 6, magnet = true, stroke = '#5F95FF', strokeWidth = 1, fill = '#fff', style = { visibility: 'hidden' }) {
        return {
            [type]: {
                r: r,
                magnet: magnet,
                stroke: stroke,
                strokeWidth: strokeWidth,
                fill: fill,
                style: style
            }
        }
    }
}