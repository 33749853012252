<template>
  <div v-loading="loading" class="w-full h-full">
    <el-row class="h-full">
      <el-col :span="1" class="h-full bd-right">
        <leftTab />
      </el-col>
      <el-col class="h-full bd-right p-5" :span="4">
        <leftItem
          delAble
          :defaultRow="defaultRow"
          :topTitle="obname"
          :topSubTitle="$route.query.obcode"
          :tableData="listData"
          field="name"
          @row-del="rowDel"
          @row-click="rowClick"
          @top-add="addDialog = true"
        />
      </el-col>
      <el-col v-if="detailData != null" :span="10" class="h-full p-5">
        <api-form
          class="inline-block overflow-y-auto scroller"
          style="height: calc(100% - 44px)"
          :formData="detailData"
          ref="apiDetail"
          :dictsList="dictsList"
          :obuuid="obuuid"
          @addFn="addFn"
          @closeLoading="closeLoading"
        />
        <el-button @click="testModalShow = true">测试</el-button>
        <el-button @click="goAll()" type="danger">测试所有</el-button>
        <el-button
          @click="
            logShow = !logShow;
            openLogs();
          "
          >查看日志</el-button
        >
        <el-button @click="onSubmit" type="primary" :loading="btnLoading">{{
          btnLoading ? "提交中 ..." : "保存"
        }}</el-button>
      </el-col>
      <el-col v-if="logShow" :span="9" class="h-full bd-left p-5">
        <p class="type-label-before-blue">日志列表</p>
        <div style="height: calc(100% - 67px)" class="overflow-x-auto scroller">
          <el-table
            :data="logData"
            v-if="logData.length != 0"
            border
            style="width: 100%"
          >
            <el-table-column prop="id" label="id" width="60"></el-table-column>
            <el-table-column label="返回码" width="80">
              <template slot-scope="scope">
                {{ scope.row.response.code }}
              </template>
            </el-table-column>
            <el-table-column label="请求结果">
              <template slot-scope="scope">
                {{ scope.row.response.message }}
              </template>
            </el-table-column>
            <el-table-column
              prop="updated_at"
              label="日期"
              width="180"
            ></el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-button
                  @click="$alert(JSON.stringify(scope.row.response))"
                  type="warning"
                  size="small"
                  >结果</el-button
                >
                <el-button
                  @click="$alert(JSON.stringify(scope.row.data))"
                  type="danger"
                  size="small"
                  >数据</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-empty
            slot="empty"
            :image-size="90"
            v-if="logData.length == 0"
            description="暂无数据"
          ></el-empty>
        </div>
        <el-pagination
          style="float: right; margin-top: 8px"
          v-if="total !== null"
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="size"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
        <!-- <el-button type="primary" @click="logShow=false;logData.length=[]"  >取消</el-button> -->
      </el-col>
    </el-row>
    <b-modal
      ref="elmodal"
      mode="drawer"
      title="新增api"
      v-if="addDialog"
      :show="addDialog"
      @sure="$refs.apiform.commit()"
      @cancel="addDialog = false"
    >
      <api-form
        slot="content"
        ref="apiform"
        :dictsList="dictsList"
        :obuuid="obuuid"
        @addFn="addFn"
        @closeLoading="closeLoading"
      />
    </b-modal>
    <el-dialog title="测试" :visible.sync="testModalShow" width="25%">
      <el-input
        clearable
        placeholder="请输入数据id"
        v-model="testModalValue"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="testModalShow = false">取 消</el-button>
        <el-button type="primary" @click="getTestApi">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import object from "@/api/objects";
import leftTab from "@/views/objects/components/leftTab.vue";
import leftItem from "@/components/leftItem.vue";
import apiForm from "@/views/objects/components/apiForm.vue";
export default {
  name: "apiList",
  components: {
    leftItem,
    apiForm,
    leftTab,
  },
  props: {
    obuuid: {
      type: String,
      default: "global",
    },
    obname: {
      type: String,
      default: "全局字段",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      listData: [],
      defaultRow: "",
      addDialog: false,
      fieldTypes: object.fieldTypes,
      dictsList: [], //字典数据
      detailData: null, //详情
      loading: false,
      btnLoading: false,
      testModalShow: false, //测试弹框
      testModalValue: "",
      logData: [], //日志数据
      logShow: false,

      size: 14,

      total: null,
      currentPage: 1,
    };
  },
  computed: {},
  methods: {
    getData(type) {
      this.loading = true;
      object.getApis(this.obuuid).then((res) => {
        if (
          res.data.code == 200 &&
          res.data.data &&
          res.data.data.length != 0
        ) {
          if (this.detailData == null) {
            this.defaultRow = res.data.data[0];
          } else {
            if (type != undefined) {
              if (type == "del") {
                this.defaultRow = res.data.data[0];
              }
            } else {
              this.defaultRow = this.detailData;
            }
          }
          this.logShow = false;
          this.listData = res.data.data;
          this.loading = false;
        } else {
          this.logShow = false;
          this.listData = [];
          this.detailData = null;
          this.loading = false;
        }
      });
    },
    handleCurrentChange(page) {
      this.openLogs(page);
    },
    rowClick(data) {
      //点击左侧
      this.detailData = null;
      this.logShow = false;
      this.openLogs();
      this.$nextTick(() => {
        this.detailData = data;
      });
    },
    rowDel(data) {
      //删除左侧列表内容
      object.deleteApi(data.uuid).then((res) => {
        if (res.data.code == 200) {
          this.logShow = false;
          this.openLogs();
          this.getData("del");
        }
      });
    },
    closeLoading() {
      //关闭弹框里按钮loading
      this.$nextTick(() => {
        this.btnLoading = false;
      });
      if (this.$refs.elmodal && this.$refs.elmodal.loading) {
        this.$refs.elmodal.loading = false;
      }
    },
    addFn(data) {
      object.addApi(data).then((res) => {
        if (res.data.code == 200) {
          this.addDialog = false;
          this.getData();
        } else {
          this.closeLoading();
        }
      });
    },
    openLogs(page = 1) {
      //查看日志
      if (!this.logShow) {
        this.logData = [];
        return;
      }
      this.loading = true;
      object.apiLogs(this.detailData.uuid, page).then((res) => {
        this.loading = false;
        if (res.data.code == 200) {
          if (res.data.data.data) {
            this.total = res.data.data.total;
            this.logData = res.data.data.data;
          } else {
            this.total = null;
            this.logData = res.data.data;
          }
        }
        this.logShow = true;
      });
    },
    getTestApi() {
      //点击测试
      let trigger = this.$refs.apiDetail.form.trigger;
      object.testApi(this.obuuid, this.testModalValue, trigger).then((res) => {
        if (res.data.code == 200) {
          this.testModalValue = "";
          this.testModalShow = false;
        }
      });
    },
    goAll() {
      //请求所有
      this.$confirm("确认测试所有数据吗？").then((res) => {
        if (res == "confirm") {
          this.loading = true;
          let trigger = this.$refs.apiDetail.form.trigger;
          object
            .testApi(this.obuuid, "all", trigger)
            .then((res) => {
              this.loading = false;
              if (res.data.code == 200) {
                this.testModalValue = "";
                this.testModalShow = false;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    onSubmit() {
      //保存
      this.btnLoading = true;
      let data = this.$refs.apiDetail.form;
      object.editApi(this.detailData.uuid, data).then((res) => {
        if (res.data.code == 200) {
          this.getData();
        }
        this.btnLoading = false;
      });
    },
  },
  mounted() {
    this.getData();
    object.getDicts(this.obuuid).then((res) => {
      if (res.data.code == 200) {
        if (res.data.data && res.data.data.length != 0) {
          let obj = { label: "普通字典", options: res.data.data };
          this.dictsList.push(obj);
        }
        object.getDicts("global").then((res1) => {
          if (
            res1.data.code == 200 &&
            res1.data.data &&
            res1.data.data.length != 0
          ) {
            let obj1 = { label: "全局字典", options: res1.data.data };
            this.dictsList.unshift(obj1);
          }
        });
      }
    });
  },
};
</script>

<style lang="less" scoped>
</style>