import { render, staticRenderFns } from "./BaseConfig.vue?vue&type=template&id=149a500e&scoped=true&"
import script from "./BaseConfig.vue?vue&type=script&lang=js&"
export * from "./BaseConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "149a500e",
  null
  
)

export default component.exports