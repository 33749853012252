/*
 * @Author: ttheitao
 * @Description: 
 * @Date: 2022-05-30 14:59:00
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-04 16:55:25
 */
import Tool from "../../js/Tool";
import object from "@/api/objects";
import { Divider, InputNumber } from "element-ui";

export default {
  props: {
    x6Proxy: {},
    formData: {
      type: Object,
      default: null,
    },
    behaviorList: {
      type: Array,
      default: () => [],
    },
    fieldList: {
      type: Array,
      default: () => [],
    },
    dictionaryList: {
      type: Array,
      default: () => [],
    },
    relationshipList: {
      type: Array,
      default: () => [],
    },
    obuuid: {
      type: String,
      default: "",
    },
  },
  components: {
    'el-divider': Divider,
    'el-input-number': InputNumber,
  },
  data() {
    return {
      cellProps: null,
      data: null,
    };
  },
  created() {
    this.data = Tool.deepCopy(this.x6Proxy.curCell.getProp().data);
    this.cellProps = Tool.deepCopy(this.x6Proxy.curCell.getProp());
  },
  methods: {
    onPropChange() {
      if (!this.x6Proxy.curCell) {
        return;
      }

      if (['Event', 'Behavior'].includes(this.x6Proxy.curCell.shape)) {
        // 自动添加名称到节点
        if (this.x6Proxy.curCell.shape === 'Event') {
          if (this.data.name === '事件' || this.x6Proxy.curCell.getProp('data').name === this.data.name) {
            this.data.name = this.data.event;
            if (this.data.type === 1) {
              this.data.name = object.systemEvents.filter(item => { return item.value === this.data.event })[0].name;
            }
          }
        }
        this.x6Proxy.curCell.label = this.data.name;
      }

      if (['RuleEdge'].includes(this.x6Proxy.curCell.shape)) {

        let stroke = Tool.ruleEdgeStoke.pass;
        if (this.data.type !== 'pass') {
          stroke = Tool.ruleEdgeStoke.fail;
        }

        this.x6Proxy.curCell.setAttrs({
          line: {
            stroke: stroke,
          }
        });
      }

      this.x6Proxy.curCell.setProp('data', Tool.deepCopy(this.data));

      this.$emit('refresh');
    },
  },
}