/*
 * @Author: ttheitao
 * @Description: 
 * @Date: 2022-07-01 14:53:27
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-07-01 14:53:27
 */

export default {
    obuuid: "",
    relationshipList: [],
    dictionaryList: [],
    fieldList: [],
    behaviorList: [],
}