<template>
  <div v-if="show" class="w-full">
    <el-form ref="form" :rules="rules" :model="form" label-width="110px">
      <p class="type-label-before-blue">
        基本信息
        <span v-html="owner ? '(主关联)' : '(副关联)'"> </span>
        <span
          v-html="simpleMode ? '简单模式' : '专业模式'"
          style="margin-left: 10px; color: #e6a23c"
        >
        </span>

        <!-- <el-switch v-model="simpleMode" style="margin-left: 10px">
          简单模式
        </el-switch> -->

        <span
          v-html="hideBaseInfo ? '展开' : '收起'"
          style="color: red; margin-left: 10px; cursor: pointer"
          @click="hideBaseInfo = !hideBaseInfo"
        ></span>
      </p>
      <!-- 简略信息 -->
      <div v-show="hideBaseInfo" style="text-align: left">
        <p>【{{ getRelationTypeName }}】 {{ form.name }}</p>
        <p>{{ form.code }} - {{ formData ? formData.uuid : "" }}</p>
      </div>
      <!-- 完整可编辑基本信息 -->
      <div v-show="!hideBaseInfo">
        <el-form-item
          label="唯一uuid"
          prop="uuid"
          v-if="formData && formData.uuid"
        >
          {{ formData.uuid }}
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 20px"
            @click="$globalAction('showMetaQoutes', formData)"
            >查看引用</el-button
          >
        </el-form-item>
        <el-form-item label="关系标识" prop="code">
          <el-input
            :disabled="formData != null ? true : false"
            v-model="form.code"
          ></el-input>
        </el-form-item>
        <el-form-item label="关系名称" prop="name">
          <el-input clearable v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="关系类型" prop="type">
          <el-select
            class="w-full"
            clearable
            v-model="form.type"
            placeholder="请选择"
          >
            <el-option
              v-for="item in relationTypes"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <!-- 所有信息：专业模式 -->
      <div v-show="!simpleMode">
        <p class="type-label-before-blue">本对象配置</p>
        <el-form-item label="本对象主键" prop="owner_primary_uuid">
          <el-select
            class="w-full"
            clearable
            v-model="form.owner_primary_uuid"
            placeholder="本对象中作为标识的字段"
          >
            <el-option
              v-for="item in ownerFields"
              :key="item.uuid + 'ownerfield'"
              v-show="item.type != 'append'"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <p class="type-label-before-blue">关联对象配置</p>
        <el-form-item label="关联对象" required>
          <ObjectSelect
            v-model="form.related_uuid"
            @on-change="getRelatedData"
            :objectsData="objectData"
          />
        </el-form-item>
        <el-form-item label="关联视图">
          <el-select
            class="w-full"
            clearable
            v-model="form.view_uuid"
            placeholder="请选择"
          >
            <template slot="prefix">
              <MetaTraceIcon method="ShowViewForm" :uuid="form.view_uuid" />
            </template>
            <el-option
              v-for="item in relatedViewsData"
              :key="item.uuid + 'related_uuid'"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联对象主键" required="">
          <el-select
            class="w-full"
            clearable
            v-model="form.related_primary_uuid"
            placeholder="关联对象中作为标识的字段"
          >
            <el-option
              v-for="item in relatedFieldData"
              :key="item.uuid + 'related_primary_uuid'"
              v-show="item.type != 'append'"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <p class="type-label-before-blue">中间对象配置</p>
        <el-form-item label="中间对象" required>
          <ObjectSelect
            v-model="form.pivot_uuid"
            @on-change="getPivotData"
            :objectsData="objectData"
          />
        </el-form-item>
        <el-form-item label="本对象映射" required="">
          <el-select
            class="w-full"
            clearable
            v-model="form.owner_pivot_uuid"
            placeholder="本对象在中间表中是哪个字段？"
          >
            <el-option
              v-for="item in pivotFieldData"
              :key="item.uuid + 'owner_pivot_uuid'"
              v-show="item.type != 'append'"
              :disabled="item.uuid == form.related_pivot_uuid"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联对象映射" required="">
          <el-select
            clearable
            class="w-full"
            v-model="form.related_pivot_uuid"
            placeholder="被关联对象在中间表中是哪个字段？"
          >
            <el-option
              v-for="item in pivotFieldData"
              :key="item.uuid + 'related_pivot_uuid'"
              :disabled="item.uuid == form.owner_pivot_uuid"
              v-show="item.type != 'append'"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <!-- 所有信息：简略模式 -->
      <div v-show="simpleMode">
        <p class="type-label-before-blue">简略模式-关联关系</p>
        <div v-if="form.type">
          <el-form-item label="关联对象" required>
            <ObjectSelect
              v-model="form.related_uuid"
              @on-change="getRelatedData"
              :objectsData="objectData"
            />
          </el-form-item>

          <el-form-item label="关联视图">
            <el-select
              class="w-full"
              clearable
              v-model="form.view_uuid"
              placeholder="请选择"
            >
              <template slot="prefix">
                <!-- el-icon-postcard -->
                <MetaTraceIcon method="ShowViewForm" :uuid="form.view_uuid" />
              </template>
              <el-option
                v-for="item in relatedViewsData"
                :key="item.uuid + 'related_uuid'"
                :label="item.name"
                :value="item.uuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="映射的字段" required="">
            <el-select
              class="w-full"
              clearable
              :value="SimpleModeFieldUuid"
              @input="solveSimpleFieldChange"
              placeholder="本对象在关联表或本表中变成了哪个字段？"
            >
              <el-option
                v-for="item in SimpleModeFields"
                :key="item.uuid + 'owner_pivot_uuid'"
                v-show="item.type != 'append'"
                :disabled="item.uuid == form.related_pivot_uuid"
                :label="
                  (item.object_uuid === obuuid ? '本表 - ' : '关联表 - ') +
                  item.name
                "
                :value="item.uuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-else>请先选择关联关系类型</div>
      </div>
    </el-form>
  </div>
</template>

<script>
import object from "@/api/objects";
import ObjectSelect from "@/components/metaSelect/ObjectSelect";
// import { mapGetters } from "vuex";
import collect from "collect.js";
import MetaTraceIcon from "@/components/metaSelect/MetaTraceIcon.vue";

export default {
  name: "relationsForm",
  components: { ObjectSelect, MetaTraceIcon },
  props: {
    formData: {
      type: Object,
      default: null,
    },
    obuuid: {
      type: String,
      default: "",
    },
    objectData: {
      //所有对象数据
      type: Array,
      default: () => [],
    },
    ownerFieldData: {
      //本表的字段数据
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hideBaseInfo: true,
      form: {
        code: "",
        name: "",
        type: 0, //关联关系
        owner_uuid: "", //前对象uuid
        owner_primary_uuid: "", //当前对象的某个字段uuid，不填默认id字段
        related_uuid: "", //被关联的对象
        related_primary_uuid: "", //被关联的对象的主键 ，不填默认id字段
        view_uuid: "", //被关联对象的视图uuid 必填
        pivot_uuid: "", //中间表对象
        owner_pivot_uuid: "", //中间表代表主对象的字段
        related_pivot_uuid: "", //中间表代表被关联对象的字段
      },
      simpleModeData: {
        //简略模式数据
        view_uuid: "", //被关联对象的视图uuid 必填
      },
      simpleMode: false,
      rules: {
        code: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "code的长度在1到30个字符之间",
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z0-9_]+$/,
            message: "code只能是字母和数字以及下划线",
          },
        ],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        type: [{ required: true, message: "请选择", trigger: "change" }],
        related_uuid: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        owner_primary_uuid: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
      },
      relationTypes: object.relationTypes, //关系类型
      relatedFieldData: [], //对象的字段数据
      ownerFields: [],
      relatedViewsData: [], //对象的视图数据
      pivotFieldData: [], //中间表字段数据
      owner: true, //是否是主关联
      show: false,
      isAdd: false,
      SimpleModeFieldUuid: "",
    };
  },
  computed: {
    // 简略模式中选择的视图合并字段
    SimpleModeFields() {
      let fields = [];
      if (this.form.type === 2) {
        fields = this.relatedFieldData;
      }
      if (this.form.type === 1) {
        fields = collect(this.ownerFields)
          .merge(this.relatedFieldData)
          .unique("uuid")
          .toArray();
      }
      return fields;
    },
    // 是否反向一对一
    isBelongsTo() {
      if (this.form && this.form.type === 1) {
        return this.form.owner_uuid === this.form.pivot_uuid;
      }
      return false;
    },
    // 查看的关联关系类型名称
    getRelationTypeName() {
      if (this.form || this.form.type) {
        let name = this.relationTypes[this.form.type].name;
        if (
          this.form.type === 1 &&
          this.form.owner_uuid === this.form.pivot_uuid
        ) {
          name = "反向" + name;
        }
        return name;
      } else {
        return "";
      }
    },
  },
  watch: {
    simpleMode: {
      handler(val) {
        if (val) {
          this.checkIfCanUseSimpleMode();
          if (this.form.type === 1) {
            // 反向一对一返回 对方在中间表的字段，否则就是自己在中间表的字段
            if (this.form.pivot_uuid === this.obuuid) {
              this.SimpleModeFieldUuid = this.form.related_pivot_uuid;
            } else {
              this.SimpleModeFieldUuid = this.form.owner_pivot_uuid;
            }
          } else if (this.form.type === 2) {
            // 一对多永远只返回自己在中间表的字段
            this.SimpleModeFieldUuid = this.form.owner_pivot_uuid;
          }
        }
      },
    },
    "form.type": {
      handler() {
        if (this.simpleMode) {
          this.checkIfCanUseSimpleMode();
        }
      },
    },
  },
  methods: {
    /**
     * @description: 简略模式请求的字段变化
     * @param {String} item 变化的item-uuid
     * @return {Void}
     */
    solveSimpleFieldChange(item) {
      this.SimpleModeFieldUuid = item;
      if (!this.simpleMode) {
        return;
      }
      item = collect(this.SimpleModeFields).where("uuid", item).first();
      if (!item) {
        return;
      }
      this.form.pivot_uuid = item.object_uuid;

      // 如果没有设置自己的主键，默认设置为id
      if (!this.form.owner_primary_uuid) {
        let owner_id = this.ownerFields.find((item) => item.name === "id");
        if (owner_id) this.form.owner_primary_uuid = owner_id.uuid;
      }
      // 如果没有设置被关联的主键，默认设置为id
      if (!this.form.related_primary_uuid) {
        let related_id = this.relatedFieldData.find(
          (item) => item.name === "id"
        );
        if (related_id) this.form.related_primary_uuid = related_id.object_uuid;
      }
      // 一对一关系处理
      if (this.form.type === 1) {
        // 正向一对一
        if (item.object_uuid !== this.obuuid) {
          // 中间表是对方对象
          this.form.pivot_uuid = item.object_uuid;
          this.pivotFieldData = this.relatedFieldData;
          // 那么这个字段是对方对象代表自己的字段
          this.form.owner_pivot_uuid = item.uuid;
          let related_id = collect(this.pivotFieldData)
            .where("code", "id")
            .first();
          this.form.related_pivot_uuid = related_id.uuid;
        } else {
          // 中间表是自己
          this.form.pivot_uuid = this.obuuid;
          this.pivotFieldData = this.ownerFields;
          // 反向一对一，中间表就是自己，那么代表自己的字段就是自己的id
          let owner_id = collect(this.pivotFieldData)
            .where("code", "id")
            .first();
          this.form.owner_pivot_uuid = owner_id.uuid;
          // 代表对方表的就是选择的那个字段
          this.form.related_pivot_uuid = item.uuid;
        }
      }
      // 一对多关系处理
      if (this.form.type === 2) {
        this.pivotFieldData = this.relatedFieldData;
        // 一对多只有自己在对方表的映射字段才有用
        this.form.owner_pivot_uuid = item.uuid;
        let related_id = collect(this.pivotFieldData)
          .where("code", "id")
          .first();
        this.form.related_pivot_uuid = related_id.uuid;
      }

      console.log(this.form, "简略模式请求的字段变化");
    },

    /**
     * @description: 被关联对象变化的时候获取相关数据
     * @param {String} uuid 变化的对象uuid
     * @return {Void}
     */
    getRelatedData(uuid) {
      //获取选中对象后的视图数据和关联字段数据供下面选择
      this.form.related_uuid = uuid;
      // if (this.simpleMode) {
      //   this.form.pivot_uuid = uuid;
      // }
      // if (this.isAdd && !this.simpleMode) {
      //   this.form.view_uuid = "";
      //   this.form.related_primary_uuid = "";
      // }
      object.getViewsList(uuid).then((res) => {
        if (res.data.code == 200 && res.data.data && res.data.data.data) {
          this.relatedViewsData = res.data.data.data;
        }
      });
      object.getFields(uuid).then((res) => {
        if (res.data.code == 200 && res.data.data && res.data.data.data) {
          this.relatedFieldData = res.data.data.data;
          // 简单模式下
          if (this.simpleMode) {
            if (!this.form.type) {
              this.$message.error("请先选择关联关系类型!");
              this.form.related_uuid = "";
              return;
            }
            this.form.owner_uuid = this.obuuid;
            // 设置本表主键为本表字段的id
            let owner_id = collect(this.ownerFields)
              .where("code", "id")
              .first();
            this.form.owner_primary_uuid = owner_id.uuid;
            // 设置中间表为选择的对象
            this.form.pivot_uuid = uuid;
            this.pivotFieldData = this.relatedFieldData;
            // 设置对方表的主键为关联表的id
            let related_id = collect(this.relatedFieldData)
              .where("code", "id")
              .first();

            if (related_id) {
              this.form.related_primary_uuid = related_id.uuid;
            }
          }
        }
      });
    },

    /**
     * @description: 检查是否可以使用简略模式
     * @param {Boolean} needInfo 是否需要展示报错信息
     * @return {Boolean} true可以使用，false不可以使用
     */
    checkIfCanUseSimpleMode(needInfo = true) {
      // 检查本对象主键是否是id
      let owner_id = collect(this.ownerFields).where("code", "id").first();
      if (
        this.form.owner_primary_uuid &&
        owner_id &&
        this.form.owner_primary_uuid !== owner_id.uuid
      ) {
        if (needInfo) this.$message.error("简略模式的本对象主键必须是id");
        this.simpleMode = false;
        return false;
      }
      // 检查关联对象主键是否是id
      let related_id = collect(this.relatedFieldData)
        .where("code", "id")
        .first();
      if (
        this.form.related_primary_uuid &&
        related_id &&
        this.form.related_primary_uuid !== related_id.uuid
      ) {
        if (needInfo) this.$message.error("简略模式的关联对象主键必须是id");
        this.simpleMode = false;
        return;
      }
      // 如果是正向一对一 或者 一对多，那么关联对象映射是否是id
      if (
        this.form.related_pivot_uuid &&
        this.form.pivot_uuid &&
        ((this.form.type === 1 && this.form.pivot_uuid !== this.obuuid) ||
          this.form.type === 2)
      ) {
        if (related_id && this.form.related_pivot_uuid !== related_id.uuid) {
          if (needInfo)
            this.$message.error(
              "简略模式的正向一对一，一对多关系的中间表关联对象映射必须是id"
            );
          this.simpleMode = false;
          return false;
        }
      }
      // 如果是反向一对一，那么本对象映射是否是id
      if (
        this.form.pivot_uuid &&
        this.form.owner_pivot_uuid &&
        this.form.type === 1
      ) {
        if (owner_id && this.form.owner_pivot_uuid === this.obuuid) {
          if (needInfo)
            this.$message.error("反向一对一关系简略模式的本对象映射必须是id");
          this.simpleMode = false;
          return false;
        }
      }
      // 多对多关联不允许使用简略模式
      if (this.form.type === 3) {
        if (needInfo) this.$message.error("多对多关联不允许使用简略模式");
        this.simpleMode = false;
        return false;
      }

      return true;
    },

    /**
     * @description: 获取中间表的数据
     * @param {String} uuid 中间表的uuid
     * @return {Void}
     */
    getPivotData(uuid) {
      //中间件数据
      this.form.pivot_uuid = uuid;
      if (this.formData == null) {
        this.form.owner_pivot_uuid = "";
        this.form.related_pivot_uuid = "";
      }
      object.getFields(uuid).then((res) => {
        if (res.data.code == 200 && res.data.data && res.data.data.data) {
          this.pivotFieldData = res.data.data.data;
        }
      });
    },

    /**
     * @description: 提交关联关系数据
     */
    commit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.checkIfCanUseSimpleMode(false)) {
            this.$confirm("该配置不符合简略模式，是否继续？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                let data = JSON.parse(JSON.stringify(this.form));
                this.$emit("addFn", data);
              })
              .catch(() => {
                this.$emit("closeLoading");
              });
          } else {
            let data = JSON.parse(JSON.stringify(this.form));
            this.$emit("addFn", data);
          }
        } else {
          this.$emit("closeLoading");
        }
      });
    },
  },
  mounted() {
    if (this.ownerFieldData.length) {
      this.ownerFields = this.ownerFieldData;
    } else {
      object.getFields(this.obuuid).then((res) => {
        if (res.data.code == 200 && res.data.data && res.data.data.data) {
          this.ownerFields = res.data.data.data;
        }
      });
    }
    if (this.obuuid) {
      this.form.owner_uuid = this.obuuid;
    }
    if (this.formData != null) {
      if (this.form.owner_uuid != this.obuuid) {
        //判断是否是主关联关系
        this.owner = false;
      }
      let data = JSON.parse(JSON.stringify(this.formData));
      this.form.code = data.code;
      this.form.name = data.name;
      this.form.type = data.type;
      this.form.owner_primary_uuid = data.owner_primary_uuid;
      this.form.view_uuid = data.view_uuid;
      this.form.owner_pivot_uuid = data.owner_pivot_uuid;
      if (data.related_uuid) {
        this.form.related_uuid = data.related_uuid;
        this.form.related_primary_uuid = data.related_primary_uuid;
        this.form.view_uuid = data.view_uuid;
      }
      if (data.pivot_uuid) {
        this.form.pivot_uuid = data.pivot_uuid;
        this.form.owner_pivot_uuid = data.owner_pivot_uuid;
        this.form.related_pivot_uuid = data.related_pivot_uuid;
      }
      this.getRelatedData(data.related_uuid);
      this.getPivotData(data.pivot_uuid);

      this.show = true;
    } else {
      this.isAdd = true;
      this.simpleMode = true;
      this.hideBaseInfo = false;
      this.show = true;
    }
  },
};
</script>

<style lang="less" scoped>
</style>
