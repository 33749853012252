<!--
 * @Author: zx
 * @Date: 2022-10-28 16:30:04
 * @LastEditTime: 2022-10-28 16:53:31
 * @Description: 
 * @LastEditors: zx
-->
<template>
<div>
  <el-select
    class="w-full"
    clearable
    v-model="value.view_uuid"
    placeholder="请选择"
    @change="saveRowData"
  >
    <template slot="prefix">
      <MetaTraceIcon method="ShowViewForm" :uuid="value.view_uuid" />
    </template>
    <el-option
      v-for="(item, index) in viewList"
      :key="item.uuid + index"
      :label="item.name"
      :value="item.uuid"
    >
    </el-option>
  </el-select>
</div>
</template>
<script>
  import MetaTraceIcon from "@/components/metaSelect/MetaTraceIcon.vue";
  import object from "@/api/objects";
  export default {
    components:{
      MetaTraceIcon
    },
    props:{
      value: {
        type: Object,
        default: () => {}
      },
      index: {
        type: Number,
        ddefault: null
      }
    },
    data() {
      return {
        viewList: []
      }
    },
    watch: {
      value: {
        handler(val) {
          if (val?.related_uuid) {
            this.getSelectList(val.related_uuid)
          }
        },
        deep: true
      }
    },
    mounted() {
      this.getSelectList(this.value.related_uuid)
    },
    methods:{
      saveRowData() {
        this.$emit('saveRowData', this.value, this.index)
      },
      getSelectList(uuid) {
      //获取选中对象后的视图数据和关联字段数据供下面选择
        object.getViewsList(uuid).then((res) => {
          if (res.data.code == 200 && res.data.data && res.data.data.data) {
            this.viewList = res.data.data.data
          }
        })
      }
    }
  }
</script>
<style lang='less' scoped>
</style>