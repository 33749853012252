/*
 * @Author: ttheitao
 * @Description: 
 * @Date: 2022-07-04 17:54:37
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-07-05 09:24:59
 */

export default class Util {
    /**
     * @description: 根据单元获取vm实例
     * @param {*} graph 画布实例
     * @param {*} cell 单元
     * @return {*}
     */
    static getVmByCell(graph, cell) {
        let vm = null;
        if (cell.view === 'vue-shape-view') {
            const view = graph.findViewByCell(cell);
            vm = view ? view.vm.$children[0] : null;
        }
        return vm;
    }
}