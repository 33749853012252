<!--
 * @Author: ttheitao
 * @Description: 
 * @Date: 2022-05-27 17:14:56
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-06-01 15:09:06
-->
<template>
    <div class="base-config-wrap">
        <div class="x6-config-form">
            <div class="x6-config-item">
                <div class="label">条件结果</div>
                <div class="content">
                    <el-radio
                        v-model="data.type"
                        @change="onPropChange"
                        label="pass"
                    >
                        通过
                    </el-radio>
                    <el-radio
                        v-model="data.type"
                        @change="onPropChange"
                        label="fail"
                    >
                        未通过
                    </el-radio>
                </div>
                <div class="tips">
                    <i class="el-icon-info"></i>
                    由来源条件节点的结果决定是否执行目标节点
                </div>
            </div>
            <div class="x6-config-item">
                <div class="label">优先级</div>
                <div class="content">
                    <el-input
                        type="number"
                        v-model="data.priority"
                        @input="onPropChange"
                        size="small"
                    />
                </div>
                <div class="tips">
                    <i class="el-icon-info"></i>
                    纯数字，表示执行顺序，由小到大执行
                </div>
            </div>
            <div class="x6-config-item">
                <div class="label">是否启用</div>
                <div class="content">
                    <el-switch v-model="data.enabled" @change="onPropChange" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dataMixin from "../mixins/dataMixin.js";

export default {
    mixins: [dataMixin],
    data() {
        return {};
    },
};
</script>

<style lang="less" scoped>
</style>