/*
 * @Author: ttheitao
 * @Description: 事件
 * @Date: 2022-05-26 09:53:46
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-04 17:36:47
 */
import Util from "./Util";
export default class EventManager {
    base = null;
    // x6实例
    graph = null;
    // vue实例
    vm = null;

    constructor(base) {
        this.base = base;
        this.vm = this.base.vm;
        this.graph = this.base.graph;
        this.register();
    }

    // 注册事件
    register() {
        this.cellEvent(); // 单元事件
        this.modelEvent(); // 画布事件
        this.keyboardEvent(); // 键盘事件
        this.selectionEvent(); // 选区事件
    }

    cellEvent() {
        // 单元右键事件
        this.graph.on("cell:contextmenu", ({ e, x, y, cell }) => {
            // console.log('cell:contextmenu', x, y, cell, view);
            this.base.curCell = cell;

            let offsetX = e.offsetX;
            let offsetY = e.offsetY;

            // 选中时使用节点坐标
            if (this.graph.isSelected(cell)) {
                offsetX = x;
                offsetY = y;
            }

            this.base.showContextmenu(offsetX, offsetY);
        });

        this.graph.on('node:mouseenter', () => {
            this.showPorts();
        });
        this.graph.on('node:mouseleave', () => {
            this.showPorts(false);
        });

        // 节点/边 增删改
        this.graph.on('cell:added', ({ cell }) => {
            if (this.base.curCell && cell.shape !== 'Start' && this.graph.isNode(cell) && !this.base.isPasting) {
                this.base.generateEdgeByNode(this.base.curCell, cell);
            }
        });

        // 节点 移动时
        this.graph.on('node:moving', () => {
            this.showPorts(false);
        });

        // 开始移动节点时
        this.graph.on('node:move', (args) => {
            const vm = Util.getVmByCell(this.graph, args.cell);
            if (vm && vm.hasPanel) {
                vm.isMoving = true;
            }
        });

        // 移动节点后
        this.graph.on('node:moved', (args) => {
            const vm = Util.getVmByCell(this.graph, args.cell);
            if (vm && vm.hasPanel) {
                setTimeout(() => {
                    vm.isMoving = false;
                }, 0);
            }
        });

        // 节点删除后
        this.graph.on('node:removed', (args) => {
            if (args.cell.parent) {
                args.cell.parent(args.cell);
            }
        });
    }

    /**
     * @description: 画布事件
     * @return {*}
     */
    modelEvent() {
        // 空白处右键事件
        this.graph.on("blank:mousedown", () => {
            this.base.hideContextmenu();
            this.base.setCurrentCell();
        });
    }

    /**
     * @description: 键盘事件
     * @return {*}
     */
    keyboardEvent() {
        // 删除
        this.graph.bindKey('delete', () => {
            this.base.deleteCellsWithSelected();
        });

        // 复制
        this.graph.bindKey('ctrl+c', () => {
            const cells = this.graph.getSelectedCells();
            if (cells.length) {
                this.graph.copy(cells);
            }
            return false;
        })

        // 粘贴
        this.graph.bindKey('ctrl+v', () => {
            this.base.isPasting = true;
            if (!this.graph.isClipboardEmpty()) {
                const cells = this.graph.paste({ offset: 32 });
                this.graph.cleanSelection();
                this.graph.select(cells);

                this.base.autoFixId(cells);
            }
            this.base.isPasting = false;
            return false;
        })
    }


    selectionEvent() {
        let ids = {};
        this.graph.on('cell:selected', (args) => {
          this.base.curCell = args.cell;
          this.base.hideContextmenu();
          this.showPorts(false);

          if(args.cell.isNode()){
            ids[args.cell.id] = args.cell.zIndex;
            setTimeout(() => {
              args.cell.toFront();
            }, 0);
          }
        });

        this.graph.on('cell:unselected', (args) => {
            this.base.curCell = null;
            this.base.hideContextmenu();

            const vm = Util.getVmByCell(this.graph, args.cell);
            if (vm && vm.hasPanel) {
                vm.closePanel();
            }

            setTimeout(() => {
              if(args.cell.isNode()){
                args.cell.setZIndex(ids[args.cell.id]);
                delete ids[args.cell.id];
              }
            }, 0);
        });
    }

    // 控制连接桩显示/隐藏
    showPorts(show = true) {
        const container = this.vm.$el;
        const ports = container.querySelectorAll('.x6-port-body');

        for (let i = 0, len = ports.length; i < len; i = i + 1) {
            ports[i].style.visibility = show ? 'visible' : 'hidden';
        }
    }
}